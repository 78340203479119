import React, { useEffect } from "react";
import "./styles/App.scss";
import "../node_modules/react-datetime/css/react-datetime.css";
import setupFontAwesome from "./setupFontAwesome";
import { AuthProvider } from "./components/Auth";
import { UserProvider } from "./components/User/UserContext";
import { FirebaseProvider } from "./components/Firebase/context";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScreensHome from "./screens/Home/Home";
import ScreensDashboard from "./screens/Dashboard/Dashboard";
import ScreensTradeList from "./screens/Trade/List";
import ScreensPerformance from "./screens/Performance/Performance";
import PrivateRoute from "./components/PrivateRoute";
import SignIn from "./screens/User/SignIn";
import GetStarted from "./screens/User/GetStarted";
import ScreensTrade from "./screens/Trade/Trade";
import ScreensTradeForm from "./screens/Trade/Form";
import ScreensDonate from "./screens/Donate";
import ScreensResources from "./screens/Resources/Resources";
import ScreensSettings from "./screens/Settings";
import ScreensFaqs from "./screens/Faqs";
import ScreensNoMatch from "./screens/NoMatch";

setupFontAwesome();

function App() {
  return (
    <FirebaseProvider>
      <UserProvider>
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={ScreensHome} />
              <Route path="/user/sign-in" component={SignIn} />
              <Route path="/user/start" component={GetStarted} />
              <PrivateRoute path="/dashboard" component={ScreensDashboard} />
              <PrivateRoute
                path="/trade/form/:id?"
                component={ScreensTradeForm}
              />
              <PrivateRoute path="/trade/list" component={ScreensTradeList} />
              <PrivateRoute path="/trade/:id" component={ScreensTrade} />
              <PrivateRoute
                path="/performance"
                component={ScreensPerformance}
              />
              <PrivateRoute path="/settings" component={ScreensSettings} />
              <Route path="/donate" component={ScreensDonate} />
              <Route path="/faqs" component={ScreensFaqs} />
              <Route path="/resources" component={ScreensResources} />
              <Route path="*" component={ScreensNoMatch}></Route>
            </Switch>
          </Router>
        </AuthProvider>
      </UserProvider>
    </FirebaseProvider>
  );
}

export default App;
