import React from "react";
import phoneSellHigh from "../../images/phone-trade-sell.png";
import phoneSellStopLoss from "../../images/phone-trade-sell-stoploss.png";
import phoneHold from "../../images/phone-monitor-progress.png";
import phoneSellHighMobile from "../../images/phone-trade-sell-mobile.png";
import phoneSellStopLossMobile from "../../images/phone-trade-sell-stoploss-mobile.png";
import phoneHoldMobile from "../../images/phone-monitor-progress-mobile.png";

const styles = {
  noPadding: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  notification: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
};

const HomePhoneColors = () => {
  return (
    <div className="tile is-ancestor">
      <div className="tile is-parent is-4" style={styles.noPadding}>
        <div
          className="tile is-child notification has-text-centered "
          style={styles.notification}
        >
          <h3 className="title is-size-6 has-text-primary">Hold</h3>
          <h4 className="subtitle is-size-7 has-text-primary">Clear</h4>
          <img
            src={phoneHold}
            className="is-hidden-touch"
            alt="Trade-r phone screen shot hold"
          />
          <img
            src={phoneHoldMobile}
            className="is-hidden-desktop"
            alt="Trade-r phone screen shot hold"
          />
        </div>
      </div>
      <div className="tile is-parent is-4" style={styles.noPadding}>
        <div
          className="tile is-child notification is-success has-text-centered"
          style={styles.notification}
        >
          <h3 className="title is-size-6">Sell profit</h3>
          <h4 className="subtitle is-size-7">Green</h4>
          <img
            src={phoneSellHigh}
            className="is-hidden-touch"
            alt="Trade-r phone screen shot sell profit"
          />
          <img
            src={phoneSellHighMobile}
            className="is-hidden-desktop"
            alt="Trade-r phone screen shot sell profit"
          />
        </div>
      </div>
      <div className="tile is-parent is-4" style={styles.noPadding}>
        <div
          className="tile is-child notification is-danger has-text-centered"
          style={styles.notification}
        >
          <h3 className="title is-size-6">Sell stop loss</h3>
          <h4 className="subtitle is-size-7">Red</h4>
          <img
            src={phoneSellStopLoss}
            className="is-hidden-touch"
            alt="Trade-r phone screen shot sell stop loss"
          />
          <img
            src={phoneSellStopLossMobile}
            className="is-hidden-desktop"
            alt="Trade-r phone screen shot sell stop loss"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePhoneColors;
