import React from "react";
import {
  formatDate,
  formatPrice,
  formatPreferredCurrency,
} from "../../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const TradeSellCard = ({ trade, preferredCurrency }) => {
  const { sell, coinTarget } = trade;

  return (
    <div className="box">
      <h3 className="subtitle">Sell</h3>

      <div className="content">
        <dl>
          <dt>Date</dt>
          <dd>{formatDate(sell.date)}</dd>
          <dt>Price</dt>
          <dd>
            {formatPrice(sell.price)} {coinTarget}
          </dd>
          <dt>
            Preferred currency price
            <Link to="/faqs" className="icon">
              <FontAwesomeIcon icon="info" />
            </Link>
          </dt>
          <dd>
            {formatPreferredCurrency(
              sell.pricePreferredCurrency,
              preferredCurrency
            )}
          </dd>
          <dt>Quantity</dt>
          <dd>{sell.quantity}</dd>
        </dl>

        <dl className="notification is-light">
          <dt>Sell Amount</dt>
          <dd>
            {formatPrice(sell.price * sell.quantity)} {coinTarget}
          </dd>
          <dt>
            Sell Amount (in preferred currency){" "}
            <Link to="/faqs" className="icon">
              <FontAwesomeIcon icon="info" />
            </Link>
          </dt>
          <dd>
            ~{" "}
            {formatPreferredCurrency(
              sell.pricePreferredCurrency * sell.quantity,
              preferredCurrency
            )}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default TradeSellCard;
