import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/UI/Layout";
import { getCurrentPrices } from "../../components/Trade/api";
import TradeList from "../../components/Trade/List";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UIPageLoader from "../../components/UI/PageLoader";
import toastWrapper from "../../services/toast-wrapper";
import { UserContext } from "../../components/User/UserContext";
import { Helmet } from "react-helmet";
import { analytics } from "../../components/Firebase/context";

const ScreensTradeList = () => {
  const { trades } = useContext(UserContext);
  const [currentPrices, setCurrentPrices] = useState();
  const [visibleTrades, setVisibleTrades] = useState([]);
  const [showAllTrades, setShowAllTrades] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Trade List" });
  }, []);

  useEffect(() => {
    async function fn() {
      if (!trades) {
        return;
      }
      if (trades.length === 0) {
        setIsLoading(false);
      }
      const response = await getCurrentPrices(trades);

      if (response.error) {
        setIsLoading(false);
        return toastWrapper({
          message: response.message,
          type: "is-danger",
          duration: 300000,
        });
      }

      setCurrentPrices(response);
      setVisibleTrades(trades.filter((trade) => trade.active));
      setIsLoading(false);
    }
    fn();
  }, [trades]);

  // Toggle show all / active-only trades
  const handleShowAllChange = () => {
    setVisibleTrades(
      showAllTrades ? trades.filter((trade) => trade.active) : trades
    );
    setShowAllTrades(!showAllTrades);
  };

  return isLoading ? (
    <UIPageLoader />
  ) : (
    <Layout>
      <Helmet>
        <title>Trade List | Trade-r</title>
      </Helmet>
      <section className="hero has-img-background-btc is-primary">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-inline-block">
              {trades.length > 0 ? `Trades` : "Lets Get Started!"}
            </h1>
            <h2 className="subtitle">
              {trades.length > 0
                ? "All time trading history"
                : "And begin trading like a pro"}
            </h2>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          {trades.length > 0 && !isLoading && (
            <div className="field">
              <input
                type="checkbox"
                className="switch is-primary"
                value={showAllTrades}
                onChange={handleShowAllChange}
                checked={showAllTrades}
                name="show-all-trades"
                id="show-all-trades"
              />{" "}
              <label htmlFor="show-all-trades">Show all trades</label>
            </div>
          )}

          {trades.length === 0 && (
            <div className="columns is-centered">
              <div className="column is-half content">
                <p>
                  <strong>Step 1:</strong> Verify your{" "}
                  <Link to="/settings">preferred currency</Link>.
                </p>

                <p>
                  <strong>Step 2:</strong> Start adding your trades
                </p>
                <Link
                  to="/trade/form"
                  className={`button is-link is-fullwidth`}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon="plus" />
                  </span>
                  <span>Add a Trade</span>
                </Link>
              </div>
            </div>
          )}

          {trades.length > 0 && (
            <TradeList
              trades={visibleTrades}
              showAllTrades={showAllTrades}
              currentPrices={currentPrices}
            />
          )}
        </div>
      </section>
    </Layout>
  );
};

export default ScreensTradeList;
