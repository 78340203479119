import React from "react";
import HomeStepsWrapper from "./StepsWrapper";
import LogoInPage from "../UI/LogoInPage";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import laptopPic from "../../images/laptop-sell.png";

const HomeSell = ({ currentUser }) => {
  return (
    <HomeStepsWrapper bg="is-primary" headline="3. Sell Into Targets">
      <div className="column is-half">
        <img
          src={laptopPic}
          alt="Trade-r laptop screen shot of sell indication"
        />
      </div>
      <div className="column is-half ">
        <p className="is-size-4 is-size-5-mobile">
          <LogoInPage /> will alert you when to sell. Do it. Follow your own
          advice.
        </p>
        <p>
          Don't think{" "}
          <span className="is-italic">
            "This trade is going to the moon, I'm hanging on for way bigger
            profits!"
          </span>{" "}
          Nope. Or another tricky one,{" "}
          <span className="is-italic">
            "I can't believe this trade lost so much in a single day, I'm
            hanging on til it comes back".
          </span>{" "}
          Think again. Sell it like the pros do.
        </p>

        <p>
          <Link
            to={`${currentUser ? "/trade/list" : "/user/sign-in"}`}
            className={`button is-outlined is-primary is-inverted ${
              isMobile ? "is-fullwidth" : ""
            }`}
          >
            Learn More
          </Link>
        </p>
      </div>
    </HomeStepsWrapper>
  );
};

export default HomeSell;
