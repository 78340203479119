import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const UIBreadcrumbs = ({ crumbs = [] }) => {
  return (
    <nav className="breadcrumb">
      <ul>
        {crumbs.map((crumb, i) => (
          <li key={i}>
            {crumb.route && <Link to={crumb.route}>{crumb.label}</Link>}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {!crumb.route && <a>{crumb.label}</a>}
          </li>
        ))}
      </ul>
    </nav>
  );
};

UIBreadcrumbs.propTypes = {
  crumbs: PropTypes.array,
};

export default UIBreadcrumbs;
