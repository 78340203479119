import moment from "moment";
import { supportedCoins } from "./supported-coins";
import { preferredCurrencies } from "./preferred-currencies";

const LONG = "LONG";

export function calculateExitPercent(trade) {
  const { buy, sell, tradeType } = trade;
  const diff =
    tradeType === LONG ? sell.price / buy.price : buy.price / sell.price;

  if (buy.price === sell.price) return 0;

  return parseInt((diff - 1) * 100);
}

export function calculateNumDecimals(value = 0) {
  let numDecimals = 0;
  const absValue = Math.abs(value);
  if (absValue < 0.001) {
    numDecimals = 8;
  } else if (absValue < 0.01) {
    numDecimals = 6;
  } else if (absValue < 1) {
    numDecimals = 4;
  } else if (absValue < 10) {
    numDecimals = 3;
  } else if (absValue < 50) {
    numDecimals = 2;
  } else {
    numDecimals = 0;
  }
  return numDecimals;
}

export function calculateProfit(trade) {
  if (!trade) {
    return null;
  }
  const { buy, sell, tradeType } = trade;
  if (tradeType !== LONG) {
    return formatPrice(buy.price * buy.quantity - sell.price * sell.quantity);
  }
  return formatPrice(sell.price * sell.quantity - buy.price * buy.quantity);
}

// TODO: These calculations are wrong.  Use percentDiff() below instead
export function calculateTargetExitPercentages(trade) {
  const { buy, target } = trade;
  let obj = { profit: null, stopLoss: null };

  const diffProfit = isLong(target, buy)
    ? target.profit / buy.price
    : buy.price / target.profit;

  const diffStopLoss = isLong(target, buy)
    ? target.stopLoss / buy.price
    : buy.price / target.stopLoss;

  obj.profit = parseInt((diffProfit - 1) * 100);
  obj.stopLoss = parseInt((diffStopLoss - 1) * 100);

  return obj;
}

export function calculateTargetProfit(trade) {
  if (!trade) {
    return null;
  }
  const { buy, target, tradeType } = trade;
  const { quantity } = buy;
  let targetProfit = formatPrice(
    target.profit * quantity - buy.price * quantity
  );
  if (tradeType !== LONG) {
    targetProfit = formatPrice(buy.price * quantity - target.profit * quantity);
  }

  return targetProfit;
}

/**
 * Calculates total profit in preferred currency, for an array of trades
 * @param {Array} trades
 * @returns {Number}
 */
export function calculateTradesProfit(trades = []) {
  const soldTrades = trades.filter((trade) => !trade.active);
  let profit = 0;

  if (soldTrades.length === 0) {
    return 0;
  }

  profit = soldTrades.reduce(
    (accumulator, trade) => trade.profitPreferredCurrency + accumulator,
    0
  );
  return profit;
}

/**
 * Calculate profitable trade count/percentage for an array of trades
 * @param {Array} trades
 * @returns {Object}
 */
export function calculateWins(trades) {
  let totalWins = 0;

  for (let trade of trades) {
    if (didSellForProfit(trade)) {
      totalWins++;
    }
  }

  return {
    total: totalWins,
    percent: Number(
      totalWins === 0 ? 0 : ((totalWins / trades.length) * 100).toFixed(2)
    ),
  };
}

export function countDecimals(value) {
  if (!value || Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
}

export function countTradeDays(trade) {
  if (!trade) return;
  const endDate = !trade.active ? trade.sell.date.toDate() : new Date();
  const numDays = moment(endDate).diff(moment(trade.buy.date.toDate()), "days");
  return numDays;
}

export function createUserSoldDataMap(trades) {
  const coinTargets = getCoinTargetsFromTrades(trades);

  // Initialize Map
  let soldDataMap = new Map(
    coinTargets.map((target) => [target, { soldTrades: [], totalProfit: 0 }])
  );

  // Create a Map of trades, organized by coinBase
  for (let trade of trades) {
    let value = soldDataMap.get(trade.coinTarget);
    value.soldTrades.push(trade);
  }

  return soldDataMap;
}

export function didSellForLoss(trade) {
  const { target, sell, tradeType } = trade;
  if (tradeType !== LONG) {
    return sell.price > target.stopLoss;
  }
  return sell.price < target.stopLoss;
}

export function didSellForProfit(trade) {
  const { target, sell, tradeType } = trade;
  if (tradeType !== LONG) {
    return sell.price < target.profit;
  }
  return sell.price > target.profit;
}

/**
 * Remove duplicate trade pairs. For example if a user has 4 BTC/USD trades,
 * we don't need 4 identical network calls
 * @param {Array} tradePairs // [{ coinBase: "bitcoin", coinTarget: "usd" }]
 */
export function filterUniqueTradePairs(tradePairs = []) {
  let uniqueTradePairs = [];

  tradePairs.forEach((pair) => {
    if (
      !uniqueTradePairs.find(
        (item) =>
          item.coinBase === pair.coinBase && item.coinTarget === pair.coinTarget
      )
    ) {
      uniqueTradePairs.push(pair);
    }
  });

  return uniqueTradePairs;
}

export const formatDate = (timestamp) => {
  if (!timestamp) return null;
  const timestampSeconds =
    typeof timestamp === "object" ? timestamp.seconds : timestamp;
  return moment.unix(timestampSeconds).format("MMMM Do YYYY, h:mm:ss a");
};

/**
 * Helper function which returns a formatted trading pair string
 * @param {object} trade Trade object record
 * @returns {string}
 */
export function formatPair(trade) {
  let pair = "";

  try {
    const { coinBase } = trade;
    const coinBaseId = typeof coinBase === "object" ? coinBase.id : coinBase;
    const coin = supportedCoins.find((coin) => coin.id === coinBaseId);
    pair = `${coin.symbol.toUpperCase()}/${trade.coinTarget}`;
  } catch (e) {}

  return pair;
}

export function formatPreferredCurrency(value, currency) {
  const decimals = currency !== "btc" ? 2 : null;
  const currencyObj = preferredCurrencies.find((c) => c.value === currency);
  const amount = value === 0 ? 0 : formatPrice(value, decimals);
  return `${currencyObj.unit}${amount}`;
}

export function formatPrice(price, decimalPlaces) {
  if (price === 0) return 0;
  if (!price) {
    return null;
  }

  // Use custom decimal places
  if (decimalPlaces) {
    return Number(price).toFixed(decimalPlaces);
  }

  let numDecimals = calculateNumDecimals(price);
  let rtn = parseFloat(Number(price).toFixed(numDecimals));

  return rtn;
}

/**
 * Build a Map datatype of coinBase profits
 * @param {Array} trades
 * @returns {Map}
 */
export function getCoinBaseProfitMap(trades) {
  const coinBaseMap = new Map();

  // Build a Map of coinBase profits
  for (let trade of trades) {
    const { coinBase, profit } = trade;

    if (!coinBaseMap.get(coinBase)) {
      coinBaseMap.set(coinBase, profit);
    } else {
      let value = coinBaseMap.get(coinBase);
      coinBaseMap.set(coinBase, value + profit);
    }
  }

  // Sort by profit
  // https://stackoverflow.com/questions/37982476/how-to-sort-a-map-by-value-in-javascript
  const mapSort = new Map(
    [...coinBaseMap.entries()].sort((a, b) => b[1] - a[1])
  );

  return mapSort;
}

/**
 * Get all unique coinBases from an array of trades
 * @param {Array} trades
 * @returns Array
 */
export function getCoinBasesFromTrades(trades) {
  let coinBases = new Set();

  for (let trade of trades) {
    coinBases.add(trade.coinBase);
  }
  return [...coinBases];
}

export function getCoinImage(coinBase) {
  try {
    const coin = supportedCoins.find((coin) => coin.id === coinBase);
    return coin.image;
  } catch (e) {
    console.log(`Error in getCoinImage():`, e);
    return "";
  }
}

/**
 * Get all unique coinTargets from an array of trades
 * @param {Array} trades
 * @returns Array
 */
export function getCoinTargetsFromTrades(trades) {
  let coinTargets = new Set();

  for (let trade of trades) {
    coinTargets.add(trade.coinTarget);
  }
  return [...coinTargets];
}

// function getMinY(trades) {
//   return trades.reduce((min, p) => p.y < min ? p.y : min, data[0].y);
// }
// function getMaxY(trades) {
//   return trades.reduce((max, p) => p.y > max ? p.y : max, data[0].y);
// }

export function getMonthlySoldTrades(trades = [], month = moment().month()) {
  let monthlyTrades = [];
  const soldTrades = trades.filter((trade) => !trade.active);

  try {
    monthlyTrades = soldTrades.filter((trade) => {
      const sellMonth =
        trade.sell && trade.sell.date
          ? moment(trade.sell.date.toDate()).month()
          : "";
      return month === sellMonth;
    });
  } catch (error) {
    console.log("Error in services: getMonthlySoldTrades(): ", error);
  }
  return monthlyTrades;
}

export const getStatusClass = (trade) => {
  if (trade.active) {
    return "is-info";
  }

  if (didSellForProfit(trade)) {
    return "is-success";
  }
  if (didSellForLoss(trade)) {
    return "is-danger";
  }
  return "is-warning";
};

export function getTimeframeTrades(trades = [], daysCount) {
  const timeframeTrades = trades.filter((trade) => {
    return moment(trade.buy.date.toDate()).isAfter(
      moment().subtract(daysCount, "days")
    );
  });

  return timeframeTrades;
}

/**
 * Generate an array of objects representing trade pairs;
 * ie; { coinBase: "bitcoin", coinTarget: "usd" }
 * @param {array} trades
 * @returns Array
 */
export function getTradePairs(trades) {
  try {
    return trades.map((trade) => ({
      coinBase: trade.coinBase,
      coinTarget: trade.coinTarget,
    }));
  } catch (error) {}
  return [];
}

export function getYearlySoldTrades(trades = [], year = moment().year()) {
  let yearlyTrades = [];
  const soldTrades = trades.filter((trade) => !trade.active);

  try {
    yearlyTrades = soldTrades.filter((trade) => {
      const sellYear =
        trade.sell && trade.sell.date
          ? moment(trade.sell.date.toDate()).year()
          : "";
      return year === sellYear;
    });
  } catch (error) {
    console.log("Error in services: getYearlySoldTrades(): ", error);
  }
  return yearlyTrades;
}

export function isLong(target, buy) {
  return target.profit > buy.price;
}

export function percentDiff(trade, currentPrice) {
  const { buy, tradeType } = trade;
  let newPrice = currentPrice || trade.sell.price;

  // Reference: https://www.skillsyouneed.com/num/percent-change.html
  const increase = newPrice - buy.price;
  let percentIncrease = Number(((increase / buy.price) * 100).toFixed(2));

  percentIncrease =
    tradeType !== "LONG" ? percentIncrease * -1 : percentIncrease;

  return percentIncrease;
}

export function shouldSellLoss(trade, currentPrice) {
  const { target, tradeType } = trade;
  return tradeType === "LONG"
    ? currentPrice < target.stopLoss
    : currentPrice > target.stopLoss;
}

export function shouldSellProfit(trade, currentPrice) {
  const { target, tradeType } = trade;
  return tradeType === "LONG"
    ? currentPrice > target.profit
    : currentPrice < target.profit;
}

/**
 * Style hex values from Bulma LUX UI
 */
export const themeColors = {
  isPrimary: "#222",
  isLight: "#f5f5f5",
  isDark: "#363636",
  isBlack: "#0a0a0a",
  isLink: "#7f888f",
  isInfo: "#209cee",
  isSuccess: "#4bbf73",
  isWarning: "#ffdd57",
  isDanger: "#d9534f",
};
