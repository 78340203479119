import React from "react";
import { themeColors } from "../../services/services";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const ChartTargetProgress = ({ label, percent, progressPercent }) => {
  const options = {
    chart: {
      height: 350,
      type: "radialBar",
      offsetY: -10,
    },
    colors: [percent >= 0 ? themeColors.isSuccess : themeColors.isDanger],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "16px",
            color: themeColors.isDark,
            offsetY: 120,
          },
          value: {
            offsetY: 76,
            fontSize: "22px",
            color: themeColors.isPrimary,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: [label],
  };

  const series = [progressPercent];

  return (
    <Chart
      options={options}
      series={series}
      type="radialBar"
      width="100%"
      height={350}
    />
  );
};

ChartTargetProgress.propTypes = {
  label: PropTypes.string,
  percent: PropTypes.number,
  progressPercent: PropTypes.number,
};

export default ChartTargetProgress;
