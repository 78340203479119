import React from "react";
import PropTypes from "prop-types";
import ChartTargets from "../Chart/Targets";
import { percentDiff } from "../../services/services";

const TradeTargetExitsCard = ({ trade, currentPrice }) => {
  const { target, coinTarget } = trade;

  return (
    <div className="box">
      <h3 className="subtitle">Target Exits</h3>

      <div className="content">
        <dl>
          <dt>Profit price</dt>
          <dd>
            {target.profit} {coinTarget}{" "}
            <span className="is-size-5">
              ({percentDiff(trade, target.profit)}%)
            </span>
          </dd>
          <dt>Stop loss price</dt>
          <dd>
            {target.stopLoss} {coinTarget}{" "}
            <span className="is-size-5">
              ({percentDiff(trade, target.stopLoss)}%)
            </span>
          </dd>
        </dl>
      </div>

      <ChartTargets currentPrice={currentPrice} trade={trade} />
    </div>
  );
};

TradeTargetExitsCard.propTypes = {
  trade: PropTypes.object,
  currentPrice: PropTypes.number,
};

export default TradeTargetExitsCard;
