import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { themeColors } from "../../services/services";

const calculateCoinPercentUsage = trades => {
  let mapper = new Map();

  for (let trade of trades) {
    let { coinBase } = trade;
    let count = mapper.get(coinBase) || 0;
    mapper.set(coinBase, count + 1);
  }

  return mapper;
};

const DashboardCoinsTraded = ({ trades = [] }) => {
  const data = calculateCoinPercentUsage(trades);
  let series = [];
  let labels = [];

  for (let [key, value] of data) {
    labels.push(key.toUpperCase());
    series.push(value);
  }

  const options = {
    chart: {
      width: "100%",
      type: "pie"
    },
    colors: Object.values(themeColors),

    labels,
    legend: {
      position: "bottom"
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            fontSize: "8px"
          }
        }
      }
    ]
  };

  return (
    <article className="box">
      <h2 className="subtitle">Coins Traded</h2>
      <Chart options={options} series={series} type="pie" width="100%" />
    </article>
  );
};

DashboardCoinsTraded.propTypes = {
  trades: PropTypes.array
};

export default DashboardCoinsTraded;
