import React, { useContext } from "react";
import FirebaseAuth from "react-firebaseui/FirebaseAuth";
//import app from "../../base";
import { FirebaseContext } from "../../components/Firebase/context";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { addUser } from "../../components/User/api";

const UserFirebaseUIWrapper = () => {
  const { app } = useContext(FirebaseContext);

  // Configure FirebaseUI (used for Google and Facebook auth providers).
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        const isNewUser = authResult.additionalUserInfo.isNewUser;

        if (isNewUser) {
          addUser(authResult);
        }

        return false;
      },
    },
  };

  return <FirebaseAuth uiConfig={uiConfig} firebaseAuth={app.auth()} />;
};

export default UserFirebaseUIWrapper;
