import React from "react";
import logo from "../../images/trade-r-logo-gradient-green.png";
import logoDark from "../../images/trade-r-logo-dark-gradient.png";

const LogoInPage = ({ isDark }) => (
  <img
    src={isDark ? logoDark : logo}
    className="logo-in-page is-inline-block"
    alt="Trade-r logo"
  />
);

export default LogoInPage;
