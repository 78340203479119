import React from "react";
import Layout from "../../components/UI/Layout";
import UIBreadcrumbs from "../../components/UI/Breadcrumbs";
import UIDetailItem from "../../components/UI/DetailItem";

const ScreensPerformance = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <h1 className="title is-1 is-sr-only">Performance</h1>
          <UIBreadcrumbs
            crumbs={[
              { label: "Home", route: "/" },
              { label: "Performance", route: "/performance" }
            ]}
          />

          <p>show chart here of gains vs losses</p>
          <UIDetailItem title="24" subtitle="Total Trades" />
          <UIDetailItem title="$30,003.844" subtitle="Total Profit" />
          <UIDetailItem title="6" subtitle="Profitable Trades" />
          <UIDetailItem title="2" subtitle="Stop Loss Trades" />
          <p>Money saved by stop losses?</p>
        </div>
      </section>
    </Layout>
  );
};

export default ScreensPerformance;
