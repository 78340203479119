import React from "react";
import { formatPrice, percentDiff } from "../../services/services";
import ChartTargetProgress from "../Chart/TargetProgress";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TradeSoldStatusCard = ({ trade }) => {
  const { coinTarget, sell, target } = trade;
  const percent = percentDiff(trade);
  const targetProfitPercent = percentDiff(trade, target.profit);
  const targetStopLossPercent = percentDiff(trade, target.stopLoss);

  let progressPercent =
    percent > 0
      ? Number(((percent / targetProfitPercent) * 100).toFixed(2))
      : Number(((percent / targetStopLossPercent) * 100).toFixed(2));

  let chartLabel = "Percent of Target";
  if (percent > 0 && progressPercent > 100) {
    chartLabel = "Sold past target";
  } else if (percent < 0 && progressPercent > 100) {
    chartLabel = "Sold below stop loss";
  } else if (percent < 0) {
    chartLabel = "Sold before stop loss";
  }

  return (
    <div className="box">
      <h3 className="subtitle">Sell Analysis</h3>

      <div className="level is-mobile has-text-centered no-bottom-padding">
        <div className="level-item">
          <div>
            <div className="heading">Sold Price ({coinTarget})</div>
            <div className="title is-4">{formatPrice(sell.price)}</div>
          </div>
        </div>
        <div className="level-item">
          <div>
            <div className="heading">
              Target {percent >= 0 ? "Profit" : "Stop Loss"}
              <br />
              Price ({coinTarget})
            </div>
            <div className="title is-4">
              {formatPrice(percent >= 0 ? target.profit : target.stopLoss)}
            </div>
          </div>
        </div>
      </div>
      <ChartTargetProgress
        label={chartLabel}
        percent={percent}
        progressPercent={progressPercent}
      />
      <p className="notification is-size-7-mobile">
        <span className="icon">
          <FontAwesomeIcon icon="exclamation-triangle" />
        </span>
        <span>
          <strong>Pro tip:</strong> Progress meter should be close to 100% as
          possible.
        </span>
      </p>
      {/* <div className="level has-text-centered no-bottom-padding">
        <div className="level-item">
          <div>
            <div className="heading">Profit</div>
            <div className="title is-3">{formatPrice(profit)}</div>
          </div>
        </div>
        <div className="level-item">
          <div>
            <div className="heading">Profit Preferred Currency</div>
            <div className="title is-3">
              {formatPrice(profitPreferredCurrency)}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

TradeSoldStatusCard.propTypes = {
  trade: PropTypes.object,
};

export default TradeSoldStatusCard;
