import React from "react";
import PropTypes from "prop-types";
import TradeListItemCard from "./ListItemCard";

const TradeList = ({ trades = [], currentPrices = {} }) => {
  // TODO: Some bug appears in here when loading after adding a new trade
  return Object.keys(currentPrices).length === 0 ? null : (
    <ul className="columns is-multiline">
      {trades.length > 0 ? (
        trades.map((trade) => {
          return (
            <li key={trade.id} className="column is-half">
              <div className="container">
                <TradeListItemCard
                  trade={trade}
                  currentPrice={
                    currentPrices[trade.coinBase][
                      trade.coinTarget.toLowerCase() === "usdc"
                        ? "usd"
                        : trade.coinTarget.toLowerCase()
                    ]
                  }
                />
              </div>
            </li>
          );
        })
      ) : (
        <p className="notification">No active trades exist</p>
      )}
    </ul>
  );
};

TradeList.propTypes = {
  trades: PropTypes.array,
  currentPrices: PropTypes.object,
};

export default TradeList;
