import React, { useState } from "react";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [userPreferences, setUserPreferences] = useState();
  const [trades, setTrades] = useState();

  const updateTrades = trades => {
    // Sort Buy Date descending
    const sorted = trades.sort(
      (a, b) => b.buy.date.seconds - a.buy.date.seconds
    );

    setTrades(sorted);
  };

  const updateUserPreferences = ({ dateAdded, preferredCurrency }) => {
    setUserPreferences({ dateAdded, preferredCurrency });
  };

  return (
    <UserContext.Provider
      value={{
        trades,
        userPreferences,
        updateTrades,
        updateUserPreferences
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
