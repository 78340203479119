import React from "react";
import PropTypes from "prop-types";
import {
  calculateTradesProfit,
  formatPrice,
  getMonthlySoldTrades,
  getYearlySoldTrades,
} from "../../services/services";
import { supportedCoins } from "../../services/supported-coins";
import Progress from "../UI/Progress";

const DashboardSnapShotsAlt = ({
  trades = [],
  userPreferences,
  coinBaseProfitMap,
  unit,
}) => {
  if (!userPreferences) return <Progress />;

  function getTopCoin() {
    const first = [...coinBaseProfitMap][0];
    if (!first) return;
    const coin = supportedCoins.find((coin) => coin.id === first[0]);

    // Calculate win %
    const topCoinTrades = trades.filter((trade) => trade.coinBase === coin.id);
    const totalProfitableTrades = topCoinTrades.filter(
      (trade) => trade.profit > 0
    ).length;
    return {
      coinBase: first[0],
      profit: first[1],
      image: coin.image,
      symbol: coin.symbol.toUpperCase(),
      winPercent: parseInt(
        (totalProfitableTrades / topCoinTrades.length) * 100
      ),
    };
  }

  function getBottomCoin() {
    const last = [...coinBaseProfitMap][coinBaseProfitMap.size - 1];

    if (!last) return;
    const coin = supportedCoins.find((coin) => coin.id === last[0]);

    // Calculate win %
    const bottomCoinTrades = trades.filter(
      (trade) => trade.coinBase === coin.id
    );
    const totalProfitableTrades = bottomCoinTrades.filter(
      (trade) => trade.profit > 0
    ).length;
    return {
      coinBase: last[0],
      profit: last[1],
      image: coin.image,
      symbol: coin.symbol.toUpperCase(),
      winPercent: parseInt(
        (totalProfitableTrades / bottomCoinTrades.length) * 100
      ),
    };
  }

  const { preferredCurrency } = userPreferences;
  const decimalPlaces = preferredCurrency === "btc" ? 6 : 2;
  const yearlyTrades = getYearlySoldTrades(trades);
  const monthlyTrades = getMonthlySoldTrades(trades);
  const totalTrades = trades.length;
  const activeTrades = trades.filter((trade) => trade.active === true).length;
  const topCoin = getTopCoin();
  const bottomCoin = getBottomCoin();

  return (
    <div>
      <div className="columns">
        <div className="column is-one-quarter">
          <div className="box notification is-primary">
            <div className="heading">Gains Total ({preferredCurrency})</div>
            <div className="title">
              {formatPrice(calculateTradesProfit(trades), decimalPlaces)}
            </div>
            <div className="level is-mobile">
              <div className="level-item">
                <div>
                  <div className="heading">Monthly {unit}</div>
                  <div className="title is-5">
                    {formatPrice(
                      calculateTradesProfit(monthlyTrades),
                      decimalPlaces
                    )}
                  </div>
                </div>
              </div>
              <div className="level-item">
                <div>
                  <div className="heading">Yearly {unit}</div>
                  <div className="title is-5">
                    {formatPrice(
                      calculateTradesProfit(yearlyTrades),
                      decimalPlaces
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-one-quarter">
          {topCoin && (
            <div className="box notification is-success">
              <div className="heading">Top Coin Traded</div>
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <figure className="image is-32x32">
                      <img src={topCoin.image} alt="coin logo" />
                    </figure>
                  </div>
                  <div className="level-item">
                    <div className="title">{topCoin.symbol}</div>
                    <br />
                  </div>
                </div>
              </div>
              <div className="level is-mobile">
                <div className="level-item">
                  <div>
                    <div className="heading">Overall {unit}</div>
                    <div className="title is-5">
                      {formatPrice(topCoin.profit, decimalPlaces)}
                    </div>
                  </div>
                </div>
                <div className="level-item">
                  <div>
                    <div className="heading">Success %</div>
                    <div className="title is-5">{topCoin.winPercent}%</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="column is-one-quarter">
          {bottomCoin && (
            <div className="box notification is-warning">
              <div className="heading">Worst Coin Traded</div>
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <figure className="image is-32x32">
                      <img src={bottomCoin.image} alt="coin logo" />
                    </figure>
                  </div>
                  <div className="level-item">
                    <div className="title">{bottomCoin.symbol}</div>
                    <br />
                  </div>
                </div>
              </div>
              <div className="level is-mobile">
                <div className="level-item">
                  <div>
                    <div className="heading">Overall {unit}</div>
                    <div className="title is-5">
                      {formatPrice(bottomCoin.profit, decimalPlaces)}
                    </div>
                  </div>
                </div>
                <div className="level-item">
                  <div>
                    <div className="heading">Success %</div>
                    <div className="title is-5">{bottomCoin.winPercent}%</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="column is-one-quarter">
          <div className="box notification is-info">
            <div className="level is-mobile">
              <div className="level-item">
                <div>
                  <div className="heading">Active Trades</div>
                  <div className="title">{activeTrades}</div>
                </div>
              </div>
              <div className="level-item">
                <div>
                  <div className="heading">Closed Trades</div>
                  <div className="title">{totalTrades - activeTrades}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="column is-one-quarter">
          <div className="box notification is-warning">
            <div className="heading">Profitable Exits</div>
            <div className="title">{parseInt(wins.percent)}%</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

DashboardSnapShotsAlt.propTypes = {
  trades: PropTypes.array,
  userPreferences: PropTypes.object,
  coinBaseProfitMap: PropTypes.object,
};

export default DashboardSnapShotsAlt;
