import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDown,
  faCalendar,
  faCheckSquare,
  faChartLine,
  faCoffee,
  faComments,
  faDirections,
  faDollarSign,
  faEnvelope,
  faEuroSign,
  faExclamationTriangle,
  faFlushed,
  faFrownOpen,
  faGraduationCap,
  faHandHoldingUsd,
  faHandPointRight,
  faHandRock,
  faInfo,
  faPlus,
  faPoundSign,
  faRubleSign,
  faSearchMinus,
  faSearchPlus,
  faSmileBeam,
  faStream,
  faTachometerAlt,
  faTasks,
  faTimes,
  faTrophy,
  faUserCircle,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

function setupFontAwesome() {
  library.add(
    fab,
    faAngleDown,
    faCalendar,
    faChartLine,
    faCheckSquare,
    faCoffee,
    faComments,
    faDirections,
    faDollarSign,
    faEnvelope,
    faEuroSign,
    faExclamationTriangle,
    faFlushed,
    faFrownOpen,
    faGraduationCap,
    faHandHoldingUsd,
    faHandPointRight,
    faHandRock,
    faInfo,
    faPlus,
    faPoundSign,
    faRubleSign,
    faSearchMinus,
    faSearchPlus,
    faSmileBeam,
    faStream,
    faTachometerAlt,
    faTasks,
    faTimes,
    faTrophy,
    faUserCircle,
    faUserShield
  );
}

export default setupFontAwesome;
