import React, { useEffect } from "react";
import Layout from "../components/UI/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { analytics } from "../components/Firebase/context";

const ScreensNoMatch = () => {
  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "404" });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Page Not Found | Trade-r</title>
      </Helmet>
      <section className="section">
        <div
          className="container has-text-centered"
          style={{ minHeight: "300px" }}
        >
          <h1 className="title is-3">
            <FontAwesomeIcon icon="flushed" /> 404 Page doesn't exist
          </h1>
          <Link to="/">Back to home</Link>
        </div>
      </section>
    </Layout>
  );
};

export default ScreensNoMatch;
