import React from "react";
import { formatPrice } from "../../services/services";

const UINotificationStopLosses = ({ price, tradeType }) => {
  if (!price) return null;

  return (
    <div className="notification is-link">
      <table className="table is-bordered is-fullwidth">
        <caption className="is-size-7 has-text-white is-italic">
          Common stop loss %s
        </caption>
        <thead>
          <tr>
            <th> -5% </th>
            <th> -10% </th>
            <th> -15% </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {formatPrice(tradeType === "LONG" ? price * 0.95 : price * 1.05)}
            </td>
            <td>
              {formatPrice(tradeType === "LONG" ? price * 0.9 : price * 1.1)}
            </td>
            <td>
              {formatPrice(tradeType === "LONG" ? price * 0.85 : price * 1.15)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UINotificationStopLosses;
