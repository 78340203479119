import React from "react";
import HomeStepsWrapper from "./StepsWrapper";
import LogoInPage from "../UI/LogoInPage";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import phonesScreenshot from "../../images/phones-monitor-progress.png";
import HomePhoneColors from "./PhoneColors";

const styles = {
  coloredNotification: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginBottom: "0",
  },
};

const HomeMonitorProgress = ({ currentUser }) => {
  return (
    <section className={`hero is-link`}>
      <div className="hero-body">
        <div className="container content">
          <h3 className="title is-size-3 ">2. Monitor Progress</h3>

          <div className="columns">
            <div className="column is-one-quarter">
              <p className="is-size-4 is-size-5-mobile">
                <LogoInPage /> helps visualize progress towards{" "}
                <span className="is-italic">Trade Targets</span> with one quick
                glance.
              </p>
              {/* <p>
                <span className="tag is-success">Green</span> = sell for profit.
                <br />
                <span className="tag is-light">White</span> = do nothing.
                <br />
                <span className="tag is-danger">Red</span> = sell at stop loss
                exit.
              </p> */}
              {/* <p>
                <div
                  className="notification is-success"
                  style={styles.coloredNotification}
                >
                  Sell for profit
                </div>
                <div
                  className="notification is-danger"
                  style={styles.coloredNotification}
                >
                  Sell at stop loss
                </div>
                <div
                  className="notification is-light"
                  style={styles.coloredNotification}
                >
                  Hold steady
                </div>
              </p> */}
              <p>
                Don't think. Don't care (we know, it sounds weird as a human
                being).{" "}
                <span className="is-italic">
                  You're learning good trading discipline!
                </span>
              </p>

              <p>
                <Link
                  to={`${currentUser ? "/trade/list" : "/user/sign-in"}`}
                  className={`button is-primary ${
                    isMobile ? "is-fullwidth" : ""
                  }`}
                >
                  View Trades
                </Link>
              </p>
            </div>
            <div className="column is-three-quarters">
              {/* <img src={phonesScreenshot} alt="Trade-r monitor screens" /> */}
              <HomePhoneColors />
            </div>
          </div>

          {/* <p className="is-size-4 is-size-5-mobile">
            <LogoInPage /> helps visualize progress towards{" "}
            <span className="is-italic">Trade Targets</span> with one quick
            glance.
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default HomeMonitorProgress;
