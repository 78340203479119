import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/UI/Layout";
import { AuthContext } from "../../components/Auth";
import {
  deleteTrade,
  getPriceHistory,
  getPriceHistoryRange,
  getUserTrades,
} from "../../components/Trade/api";
import Trade from "../../components/Trade/Trade";
import { useHistory, useParams } from "react-router-dom";
import UIPageLoader from "../../components/UI/PageLoader";
import { UserContext } from "../../components/User/UserContext";
import { countTradeDays } from "../../services/services";
import moment from "moment";
import { Helmet } from "react-helmet";
import { analytics } from "../../components/Firebase/context";

const ScreensTrade = () => {
  const [trade, setTrade] = useState();
  const [priceHistory, setPriceHistory] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const { trades = [], updateTrades } = useContext(UserContext);
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Trade" });
  }, []);

  useEffect(() => {
    async function fn() {
      if (trades.length === 0) {
        return;
      }

      const userContextTrade = trades.find((t) => t.id === params.id);
      if (!userContextTrade) return;

      setTrade(userContextTrade);

      const days = countTradeDays(userContextTrade);

      const response = userContextTrade.active
        ? await getPriceHistory(
            userContextTrade.coinBase,
            userContextTrade.coinTarget,
            days + 1
          )
        : await getPriceHistoryRange(
            userContextTrade.coinBase,
            userContextTrade.coinTarget,
            moment(userContextTrade.buy.date.toDate()).subtract(1, "days"),
            moment(userContextTrade.sell.date.toDate()).add(1, "days")
          );

      // Error getting trade
      if (response.error) {
        setIsLoading(false);
        return setError(true);
      }

      setPriceHistory(response);
      setIsLoading(false);
    }
    fn();
  }, [params.id, trades]);

  const handleDeleteClick = async () => {
    const result = await deleteTrade(currentUser.uid, trade.id);
    // TODO: Handle error deleting trade

    const trades = await getUserTrades(currentUser.uid);
    updateTrades(trades);
    history.push("/trade/list", { trade });
  };

  return isLoading ? (
    <UIPageLoader />
  ) : (
    <Layout>
      <Helmet>
        <title>Trade | Trade-r</title>
      </Helmet>
      {error && (
        <p className="notification is-danger">
          There was an error retrieving the trade
        </p>
      )}

      {trade && (
        <Trade
          trade={trade}
          handleDeleteClick={handleDeleteClick}
          priceHistory={priceHistory}
        />
      )}
    </Layout>
  );
};

export default ScreensTrade;
