import React from "react";
import {
  formatPrice,
  getCoinBaseProfitMap,
  themeColors,
} from "../../services/services";
import Chart from "react-apexcharts";
import { preferredCurrencies } from "../../services/preferred-currencies";
import PropTypes from "prop-types";
import { supportedCoins } from "../../services/supported-coins";
import { isMobile } from "react-device-detect";

const ChartProfitByCoin = ({ userPreferences, trades }) => {
  if (!userPreferences) {
    return null;
  }
  const { preferredCurrency = "" } = userPreferences;
  let categories = [];
  let profits = [];
  let decimalPlaces = preferredCurrency !== "bitcoin" ? 2 : 6;

  const unit = preferredCurrency
    ? preferredCurrencies.find((c) => c.value === preferredCurrency).unit
    : "";

  const profitMap = getCoinBaseProfitMap(trades);

  for (let [key, value] of profitMap) {
    const symbol = supportedCoins.find((coin) => coin.id === key).symbol;
    categories.push(symbol.toUpperCase());
    profits.push(formatPrice(value, decimalPlaces));
  }

  const chartHeight = 400;
  const title = isMobile
    ? {}
    : {
        text: `Profits in preferred currency (${unit} ${preferredCurrency.toUpperCase()})`,
        floating: true,
        align: "center",
        style: {
          color: themeColors.isDark,
        },
      };

  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
        colors: {
          ranges: [
            {
              from: -10000,
              to: -1,
              color: themeColors.isDanger,
            },
            {
              from: 0,
              to: 10000,
              color: themeColors.isSuccess,
            },
          ],
        },
        columnWidth: "80%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    title,
    tooltip: {
      marker: {
        show: false,
      },
    },
    xaxis: {
      categories,
    },
    yaxis: {
      labels: {
        formatter: function (val, index) {
          return `${unit}${parseInt(val)}`;
        },
      },
    },
  };

  const series = [
    {
      name: unit,
      data: profits,
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height={chartHeight}
    />
  );
};

ChartProfitByCoin.propTypes = {
  soldDataMap: PropTypes.array,
  userPreferences: PropTypes.object,
};

export default ChartProfitByCoin;
