import React, { useState, useEffect, useContext } from "react";
import { updateUser } from "../User/api";
import toastWrapper from "../../services/toast-wrapper";
import { updatePreferredCurrencyPrices } from "../Trade/api";
import { UserContext } from "../User/UserContext";
import PropTypes from "prop-types";
import { preferredCurrencies } from "../../services/preferred-currencies";

const SettingsForm = ({ user, userId }) => {
  const [currency, setCurrency] = useState(preferredCurrencies[0].value);
  const [loading, setLoading] = useState(false);
  const {
    trades,
    updateTrades,
    updateUserPreferences,
    userPreferences,
  } = useContext(UserContext);

  useEffect(() => {
    if (!user) return;
    setCurrency(user.preferredCurrency);
  }, [user]);

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await updateUser(user.id, {
      preferredCurrency: currency,
    });

    if (response && response.error) {
      setLoading(false);
      return toastWrapper({
        message: response.message,
        type: "is-danger",
        duration: 10000,
      });
    }

    const freshTrades = await updatePreferredCurrencyPrices(
      trades,
      currency,
      userId
    );

    if (freshTrades.error) {
      setLoading(false);
      return toastWrapper({
        message: freshTrades.message,
        type: "is-danger",
      });
    }

    // Update UserContext
    updateTrades(freshTrades);
    updateUserPreferences({ ...userPreferences, preferredCurrency: currency });

    setLoading(false);

    toastWrapper({
      message: "Settings successfully updated",
      type: "is-success",
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="field">
        <label className="label">Preferred Currency</label>
        <p className="control">
          <span className="select ">
            <select onChange={handleCurrencyChange} value={currency}>
              {preferredCurrencies.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </span>
        </p>
      </div>
      <div className="field">
        <div className="buttons">
          <button
            type="submit"
            className={`button is-primary  ${loading ? "is-loading" : ""}`}
            disabled={loading ? true : false}
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

SettingsForm.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
};

export default SettingsForm;
