import React from "react";
import { themeColors } from "../../services/services";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const ChartProfitTimeline = ({ userPreferences, trades, unit }) => {
  if (!userPreferences) {
    return null;
  }
  const { preferredCurrency = "" } = userPreferences;
  let decimalPlaces = preferredCurrency !== "bitcoin" ? 0 : 6;
  let preferredCurrencyProfits = [];
  let dates = [];
  let profitCounter = 0;

  // Order trades by sold date
  const sorted = trades.sort(
    (a, b) => a.sell.date.seconds - b.sell.date.seconds
  );

  // Build series data
  for (let trade of sorted) {
    profitCounter = profitCounter + trade.profitPreferredCurrency;
    preferredCurrencyProfits.push(profitCounter);
    dates.push(trade.sell.date.toMillis());
  }

  const chartHeight = 400;
  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    colors: [themeColors.isInfo],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    labels: dates,
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      decimalsInFloat: decimalPlaces,
      opposite: true,
      title: {
        text: `Preferred Currency ${unit}`,
      },
    },
  };

  const series = [
    {
      name: "Preferred",
      data: preferredCurrencyProfits,
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      height={chartHeight}
    />
  );
};

ChartProfitTimeline.propTypes = {
  trades: PropTypes.array,
  userPreferences: PropTypes.object,
  unit: PropTypes.string,
};

export default ChartProfitTimeline;
