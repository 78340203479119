import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatPair,
  formatPrice,
  percentDiff,
  shouldSellProfit,
  shouldSellLoss,
} from "../../services/services";
import { supportedCoins } from "../../services/supported-coins";
import { isMobile } from "react-device-detect";
import UIProgress from "../UI/Progress";

const TradeHeader = ({ trade, currentPrice, heroSize }) => {
  const { active, buy, tradeType } = trade;
  let msg = "HOLD";
  let className = active ? "is-white" : "is-primary";
  const coinImg = supportedCoins.find((coin) => coin.id === trade.coinBase)
    .image;
  const percentChangeCurrentPrice = percentDiff(trade, currentPrice);

  if (active) {
    if (shouldSellProfit(trade, currentPrice)) {
      msg = "SELL";
      className = "is-success";
    }
    if (shouldSellLoss(trade, currentPrice)) {
      msg = "SELL";
      className = "is-danger";
    }
  }

  return !trade || !currentPrice ? (
    <UIProgress />
  ) : (
    <section className={`hero has-ribbon ${heroSize} ${className}`}>
      <div className={`ribbon is-size-5 is-size-6-mobile is-info`}>
        {active ? `ACTIVE` : `CLOSED`}
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="media">
            <figure className="media-left">
              <p className={`image ${isMobile ? "is-32x32" : "is-64x64"}`}>
                <img src={coinImg} alt="Coin logo" />
              </p>
            </figure>

            <div className="media-content">
              <div className="content">
                <div className="level no-bottom-padding is-mobile">
                  <div className="level-left is-size-1 is-size-4-mobile">
                    <span className="level-item">
                      {trade.coinBase.toUpperCase()}
                    </span>
                    <span className="level-item is-size-3 is-size-5-mobile">
                      {" "}
                      | {formatPair(trade)}
                    </span>
                  </div>
                </div>

                {active && (
                  <div>
                    <div className="level is-mobile title no-bottom-padding">
                      <div className="level-left">
                        <div className="level-item">
                          <span
                            className="is-size-1 is-size-2-mobile"
                            title="Current price"
                          >
                            {formatPrice(currentPrice)}
                          </span>
                        </div>
                        <div className="level-item is-size-3 is-size-5-mobile">
                          <span
                            className=""
                            title="Price change since purchase"
                          >
                            {formatPrice(currentPrice - buy.price)}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <div
                            className={`is-size-2 is-size-3-mobile ${
                              ["is-danger", "is-success"].indexOf(className) <
                                0 &&
                              (percentChangeCurrentPrice > 0
                                ? "has-text-success"
                                : "has-text-danger")
                            }`}
                          >
                            {percentChangeCurrentPrice}%
                          </div>
                        </div>
                        <div className="level-item">
                          {tradeType !== "LONG" && (
                            <p>
                              <span className="tag is-info">SHORT</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="subtitle is-size-5-mobile">
                      <FontAwesomeIcon icon="hand-point-right" />
                      <span style={{ paddingLeft: ".5rem" }}>{msg}</span>
                    </p>
                  </div>
                )}

                {/* {!active && (
                  <div>
                    <div
                      className={`is-size-2 is-size-3-mobile ${
                        percentChangeSold > 0
                          ? "has-text-success"
                          : "has-text-danger"
                      }`}
                      style={{ paddingBottom: "0.5rem" }}
                    >
                      {percentChangeSold}%
                    </div>
                    <div className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <span className="title is-size-4">
                            {formatPrice(trade.profit)} {coinTarget}
                          </span>
                        </div>
                        <div className="level-item">
                          <span className="subtitle">
                            {formatPreferredCurrency(
                              trade.profitPreferredCurrency,
                              preferredCurrency
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TradeHeader.propTypes = {
  trade: PropTypes.object,
  currentPrice: PropTypes.number,
};

export default TradeHeader;
