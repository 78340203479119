import { app } from "../../components/Firebase/context";
import axios from "axios";
import {
  filterUniqueTradePairs,
  formatPrice,
  getTradePairs,
} from "../../services/services";
import { supportedVsCurrencies } from "../../services/exchanges";
import moment from "moment";

const usersCollectionRef = app.firestore().collection("users");
const coinGeckoApiUrl = "https://api.coingecko.com/api/v3";

export async function addTrade(userId, data) {
  try {
    const docRef = await usersCollectionRef
      .doc(userId)
      .collection("trades")
      .add(data);

    const docData = await docRef.get();
    return docData.data();
  } catch (error) {
    console.log("Error adding trade", error);
    return Promise.resolve(null);
  }
}

export async function deleteTrade(userId, tradeId) {
  try {
    await usersCollectionRef
      .doc(userId)
      .collection("trades")
      .doc(tradeId)
      .delete();
  } catch (e) {
    console.log("Error deleting trade", e);
  }
}

export async function getCoinTargets({ exchange, coinBase }) {
  try {
    const result = await axios.get(
      `${coinGeckoApiUrl}/exchanges/${exchange}/tickers?coin_ids=${coinBase}`
    );

    // Filter the list, but this limits some exchanges, like BAT/USDC
    const filteredTickers = result.data.tickers.filter(
      (ticker) =>
        supportedVsCurrencies.indexOf(ticker.target.toLowerCase()) > -1
    );

    const tickers = filteredTickers.map((ticker) => ({
      id: ticker.target,
      label: `${ticker.base}/${ticker.target}`,
    }));

    return tickers;
  } catch (e) {
    console.log("Error in getCoinTargets", e);
    return Promise.resolve();
  }
}

/**
 * Get a coins current price
 * @param {string} coinId
 * @param {string} currency - id "usd" or something in lowercase
 */
export async function getCurrentPrice(coinId, currency) {
  const formattedCurrency =
    currency.toLowerCase() === "usdc" ? "usd" : currency.toLowerCase();
  try {
    const result = await axios.get(
      `${coinGeckoApiUrl}/simple/price?ids=${coinId}&vs_currencies=${formattedCurrency}`
    );

    return result.data[coinId][formattedCurrency];
  } catch (e) {
    console.log("Error getting current price", e);
    return Promise.resolve();
  }
}

/**
 * Get current prices of coins
 * @param {Array} trades
 */
export async function getCurrentPrices(trades) {
  try {
    const tradePairs = getTradePairs(trades);
    const uniqueTradePairs = filterUniqueTradePairs(tradePairs);
    const coinBases = uniqueTradePairs.map((pair) => pair.coinBase);
    const coinTargets = uniqueTradePairs.map((pair) =>
      pair.coinTarget.toLowerCase() === "usdc"
        ? "usd"
        : pair.coinTarget.toLowerCase()
    );

    const result = await axios.get(
      `${coinGeckoApiUrl}/simple/price?ids=${coinBases.join(
        ","
      )}&vs_currencies=${coinTargets.join(",")}`
    );

    return result.data;
  } catch (e) {
    console.log("Error getCurrentPrices()", e);
    return Promise.resolve();
  }
}

/**
 * Get a coin pairing's price history from Coin Gecko API
 * @param {String} coinId coinGecko's coin id
 * @param {String} vsCurrency coinGecko's convention, ie. "usd"
 * @param {Number} numDays
 * @returns {Array} an array of arrays ie, [timestamp, price]
 */
export async function getPriceHistory(
  coinId,
  vsCurrency = "usd",
  numDays = 120
) {
  try {
    vsCurrency = vsCurrency.toLowerCase() === "usdc" ? "usd" : vsCurrency;
    const result = await axios.get(
      `${coinGeckoApiUrl}/coins/${coinId}/market_chart?vs_currency=${vsCurrency.toLowerCase()}&days=${numDays}`
    );

    // Really long price values are returned, so cap 'em
    const prices = result.data.prices.map((item) => [
      item[0],
      formatPrice(item[1]),
    ]);
    return prices;
  } catch (e) {
    console.log("Error in getPriceHistory()", e);
    return {
      error: e,
      message: `There was an error getting trade price history: ${e}`,
    };
  }
}

export async function getPriceHistoryRange(
  coinId,
  vsCurrency = "usd",
  from,
  to
) {
  try {
    vsCurrency = vsCurrency.toLowerCase() === "usdc" ? "usd" : vsCurrency;
    const result = await axios.get(
      `${coinGeckoApiUrl}/coins/${coinId}/market_chart/range?vs_currency=${vsCurrency.toLowerCase()}&from=${moment(
        from
      ).unix()}&to=${moment(to).unix()}`
    );

    // Really long price values are returned, so cap 'em
    const prices = result.data.prices.map((item) => [
      item[0],
      formatPrice(item[1]),
    ]);
    return prices;
  } catch (e) {
    console.log("Error in getPriceHistoryRange()", e);
    return {
      error: e,
      message: `There was an error getting trade price history: ${e}`,
    };
  }
}

/**
 * Get coin data for a given date
 * @param {String} coinId
 * @param {Object} date Any kind of date string / timestamp
 * @returns {Object}
 */
export async function getPricesOnDate(coinId, date) {
  try {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    const result = await axios.get(
      `${coinGeckoApiUrl}/coins/${coinId}/history?date=${formattedDate}&localization=false`
    );

    return result.data.market_data.current_price;
  } catch (e) {
    console.log("Error getPricesOnDate()", e);
    return Promise.resolve();
  }
}

// export async function getPriceHistories(coins) {
//   //console.log("TCL: getPriceHistories -> coins", coins);

//   try {
//     const requests = coins.map(
//       ({ coinBase, coinTarget: vsCurrency, numDays = 120 }) =>
//         axios.get(
//           `${coinGeckoApiUrl}/coins/${coinBase}/market_chart?vs_currency=${vsCurrency}&days=${numDays}`
//         )
//     );
//     const results = await axios.all(requests);
//     const resultsInPrices = results.map(result =>
//       result.data.prices.map(item => [item[0], formatPrice(item[1])])
//     );
//     //console.log("TCL: getPriceHistories -> resultsInPrices", resultsInPrices);
//     return resultsInPrices;
//   } catch (e) {
//     console.log("Error getTradePairHistory()", e);
//     return Promise.resolve([]);
//   }
// }

/**
 * Get profit amount in a user's preferred currency
 * @param {Object} trade
 * @param {string} preferredCurrency
 * @returns {Object}
 */
export async function getPreferredCurrencyPrices(trade, preferredCurrency) {
  try {
    const { buy, sell, coinBase, coinTarget, tradeType } = trade;
    const promises = [getPricesOnDate(coinBase, trade.buy.date.toDate())];
    if (!trade.active) {
      promises.push(getPricesOnDate(coinBase, trade.sell.date.toDate()));
    }
    const results = await Promise.all(promises);
    let preferredPriceBuy = results[0][preferredCurrency.toLowerCase()];
    let preferredPriceSell = trade.active
      ? null
      : results[1][preferredCurrency.toLowerCase()];

    let profit = null;

    // Closed trade
    if (!trade.active) {
      let ct =
        coinTarget.toLowerCase() === "usdc" ? "usd" : coinTarget.toLowerCase();

      // Coin target same as user preferred currency, so use user values
      if (ct === preferredCurrency) {
        preferredPriceBuy = buy.price;
        preferredPriceSell = sell.price;
      }

      profit =
        tradeType === "LONG"
          ? formatPrice(
              preferredPriceSell * sell.quantity -
                preferredPriceBuy * buy.quantity
            )
          : formatPrice(
              preferredPriceBuy * buy.quantity -
                preferredPriceSell * sell.quantity
            );
    }

    return { preferredPriceBuy, preferredPriceSell, profit };
  } catch (e) {
    console.log(`Error in getPreferredCurrencyPrices(): ${e}`);
    return Promise.resolve();
  }
}

export async function getTrade(userId, tradeId) {
  if (!userId) return Promise.resolve(null);
  try {
    const doc = await usersCollectionRef
      .doc(userId)
      .collection("trades")
      .doc(tradeId)
      .get();

    return { id: tradeId, ...doc.data() };
  } catch (error) {
    console.log("error getting trade", error);
    return Promise.resolve(null);
  }
}

export async function getTradeAndPriceHistory(userId, tradeId) {
  try {
    const trade = await getTrade(userId, tradeId);
    const priceHistory = await getPriceHistory(
      trade.coinBase,
      trade.coinTarget,
      moment().diff(moment(trade.buy.date.toDate()), "days")
    );
    return { trade, priceHistory };
  } catch (error) {
    console.log("Error getTradeAndPriceHistory()");
  }
}

export async function getTradesAndCurrentPrices(userId) {
  try {
    const trades = await getUserTrades(userId);
    const currentPrices = await getCurrentPrices(trades);

    return {
      trades,
      currentPrices,
    };
  } catch (error) {
    console.log("Error in getTradesAndPriceHistories()");
    return Promise.resolve({
      error,
      message: `There was an error getting trades and current prices: ${error}`,
    });
  }
}

export async function getTradesAndPriceHistories(userId) {
  try {
    const trades = await getUserTrades(userId);

    let tradePairs = trades.map((trade) => ({
      coinBase: trade.coinBase,
      coinTarget: trade.coinTarget,
      priceHistory: [],
    }));

    // Remove duplicate trade pairs. For example if a user has 4 BTC/USD trades,
    // we don't need 4 identical network calls
    let uniqueTradePairs = [];
    tradePairs.forEach((pair) => {
      if (
        !uniqueTradePairs.find(
          (item) =>
            item.coinBase === pair.coinBase &&
            item.coinTarget === pair.coinTarget
        )
      ) {
        uniqueTradePairs.push(pair);
      }
    });

    const promises = [];

    // Get price histories of trade pairs
    for (let tradePair of uniqueTradePairs) {
      promises.push(getPriceHistory(tradePair.coinBase, tradePair.coinTarget));
    }

    // Get current prices.  Result will be the last entry in promises array.
    promises.push(getCurrentPrices(trades));

    // Execute bundled network requests
    const promiseResults = await Promise.all(promises);

    // Put price histories on each trade
    uniqueTradePairs.forEach((trade, i) => {
      trade.priceHistory = promiseResults[i];
    });

    return {
      trades,
      tradePairHistories: uniqueTradePairs,
      currentPrices: promiseResults[promises.length - 1],
    };
  } catch (error) {
    console.log("Error in getTradesAndPriceHistories()");
  }
}

export async function getUserTrades(userId) {
  try {
    const snapshot = await usersCollectionRef
      .doc(userId)
      .collection("trades")
      .orderBy("dateAdded", "desc")
      .get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.log("Error getUserTrades()", error);
    return Promise.resolve(null);
  }
}

/**
 * Only needed in special cases
 * Updates a non-active trade's buy and sell preferredCurrency prices,
 * along with profit in preferred currency
 * @param {Array} trades
 * @param {String} preferredCurrency
 * @param {String} userId
 */
export async function updatePreferredCurrencyPrices(
  trades,
  preferredCurrency,
  userId
) {
  try {
    // Get Buy and Sell prices and profit in preferred currency
    let promises = [];
    for (let trade of trades) {
      promises.push(getPreferredCurrencyPrices(trade, preferredCurrency));
    }
    const preferredCurrencyObjects = await Promise.all(promises);

    // Update each sold Trade's preferred currency prices
    let updatePromises = [];
    trades.forEach((trade, i) => {
      const currencyObj = preferredCurrencyObjects[i];
      const obj = {
        buy: {
          ...trade.buy,
          pricePreferredCurrency: currencyObj.preferredPriceBuy,
        },
      };
      // Trade is sold, update more stuff
      if (!trade.active) {
        obj.profitPreferredCurrency = currencyObj.profit;
        obj.sell = {
          ...trade.sell,
          pricePreferredCurrency: currencyObj.preferredPriceSell,
        };
      }
      updatePromises.push(updateTrade(userId, trade.id, obj));
    });

    const updatesResponse = await Promise.all(updatePromises);

    // Return updated trade records
    const freshTrades = await getUserTrades(userId);
    return freshTrades;
  } catch (error) {
    console.log("Error in updatePreferredCurrencyPrices(): ", error);
    return Promise.resolve({
      error,
      message: `Error in updatePreferredCurrencyPrices(): ${error}`,
    });
  }
}

/* Note: this firebase update doesn't seem to return anything.  Seems odd */
export async function updateTrade(userId, tradeId, data) {
  try {
    const tradeRef = await usersCollectionRef
      .doc(userId)
      .collection("trades")
      .doc(tradeId);

    const response = await tradeRef.update(data);
    return response;
  } catch (error) {
    console.log("Error updating trade", error);
    return Promise.resolve({
      error,
      message: `There was an error updating the trade: ${error}`,
    });
  }
}
