import axios from "axios";

export async function generateSupportedCoins() {
  const top250 = await axios.get(
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=false"
  );
  const supportedCoins = top250.data.map(coin => {
    const { id, symbol, name, image } = coin;
    return { id, symbol, name, image };
  });

  return supportedCoins;
}

// We update this whenever?
export const supportedCoins = [
  {
    id: "bitcoin",
    symbol: "btc",
    name: "Bitcoin",
    image:
      "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579"
  },
  {
    id: "ethereum",
    symbol: "eth",
    name: "Ethereum",
    image:
      "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1547034048"
  },
  {
    id: "ripple",
    symbol: "xrp",
    name: "XRP",
    image:
      "https://assets.coingecko.com/coins/images/44/large/xrp.png?1564480400"
  },
  {
    id: "bitcoin-cash",
    symbol: "bch",
    name: "Bitcoin Cash",
    image:
      "https://assets.coingecko.com/coins/images/780/large/bitcoin_cash.png?1547034541"
  },
  {
    id: "tether",
    symbol: "usdt",
    name: "Tether",
    image:
      "https://assets.coingecko.com/coins/images/325/large/tether.png?1547034089"
  },
  {
    id: "litecoin",
    symbol: "ltc",
    name: "Litecoin",
    image:
      "https://assets.coingecko.com/coins/images/2/large/litecoin.png?1547033580"
  },
  {
    id: "eos",
    symbol: "eos",
    name: "EOS",
    image:
      "https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1547034481"
  },
  {
    id: "binancecoin",
    symbol: "bnb",
    name: "Binance Coin",
    image:
      "https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615"
  },
  {
    id: "bitcoin-cash-sv",
    symbol: "bsv",
    name: "Bitcoin SV",
    image:
      "https://assets.coingecko.com/coins/images/6799/large/BSV.png?1558947902"
  },
  {
    id: "stellar",
    symbol: "xlm",
    name: "Stellar",
    image:
      "https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1552356157"
  },
  {
    id: "cardano",
    symbol: "ada",
    name: "Cardano",
    image:
      "https://assets.coingecko.com/coins/images/975/large/cardano.png?1547034860"
  },
  {
    id: "tron",
    symbol: "trx",
    name: "TRON",
    image:
      "https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1547035066"
  },
  {
    id: "monero",
    symbol: "xmr",
    name: "Monero",
    image:
      "https://assets.coingecko.com/coins/images/69/large/monero_logo.png?1547033729"
  },
  {
    id: "leo-token",
    symbol: "leo",
    name: "LEO Token",
    image:
      "https://assets.coingecko.com/coins/images/8418/large/leo-token.png?1558326215"
  },
  {
    id: "chainlink",
    symbol: "link",
    name: "ChainLink",
    image:
      "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700"
  },
  {
    id: "okb",
    symbol: "okb",
    name: "OKB",
    image:
      "https://assets.coingecko.com/coins/images/4463/large/okb_token.png?1548386209"
  },
  {
    id: "huobi-token",
    symbol: "ht",
    name: "Huobi Token",
    image:
      "https://assets.coingecko.com/coins/images/2822/large/huobi-token-logo.png?1547036992"
  },
  {
    id: "cosmos",
    symbol: "atom",
    name: "Cosmos",
    image:
      "https://assets.coingecko.com/coins/images/1481/large/cosmos_hub.png?1555657960"
  },
  {
    id: "neo",
    symbol: "neo",
    name: "NEO",
    image:
      "https://assets.coingecko.com/coins/images/480/large/neo.png?1547034189"
  },
  {
    id: "iota",
    symbol: "miota",
    name: "IOTA",
    image:
      "https://assets.coingecko.com/coins/images/692/large/IOTA_Logo.png?1547034454"
  },
  {
    id: "tezos",
    symbol: "xtz",
    name: "Tezos",
    image:
      "https://assets.coingecko.com/coins/images/976/large/Tezos-logo.png?1547034862"
  },
  {
    id: "dash",
    symbol: "dash",
    name: "Dash",
    image:
      "https://assets.coingecko.com/coins/images/19/large/dash-logo.png?1548385930"
  },
  {
    id: "ontology",
    symbol: "ont",
    name: "Ontology",
    image:
      "https://assets.coingecko.com/coins/images/3447/large/ont.png?1560322209"
  },
  {
    id: "ethereum-classic",
    symbol: "etc",
    name: "Ethereum Classic",
    image:
      "https://assets.coingecko.com/coins/images/453/large/ethereum-classic-logo.png?1547034169"
  },
  {
    id: "usd-coin",
    symbol: "usdc",
    name: "USD Coin",
    image:
      "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389"
  },
  {
    id: "crypto-com-chain",
    symbol: "cro",
    name: "Crypto.com Coin",
    image:
      "https://assets.coingecko.com/coins/images/7310/large/cypto.png?1547043960"
  },
  {
    id: "maker",
    symbol: "mkr",
    name: "Maker",
    image:
      "https://assets.coingecko.com/coins/images/1364/large/maker.png?1547035390"
  },
  {
    id: "nem",
    symbol: "xem",
    name: "NEM",
    image:
      "https://assets.coingecko.com/coins/images/242/large/nem.png?1547034011"
  },
  {
    id: "dogecoin",
    symbol: "doge",
    name: "Dogecoin",
    image:
      "https://assets.coingecko.com/coins/images/5/large/dogecoin.png?1547792256"
  },
  {
    id: "basic-attention-token",
    symbol: "bat",
    name: "Basic Attention Token",
    image:
      "https://assets.coingecko.com/coins/images/677/large/basic-attention-token.png?1547034427"
  },
  {
    id: "zcash",
    symbol: "zec",
    name: "Zcash",
    image:
      "https://assets.coingecko.com/coins/images/486/large/circle-zcash-color.png?1547034197"
  },
  {
    id: "vechain",
    symbol: "vet",
    name: "VeChain",
    image:
      "https://assets.coingecko.com/coins/images/1167/large/VeChain-Logo-768x725.png?1547035194"
  },
  {
    id: "paxos-standard",
    symbol: "pax",
    name: "Paxos Standard",
    image:
      "https://assets.coingecko.com/coins/images/6013/large/paxos_standard.png?1548386291"
  },
  {
    id: "decred",
    symbol: "dcr",
    name: "Decred",
    image:
      "https://assets.coingecko.com/coins/images/329/large/decred.png?1547034093"
  },
  {
    id: "bytom",
    symbol: "btm",
    name: "Bytom",
    image:
      "https://assets.coingecko.com/coins/images/1087/large/bytom.png?1547564390"
  },
  {
    id: "qtum",
    symbol: "qtum",
    name: "Qtum",
    image:
      "https://assets.coingecko.com/coins/images/684/large/qtum.png?1547034438"
  },
  {
    id: "true-usd",
    symbol: "tusd",
    name: "TrueUSD",
    image:
      "https://assets.coingecko.com/coins/images/3449/large/TUSD.png?1559172762"
  },
  {
    id: "0x",
    symbol: "zrx",
    name: "0x",
    image:
      "https://assets.coingecko.com/coins/images/863/large/0x.png?1547034672"
  },
  {
    id: "holotoken",
    symbol: "hot",
    name: "Holo",
    image:
      "https://assets.coingecko.com/coins/images/3348/large/Holologo_Profile.png?1547037966"
  },
  {
    id: "ravencoin",
    symbol: "rvn",
    name: "Ravencoin",
    image:
      "https://assets.coingecko.com/coins/images/3412/large/ravencoin.png?1548386057"
  },
  {
    id: "bitcoin-gold",
    symbol: "btg",
    name: "Bitcoin Gold",
    image:
      "https://assets.coingecko.com/coins/images/1043/large/bitcoin-gold-logo.png?1547034978"
  },
  {
    id: "kucoin-shares",
    symbol: "kcs",
    name: "KuCoin Shares",
    image:
      "https://assets.coingecko.com/coins/images/1047/large/kcs.png?1547034982"
  },
  {
    id: "omisego",
    symbol: "omg",
    name: "OmiseGO",
    image:
      "https://assets.coingecko.com/coins/images/776/large/omisego.png?1547034534"
  },
  {
    id: "v-systems",
    symbol: "vsys",
    name: "V.SYSTEMS",
    image:
      "https://assets.coingecko.com/coins/images/7492/large/VSYS_Coin_200.png?1559454560"
  },
  {
    id: "algorand",
    symbol: "algo",
    name: "Algorand",
    image:
      "https://assets.coingecko.com/coins/images/4380/large/download.png?1547039725"
  },
  {
    id: "nano",
    symbol: "nano",
    name: "Nano",
    image:
      "https://assets.coingecko.com/coins/images/756/large/nano-coin-logo.png?1547034501"
  },
  {
    id: "augur",
    symbol: "rep",
    name: "Augur",
    image:
      "https://assets.coingecko.com/coins/images/309/large/Webp.net-resizeimage_%288%29.png?1547034073"
  },
  {
    id: "lisk",
    symbol: "lsk",
    name: "Lisk",
    image:
      "https://assets.coingecko.com/coins/images/385/large/Lisk_Logo.png?1547034119"
  },
  {
    id: "bitcoin-diamond",
    symbol: "bcd",
    name: "Bitcoin Diamond",
    image:
      "https://assets.coingecko.com/coins/images/1254/large/bitcoin-diamond.png?1547035280"
  },
  {
    id: "bittorrent-2",
    symbol: "btt",
    name: "BitTorrent",
    image:
      "https://assets.coingecko.com/coins/images/7595/large/BTT_Token_Graphic.png?1555066995"
  },
  {
    id: "theta-token",
    symbol: "theta",
    name: "Theta Network",
    image:
      "https://assets.coingecko.com/coins/images/2538/large/theta-token-logo.png?1548387191"
  },
  {
    id: "dai",
    symbol: "dai",
    name: "Dai",
    image:
      "https://assets.coingecko.com/coins/images/1442/large/dai.png?1547035520"
  },
  {
    id: "siacoin",
    symbol: "sc",
    name: "Siacoin",
    image:
      "https://assets.coingecko.com/coins/images/289/large/siacoin.png?1548386465"
  },
  {
    id: "digibyte",
    symbol: "dgb",
    name: "DigiByte",
    image:
      "https://assets.coingecko.com/coins/images/63/large/digibyte.png?1547033717"
  },
  {
    id: "hshare",
    symbol: "hc",
    name: "HyperCash",
    image:
      "https://assets.coingecko.com/coins/images/1085/large/hshare-hcash-logo.png?1547035052"
  },
  {
    id: "icon",
    symbol: "icx",
    name: "ICON",
    image:
      "https://assets.coingecko.com/coins/images/1060/large/icon-icx-logo.png?1547035003"
  },
  {
    id: "iostoken",
    symbol: "iost",
    name: "IOST",
    image:
      "https://assets.coingecko.com/coins/images/2523/large/IOST.png?1557555183"
  },
  {
    id: "luna",
    symbol: "luna",
    name: "Luna",
    image:
      "https://assets.coingecko.com/coins/images/8284/large/luna1557227471663.png?1567147072"
  },
  {
    id: "waves",
    symbol: "waves",
    name: "Waves",
    image:
      "https://assets.coingecko.com/coins/images/425/large/waves.png?1548386117"
  },
  {
    id: "bitshares",
    symbol: "bts",
    name: "BitShares",
    image:
      "https://assets.coingecko.com/coins/images/95/large/bitshares.png?1547273755"
  },
  {
    id: "lambda",
    symbol: "lamb",
    name: "Lambda",
    image:
      "https://assets.coingecko.com/coins/images/4703/large/Lambda-Logo.png?1547219112"
  },
  {
    id: "monacoin",
    symbol: "mona",
    name: "MonaCoin",
    image:
      "https://assets.coingecko.com/coins/images/99/large/monacoin.png?1547033772"
  },
  {
    id: "aeternity",
    symbol: "ae",
    name: "Aeternity",
    image:
      "https://assets.coingecko.com/coins/images/1091/large/aeternity.png?1547035060"
  },
  {
    id: "bytecoin",
    symbol: "bcn",
    name: "Bytecoin",
    image:
      "https://assets.coingecko.com/coins/images/92/large/bytecoin-logo.png?1547033763"
  },
  {
    id: "swipe",
    symbol: "sxp",
    name: "Swipe",
    image:
      "https://assets.coingecko.com/coins/images/9368/large/swipe.png?1566792311"
  },
  {
    id: "komodo",
    symbol: "kmd",
    name: "Komodo",
    image:
      "https://assets.coingecko.com/coins/images/611/large/KMD_Mark_Color.png?1563350261"
  },
  {
    id: "monaco",
    symbol: "mco",
    name: "MCO",
    image:
      "https://assets.coingecko.com/coins/images/739/large/mco.png?1548387054"
  },
  {
    id: "havven",
    symbol: "snx",
    name: "Synthetix Network Token",
    image:
      "https://assets.coingecko.com/coins/images/3406/large/synthetix.png?1547038079"
  },
  {
    id: "verge",
    symbol: "xvg",
    name: "Verge",
    image:
      "https://assets.coingecko.com/coins/images/203/large/verge-symbol-color_logo.png?1561543281"
  },
  {
    id: "molecular-future",
    symbol: "mof",
    name: "Molecular Future",
    image:
      "https://assets.coingecko.com/coins/images/2607/large/molecular_future.png?1547036754"
  },
  {
    id: "chiliz",
    symbol: "chz",
    name: "Chiliz",
    image:
      "https://assets.coingecko.com/coins/images/8834/large/Chiliz.png?1561970540"
  },
  {
    id: "maidsafecoin",
    symbol: "maid",
    name: "MaidSafeCoin",
    image:
      "https://assets.coingecko.com/coins/images/80/large/maidsafecoin.png?1547033750"
  },
  {
    id: "quant-network",
    symbol: "qnt",
    name: "Quant",
    image:
      "https://assets.coingecko.com/coins/images/3370/large/QNT.jpg?1547038004"
  },
  {
    id: "enjincoin",
    symbol: "enj",
    name: "Enjin Coin",
    image:
      "https://assets.coingecko.com/coins/images/1102/large/enjin-coin-logo.png?1547035078"
  },
  {
    id: "maximine",
    symbol: "mxm",
    name: "Maximine",
    image:
      "https://assets.coingecko.com/coins/images/3803/large/mxm.png?1547038881"
  },
  {
    id: "ardor",
    symbol: "ardr",
    name: "Ardor",
    image:
      "https://assets.coingecko.com/coins/images/525/large/Ardor.png?1557969103"
  },
  {
    id: "energi",
    symbol: "nrg",
    name: "Energi",
    image:
      "https://assets.coingecko.com/coins/images/5795/large/energi.png?1547393636"
  },
  {
    id: "zilliqa",
    symbol: "zil",
    name: "Zilliqa",
    image:
      "https://assets.coingecko.com/coins/images/2687/large/Zilliqa-logo.png?1547036894"
  },
  {
    id: "bmax",
    symbol: "btmx",
    name: "Bitmax Token",
    image:
      "https://assets.coingecko.com/coins/images/5003/large/BTMX.jpg?1547040429"
  },
  {
    id: "nexo",
    symbol: "nexo",
    name: "NEXO",
    image:
      "https://assets.coingecko.com/coins/images/3695/large/nexo.png?1548086057"
  },
  {
    id: "xmax",
    symbol: "xmx",
    name: "XMax",
    image:
      "https://assets.coingecko.com/coins/images/4643/large/xmax.png?1548761152"
  },
  {
    id: "rif-token",
    symbol: "rif",
    name: "RIF Token",
    image:
      "https://assets.coingecko.com/coins/images/7460/large/RIF.png?1558011767"
  },
  {
    id: "seele",
    symbol: "seele",
    name: "Seele",
    image:
      "https://assets.coingecko.com/coins/images/3294/large/seele.png?1548608910"
  },
  {
    id: "steem",
    symbol: "steem",
    name: "Steem",
    image:
      "https://assets.coingecko.com/coins/images/398/large/steem.png?1547034126"
  },
  {
    id: "status",
    symbol: "SNT",
    name: "Status",
    image:
      "https://assets.coingecko.com/coins/images/779/large/status.png?1548610778"
  },
  {
    id: "erc20",
    symbol: "erc20",
    name: "ERC20",
    image:
      "https://assets.coingecko.com/coins/images/1141/large/erc20.png?1547035146"
  },
  {
    id: "crypterium",
    symbol: "crpt",
    name: "Crypterium",
    image:
      "https://assets.coingecko.com/coins/images/1901/large/crypt.png?1547036205"
  },
  {
    id: "iexec-rlc",
    symbol: "rlc",
    name: "iExec RLC",
    image:
      "https://assets.coingecko.com/coins/images/646/large/iExec_RLC.png?1547034373"
  },
  {
    id: "golem",
    symbol: "gnt",
    name: "Golem",
    image:
      "https://assets.coingecko.com/coins/images/542/large/golem_token.png?1548838076"
  },
  {
    id: "pundi-x",
    symbol: "npxs",
    name: "Pundi X",
    image:
      "https://assets.coingecko.com/coins/images/2170/large/pundi-x.png?1548386366"
  },
  {
    id: "republic-protocol",
    symbol: "ren",
    name: "REN",
    image:
      "https://assets.coingecko.com/coins/images/3139/large/ren.png?1548237010"
  },
  {
    id: "thunder-token",
    symbol: "tt",
    name: "ThunderCore",
    image:
      "https://assets.coingecko.com/coins/images/4375/large/Thundercore.png?1558566824"
  },
  {
    id: "zcoin",
    symbol: "xzc",
    name: "Zcoin",
    image:
      "https://assets.coingecko.com/coins/images/479/large/ci_icon_monochrome_green_small.png?1547034188"
  },
  {
    id: "breezecoin",
    symbol: "brze",
    name: "Breezecoin",
    image:
      "https://assets.coingecko.com/coins/images/6727/large/breezecoin.png?1547563369"
  },
  {
    id: "decentraland",
    symbol: "mana",
    name: "Decentraland",
    image:
      "https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png?1550108745"
  },
  {
    id: "aelf",
    symbol: "elf",
    name: "aelf",
    image:
      "https://assets.coingecko.com/coins/images/1371/large/aelf-logo.png?1547035397"
  },
  {
    id: "you-chain",
    symbol: "you",
    name: "YOU Chain",
    image:
      "https://assets.coingecko.com/coins/images/4649/large/youchain-logo.jpg?1547039915"
  },
  {
    id: "ftx-token",
    symbol: "ftt",
    name: "FTX Token",
    image:
      "https://assets.coingecko.com/coins/images/9026/large/ftt.png?1563776835"
  },
  {
    id: "cdai",
    symbol: "cdai",
    name: "Compound Dai",
    image:
      "https://assets.coingecko.com/coins/images/9281/large/cDAI.png?1565763801"
  },
  {
    id: "wink",
    symbol: "win",
    name: "WINk",
    image:
      "https://assets.coingecko.com/coins/images/9129/large/WinK.png?1564624891"
  },
  {
    id: "metaverse-etp",
    symbol: "etp",
    name: "Metaverse ETP",
    image:
      "https://assets.coingecko.com/coins/images/1086/large/metaverse.jpg?1547035053"
  },
  {
    id: "yuan-chain-coin",
    symbol: "ycc",
    name: "Yuan Chain Coin",
    image:
      "https://assets.coingecko.com/coins/images/5328/large/G5gCEo-J_400x400.jpg?1547040897"
  },
  {
    id: "zencash",
    symbol: "zen",
    name: "Horizen",
    image:
      "https://assets.coingecko.com/coins/images/691/large/horizen.png?1555052241"
  },
  {
    id: "electroneum",
    symbol: "etn",
    name: "Electroneum",
    image:
      "https://assets.coingecko.com/coins/images/1109/large/electroneum.png?1547224846"
  },
  {
    id: "aurora",
    symbol: "aoa",
    name: "Aurora",
    image:
      "https://assets.coingecko.com/coins/images/4755/large/aurora-logo.png?1547040076"
  },
  {
    id: "odem",
    symbol: "ode",
    name: "ODEM",
    image:
      "https://assets.coingecko.com/coins/images/3752/large/odem.png?1548329893"
  },
  {
    id: "digitex-futures-exchange",
    symbol: "dgtx",
    name: "Digitex Futures Exchange",
    image:
      "https://assets.coingecko.com/coins/images/2188/large/digitex-futures-logo.png?1547036481"
  },
  {
    id: "gxchain",
    symbol: "gxc",
    name: "GXChain",
    image:
      "https://assets.coingecko.com/coins/images/1089/large/26296223.png?1571192241"
  },
  {
    id: "solve-care",
    symbol: "solve",
    name: "Solve.Care",
    image:
      "https://assets.coingecko.com/coins/images/1768/large/solve-care.jpg?1547036060"
  },
  {
    id: "dxchain",
    symbol: "dx",
    name: "DxChain Token",
    image:
      "https://assets.coingecko.com/coins/images/4700/large/dxchain.jpg?1547039992"
  },
  {
    id: "stratis",
    symbol: "strat",
    name: "Stratis",
    image:
      "https://assets.coingecko.com/coins/images/531/large/stratis.png?1547034209"
  },
  {
    id: "bit-z-token",
    symbol: "bz",
    name: "Bit-Z Token",
    image:
      "https://assets.coingecko.com/coins/images/4774/large/bit-z-logo.png?1547274387"
  },
  {
    id: "telos",
    symbol: "tlos",
    name: "Telos",
    image:
      "https://assets.coingecko.com/coins/images/7588/large/Telos.png?1548741055"
  },
  {
    id: "matic-network",
    symbol: "matic",
    name: "Matic Network",
    image:
      "https://assets.coingecko.com/coins/images/4713/large/Matic.png?1553498071"
  },
  {
    id: "tomochain",
    symbol: "tomo",
    name: "TomoChain",
    image:
      "https://assets.coingecko.com/coins/images/3416/large/TOmo.png?1547038098"
  },
  {
    id: "neon-exchange",
    symbol: "nex",
    name: "Nash Exchange",
    image:
      "https://assets.coingecko.com/coins/images/3246/large/Nash-token_icon.png?1550821163"
  },
  {
    id: "loopring",
    symbol: "lrc",
    name: "Loopring",
    image:
      "https://assets.coingecko.com/coins/images/913/large/Loopring.png?1569556862"
  },
  {
    id: "zb-token",
    symbol: "zb",
    name: "ZB Token",
    image:
      "https://assets.coingecko.com/coins/images/4773/large/zb-token-logo.png?1547040106"
  },
  {
    id: "kyber-network",
    symbol: "knc",
    name: "Kyber Network",
    image:
      "https://assets.coingecko.com/coins/images/947/large/kyber-logo.png?1547034806"
  },
  {
    id: "nuls",
    symbol: "nuls",
    name: "Nuls",
    image:
      "https://assets.coingecko.com/coins/images/1053/large/Nuls.png?1556868153"
  },
  {
    id: "ark",
    symbol: "ark",
    name: "Ark",
    image:
      "https://assets.coingecko.com/coins/images/613/large/ark.png?1547034308"
  },
  {
    id: "aion",
    symbol: "aion",
    name: "Aion",
    image:
      "https://assets.coingecko.com/coins/images/1023/large/aion.png?1547034950"
  },
  {
    id: "elastos",
    symbol: "ela",
    name: "Elastos",
    image:
      "https://assets.coingecko.com/coins/images/2780/large/elastos.png?1547224834"
  },
  {
    id: "bcv",
    symbol: "bcv",
    name: "BitCapitalVendor",
    image:
      "https://assets.coingecko.com/coins/images/5326/large/hqQw2WGE_400x400.jpg?1547040893"
  },
  {
    id: "beam",
    symbol: "beam",
    name: "BEAM",
    image:
      "https://assets.coingecko.com/coins/images/7339/large/BEAM.png?1558013729"
  },
  {
    id: "tierion",
    symbol: "tnt",
    name: "Tierion",
    image:
      "https://assets.coingecko.com/coins/images/923/large/tierion.png?1547034767"
  },
  {
    id: "wixlar",
    symbol: "wix",
    name: "Wixlar",
    image:
      "https://assets.coingecko.com/coins/images/6306/large/JtT8UEU.png?1547042361"
  },
  {
    id: "civic",
    symbol: "cvc",
    name: "Civic",
    image:
      "https://assets.coingecko.com/coins/images/788/large/civic.png?1547034556"
  },
  {
    id: "ignis",
    symbol: "ignis",
    name: "Ignis",
    image:
      "https://assets.coingecko.com/coins/images/1345/large/ignis.png?1547744318"
  },
  {
    id: "nebulas",
    symbol: "nas",
    name: "Nebulas",
    image:
      "https://assets.coingecko.com/coins/images/2431/large/Webp.net-resizeimage_%289%29.png?1547036577"
  },
  {
    id: "hedera-hashgraph",
    symbol: "hbar",
    name: "Hedera Hashgraph",
    image:
      "https://assets.coingecko.com/coins/images/3688/large/mqTDGK7Q.png?1566256777"
  },
  {
    id: "reddcoin",
    symbol: "rdd",
    name: "Reddcoin",
    image:
      "https://assets.coingecko.com/coins/images/40/large/reddcoin.png?1547033681"
  },
  {
    id: "revain",
    symbol: "r",
    name: "Revain",
    image:
      "https://assets.coingecko.com/coins/images/1107/large/revain.png?1548608339"
  },
  {
    id: "jex-token",
    symbol: "jex",
    name: "Jex Token",
    image:
      "https://assets.coingecko.com/coins/images/4822/large/jex-token-logo.png?1547040192"
  },
  {
    id: "digixdao",
    symbol: "dgd",
    name: "DigixDAO",
    image:
      "https://assets.coingecko.com/coins/images/397/large/dgd.png?1547034124"
  },
  {
    id: "grin",
    symbol: "grin",
    name: "Grin",
    image:
      "https://assets.coingecko.com/coins/images/7340/large/grin.png?1548641090"
  },
  {
    id: "wax",
    symbol: "waxp",
    name: "WAX",
    image:
      "https://assets.coingecko.com/coins/images/1372/large/wax.png?1548760644"
  },
  {
    id: "usdk",
    symbol: "usdk",
    name: "USDK",
    image:
      "https://assets.coingecko.com/coins/images/8824/large/usdk.png?1563418517"
  },
  {
    id: "fantom",
    symbol: "ftm",
    name: "Fantom",
    image:
      "https://assets.coingecko.com/coins/images/4001/large/Fantom.png?1558015016"
  },
  {
    id: "aragon",
    symbol: "ant",
    name: "Aragon",
    image:
      "https://assets.coingecko.com/coins/images/681/large/aragon-logo.png?1547034433"
  },
  {
    id: "wanchain",
    symbol: "wan",
    name: "Wanchain",
    image:
      "https://assets.coingecko.com/coins/images/3482/large/wanchain-logo.png?1547038221"
  },
  {
    id: "factom",
    symbol: "fct",
    name: "Factom",
    image:
      "https://assets.coingecko.com/coins/images/310/large/factom.png?1547034074"
  },
  {
    id: "enigma",
    symbol: "eng",
    name: "Enigma",
    image:
      "https://assets.coingecko.com/coins/images/1007/large/enigma-logo.png?1547034914"
  },
  {
    id: "bread",
    symbol: "brd",
    name: "Bread",
    image:
      "https://assets.coingecko.com/coins/images/1440/large/bread.png?1547563238"
  },
  {
    id: "ripio-credit-network",
    symbol: "rcn",
    name: "Ripio Credit Network",
    image:
      "https://assets.coingecko.com/coins/images/1057/large/ripio-credit-network.png?1548608361"
  },
  {
    id: "bancor",
    symbol: "bnt",
    name: "Bancor Network Token",
    image:
      "https://assets.coingecko.com/coins/images/736/large/bancor.png?1547034477"
  },
  {
    id: "qash",
    symbol: "qash",
    name: "QASH",
    image:
      "https://assets.coingecko.com/coins/images/1220/large/qash.jpeg?1547035247"
  },
  {
    id: "waltonchain",
    symbol: "wtc",
    name: "Waltonchain",
    image:
      "https://assets.coingecko.com/coins/images/1093/large/waltonchain.png?1548760303"
  },
  {
    id: "gas",
    symbol: "gas",
    name: "Gas",
    image:
      "https://assets.coingecko.com/coins/images/858/large/gas.png?1547034659"
  },
  {
    id: "nexus",
    symbol: "nxs",
    name: "Nexus",
    image:
      "https://assets.coingecko.com/coins/images/576/large/nexus-logo.png?1547034256"
  },
  {
    id: "numeraire",
    symbol: "nmr",
    name: "Numeraire",
    image:
      "https://assets.coingecko.com/coins/images/752/large/Erasure_Mark_Final.png?1567402035"
  },
  {
    id: "edc-blockchain",
    symbol: "edc",
    name: "EDC Blockchain",
    image:
      "https://assets.coingecko.com/coins/images/524/large/EDC_Blockchain.png?1554867229"
  },
  {
    id: "storj",
    symbol: "storj",
    name: "Storj",
    image:
      "https://assets.coingecko.com/coins/images/949/large/storj.png?1547034811"
  },
  {
    id: "metal",
    symbol: "mtl",
    name: "Metal",
    image:
      "https://assets.coingecko.com/coins/images/763/large/metal.png?1548084821"
  },
  {
    id: "power-ledger",
    symbol: "powr",
    name: "Power Ledger",
    image:
      "https://assets.coingecko.com/coins/images/1104/large/power-ledger.png?1547035082"
  },
  {
    id: "ztcoin",
    symbol: "zt",
    name: "ZTCoin",
    image:
      "https://assets.coingecko.com/coins/images/6566/large/ztcoin.jpg?1547042777"
  },
  {
    id: "otocash",
    symbol: "oto",
    name: "OTOCASH",
    image:
      "https://assets.coingecko.com/coins/images/7447/large/oto.png?1548635657"
  },
  {
    id: "contentos",
    symbol: "cos",
    name: "Contentos",
    image:
      "https://assets.coingecko.com/coins/images/8379/large/4036.png?1561085375"
  },
  {
    id: "constellation-labs",
    symbol: "dag",
    name: "Constellation",
    image:
      "https://assets.coingecko.com/coins/images/4645/large/Constellation_labs.jpg?1547039911"
  },
  {
    id: "funfair",
    symbol: "fun",
    name: "FunFair",
    image:
      "https://assets.coingecko.com/coins/images/761/large/funfair.png?1548126630"
  },
  {
    id: "quark-chain",
    symbol: "qkc",
    name: "QuarkChain",
    image:
      "https://assets.coingecko.com/coins/images/3849/large/quarkchain.png?1548387524"
  },
  {
    id: "iotex",
    symbol: "iotx",
    name: "IoTeX",
    image:
      "https://assets.coingecko.com/coins/images/3334/large/iotex-logo.png?1547037941"
  },
  {
    id: "dent",
    symbol: "dent",
    name: "Dent",
    image:
      "https://assets.coingecko.com/coins/images/1152/large/dent.png?1547035167"
  },
  {
    id: "populous",
    symbol: "ppt",
    name: "Populous",
    image:
      "https://assets.coingecko.com/coins/images/753/large/populous.png?1548331905"
  },
  {
    id: "bibox-token",
    symbol: "bix",
    name: "Bibox Token",
    image:
      "https://assets.coingecko.com/coins/images/1441/large/bibox-token.png?1547035519"
  },
  {
    id: "groestlcoin",
    symbol: "grs",
    name: "Groestlcoin",
    image:
      "https://assets.coingecko.com/coins/images/71/large/image001.png?1547033733"
  },
  {
    id: "gnosis",
    symbol: "gno",
    name: "Gnosis",
    image:
      "https://assets.coingecko.com/coins/images/662/large/gnosis-logo.png?1547034401"
  },
  {
    id: "wagerr",
    symbol: "wgr",
    name: "Wagerr",
    image:
      "https://assets.coingecko.com/coins/images/759/large/wagerr.png?1548760318"
  },
  {
    id: "q-dao-governance-token-v1-0",
    symbol: "qdao",
    name: "Q DAO Governance token v1.0",
    image:
      "https://assets.coingecko.com/coins/images/8599/large/QDAO_logo_white_black.png?1562131656"
  },
  {
    id: "kava",
    symbol: "kava",
    name: "Kava",
    image:
      "https://assets.coingecko.com/coins/images/9761/large/kava-coin.png?1571486378"
  },
  {
    id: "byteball",
    symbol: "gbyte",
    name: "Obyte",
    image:
      "https://assets.coingecko.com/coins/images/561/large/byteball.png?1547564361"
  },
  {
    id: "tokenize-xchange",
    symbol: "tkx",
    name: "Tokenize Xchange",
    image:
      "https://assets.coingecko.com/coins/images/4984/large/Tokenize.png?1561602968"
  },
  {
    id: "apollo",
    symbol: "apl",
    name: "Apollo",
    image:
      "https://assets.coingecko.com/coins/images/5226/large/apollo_token.jpg?1547040701"
  },
  {
    id: "vitae",
    symbol: "vitae",
    name: "Vitae",
    image:
      "https://assets.coingecko.com/coins/images/5504/large/vitae.png?1548760073"
  },
  {
    id: "cybermiles",
    symbol: "cmt",
    name: "CyberMiles",
    image:
      "https://assets.coingecko.com/coins/images/1300/large/cybermiles.png?1547035327"
  },
  {
    id: "robotina",
    symbol: "rox",
    name: "Robotina",
    image:
      "https://assets.coingecko.com/coins/images/3153/large/rox.png?1519831400"
  },
  {
    id: "livepeer",
    symbol: "lpt",
    name: "Livepeer",
    image:
      "https://assets.coingecko.com/coins/images/7137/large/livepeer.png?1547976208"
  },
  {
    id: "kan",
    symbol: "kan",
    name: "BitKan",
    image:
      "https://assets.coingecko.com/coins/images/4651/large/kan-token.png?1547039917"
  },
  {
    id: "singularitynet",
    symbol: "agi",
    name: "SingularityNET",
    image:
      "https://assets.coingecko.com/coins/images/2138/large/singularitynet.png?1548609559"
  },
  {
    id: "crypto20",
    symbol: "c20",
    name: "CRYPTO20",
    image:
      "https://assets.coingecko.com/coins/images/2097/large/c20.png?1547036413"
  },
  {
    id: "arcblock",
    symbol: "abt",
    name: "Arcblock",
    image:
      "https://assets.coingecko.com/coins/images/2341/large/arcblock.png?1547036543"
  },
  {
    id: "harmony",
    symbol: "one",
    name: "Harmony",
    image:
      "https://assets.coingecko.com/coins/images/4344/large/Y88JAze.png?1565065793"
  },
  {
    id: "divi",
    symbol: "divi",
    name: "Divi",
    image:
      "https://assets.coingecko.com/coins/images/1273/large/divi.png?1547223784"
  },
  {
    id: "pivx",
    symbol: "pivx",
    name: "PIVX",
    image:
      "https://assets.coingecko.com/coins/images/548/large/pivx-logo.png?1547034228"
  },
  {
    id: "ethlend",
    symbol: "lend",
    name: "Aave",
    image:
      "https://assets.coingecko.com/coins/images/1365/large/ethlend.png?1547394586"
  },
  {
    id: "cocos-bcx",
    symbol: "cocos",
    name: "COCOS BCX",
    image:
      "https://assets.coingecko.com/coins/images/4932/large/_QPpjoUi_400x400.jpg?1566430520"
  },
  {
    id: "celer-network",
    symbol: "celr",
    name: "Celer Network",
    image:
      "https://assets.coingecko.com/coins/images/4379/large/Celr.png?1554705437"
  },
  {
    id: "super-zero",
    symbol: "sero",
    name: "SERO",
    image:
      "https://assets.coingecko.com/coins/images/8245/large/43697637.png?1556982099"
  },
  {
    id: "fsn",
    symbol: "fsn",
    name: "FUSION",
    image:
      "https://assets.coingecko.com/coins/images/2515/large/fusion-token-logo.jpg?1547036623"
  },
  {
    id: "credits",
    symbol: "cs",
    name: "CREDITS",
    image:
      "https://assets.coingecko.com/coins/images/1566/large/cs.png?1547035736"
  },
  {
    id: "matrix-ai-network",
    symbol: "man",
    name: "Matrix AI Network",
    image:
      "https://assets.coingecko.com/coins/images/2698/large/matrix-ai-network.png?1547978533"
  },
  {
    id: "polymath-network",
    symbol: "poly",
    name: "Polymath Network",
    image:
      "https://assets.coingecko.com/coins/images/2784/large/polymath-network.png?1548331592"
  },
  {
    id: "syscoin",
    symbol: "sys",
    name: "Syscoin",
    image:
      "https://assets.coingecko.com/coins/images/119/large/Syscoin.png?1560401261"
  },
  {
    id: "ultrain",
    symbol: "ugas",
    name: "Ultrain",
    image:
      "https://assets.coingecko.com/coins/images/4684/large/ultrain.png?1547039956"
  },
  {
    id: "egretia",
    symbol: "egt",
    name: "Egretia",
    image:
      "https://assets.coingecko.com/coins/images/3614/large/egretia.png?1547224764"
  },
  {
    id: "vertcoin",
    symbol: "vtc",
    name: "Vertcoin",
    image:
      "https://assets.coingecko.com/coins/images/18/large/vertcoin-logo-2018.png?1547033643"
  },
  {
    id: "moeda-loyalty-points",
    symbol: "mda",
    name: "Moeda Loyalty Points",
    image:
      "https://assets.coingecko.com/coins/images/895/large/moeda-loyalty-points.png?1547034720"
  },
  {
    id: "unlimitedip",
    symbol: "uip",
    name: "UnlimitedIP",
    image:
      "https://assets.coingecko.com/coins/images/2642/large/unlimitedip.png?1547036818"
  },
  {
    id: "loom-network",
    symbol: "loom",
    name: "Loom Network",
    image:
      "https://assets.coingecko.com/coins/images/3387/large/1_K76UVoLq-FOL7l-_Fag-Qw_2x.png?1547038040"
  },
  {
    id: "cindicator",
    symbol: "cnd",
    name: "Cindicator",
    image:
      "https://assets.coingecko.com/coins/images/1006/large/cindicator.png?1547034913"
  },
  {
    id: "reserve-rights-token",
    symbol: "rsr",
    name: "Reserve Rights Token",
    image:
      "https://assets.coingecko.com/coins/images/8365/large/Reserve_Rights.png?1557737411"
  },
  {
    id: "medibloc",
    symbol: "med",
    name: "Medibloc",
    image:
      "https://assets.coingecko.com/coins/images/1374/large/medibloc_basic.png?1570607623"
  },
  {
    id: "loki-network",
    symbol: "loki",
    name: "Loki Network",
    image:
      "https://assets.coingecko.com/coins/images/3462/large/d9r2wz4J_400x400.jpeg?1547038188"
  },
  {
    id: "nxt",
    symbol: "nxt",
    name: "NXT",
    image:
      "https://assets.coingecko.com/coins/images/6/large/nxt.png?1547033614"
  },
  {
    id: "stasis-eurs",
    symbol: "eurs",
    name: "STASIS EURO",
    image:
      "https://assets.coingecko.com/coins/images/5164/large/EURS_300x300.png?1550571779"
  },
  {
    id: "hyperion",
    symbol: "hyn",
    name: "Hyperion",
    image:
      "https://assets.coingecko.com/coins/images/7171/large/Hyperion.png?1558069078"
  },
  {
    id: "fetch-ai",
    symbol: "fet",
    name: "Fetch.ai",
    image:
      "https://assets.coingecko.com/coins/images/5681/large/Fetch.jpg?1572098136"
  },
  {
    id: "libra-credit",
    symbol: "lba",
    name: "Cred",
    image:
      "https://assets.coingecko.com/coins/images/3673/large/libra-credit.png?1547975828"
  },
  {
    id: "telcoin",
    symbol: "tel",
    name: "Telcoin",
    image:
      "https://assets.coingecko.com/coins/images/1899/large/tel.png?1547036203"
  },
  {
    id: "elrond",
    symbol: "erd",
    name: "Elrond",
    image:
      "https://assets.coingecko.com/coins/images/5679/large/Elrond.png?1562079028"
  },
  {
    id: "celsius-degree-token",
    symbol: "cel",
    name: "Celsius Network",
    image:
      "https://assets.coingecko.com/coins/images/3263/large/celsius.jpg?1560147978"
  },
  {
    id: "bankera",
    symbol: "bnk",
    name: "Bankera",
    image:
      "https://assets.coingecko.com/coins/images/1949/large/bnk.png?1547036236"
  },
  {
    id: "dusk-network",
    symbol: "dusk",
    name: "DUSK Network",
    image:
      "https://assets.coingecko.com/coins/images/5217/large/D_ticker_purple_on_circle_%282%29.png?1563781659"
  },
  {
    id: "ultiledger",
    symbol: "ult",
    name: "Ultiledger",
    image:
      "https://assets.coingecko.com/coins/images/6747/large/Ultiledger.jpeg?1547042996"
  },
  {
    id: "particl",
    symbol: "part",
    name: "Particl",
    image:
      "https://assets.coingecko.com/coins/images/839/large/Particl.png?1558053958"
  },
  {
    id: "wirex",
    symbol: "wxt",
    name: "Wirex",
    image:
      "https://assets.coingecko.com/coins/images/8835/large/Wirex.jpg?1561970529"
  },
  {
    id: "zrcoin",
    symbol: "zrc",
    name: "ZrCoin",
    image:
      "https://assets.coingecko.com/coins/images/699/large/zrcoin.png?1548761423"
  },
  {
    id: "iris-network",
    symbol: "iris",
    name: "IRISnet",
    image:
      "https://assets.coingecko.com/coins/images/5135/large/IRIS.png?1557999365"
  },
  {
    id: "asch",
    symbol: "xas",
    name: "Asch",
    image:
      "https://assets.coingecko.com/coins/images/636/large/asch.png?1547034353"
  },
  {
    id: "litenero",
    symbol: "ltnx",
    name: "Litenero",
    image:
      "https://assets.coingecko.com/coins/images/8592/large/logo-white2x.png?1559545522"
  },
  {
    id: "request-network",
    symbol: "req",
    name: "Request",
    image:
      "https://assets.coingecko.com/coins/images/1031/large/Request_icon.png?1550832088"
  },
  {
    id: "eidoo",
    symbol: "edo",
    name: "Eidoo",
    image:
      "https://assets.coingecko.com/coins/images/1018/large/mark-color-onBright.png?1557386214"
  },
  {
    id: "nkn",
    symbol: "nkn",
    name: "NKN",
    image:
      "https://assets.coingecko.com/coins/images/3375/large/nkn.png?1548329212"
  },
  {
    id: "tokencard",
    symbol: "tkn",
    name: "Monolith",
    image:
      "https://assets.coingecko.com/coins/images/675/large/Monolith.png?1566296607"
  },
  {
    id: "cnns",
    symbol: "cnns",
    name: "CNNS",
    image:
      "https://assets.coingecko.com/coins/images/8362/large/cnns.png?1557992686"
  },
  {
    id: "gowithmi",
    symbol: "gmat",
    name: "GoWithMi",
    image:
      "https://assets.coingecko.com/coins/images/7162/large/qXNbxVny_400x400.png?1564433322"
  },
  {
    id: "skycoin",
    symbol: "sky",
    name: "Skycoin",
    image:
      "https://assets.coingecko.com/coins/images/687/large/skycoin.png?1551071121"
  },
  {
    id: "einsteinium",
    symbol: "emc2",
    name: "Einsteinium",
    image:
      "https://assets.coingecko.com/coins/images/46/large/einsteinium.png?1547033689"
  },
  {
    id: "wabi",
    symbol: "wabi",
    name: "Tael",
    image:
      "https://assets.coingecko.com/coins/images/1338/large/Tael.png?1547035364"
  },
  {
    id: "quantum-resistant-ledger",
    symbol: "qrl",
    name: "Quantum Resistant Ledger",
    image:
      "https://assets.coingecko.com/coins/images/700/large/QRL_logo.png?1547034470"
  },
  {
    id: "volume-network-token",
    symbol: "vol",
    name: "Volume Network",
    image:
      "https://assets.coingecko.com/coins/images/8803/large/SexNcqS.png?1561521344"
  },
  {
    id: "tratok",
    symbol: "trat",
    name: "Tratok",
    image:
      "https://assets.coingecko.com/coins/images/6791/large/tratok.png?1547043075"
  },
  {
    id: "ocean-protocol",
    symbol: "ocean",
    name: "Ocean Protocol",
    image:
      "https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1547038686"
  },
  {
    id: "first-blood",
    symbol: "1st",
    name: "First Blood",
    image:
      "https://assets.coingecko.com/coins/images/522/large/bqJZ2qGQ_400x400.jpg?1569415098"
  },
  {
    id: "dentacoin",
    symbol: "dcn",
    name: "Dentacoin",
    image:
      "https://assets.coingecko.com/coins/images/850/large/dentacoin.png?1547034647"
  },
  {
    id: "daps-token",
    symbol: "daps",
    name: "DAPS Coin",
    image:
      "https://assets.coingecko.com/coins/images/6244/large/daps-token.png?1547042279"
  },
  {
    id: "tenx",
    symbol: "pay",
    name: "TenX",
    image:
      "https://assets.coingecko.com/coins/images/775/large/TenX-Icon-CircleBlack.png?1553766360"
  },
  {
    id: "dragonchain",
    symbol: "drgn",
    name: "Dragonchain",
    image:
      "https://assets.coingecko.com/coins/images/1289/large/dragonchain.png?1547957761"
  },
  {
    id: "hycon",
    symbol: "hyc",
    name: "Hycon",
    image:
      "https://assets.coingecko.com/coins/images/3289/large/hycon.png?1547744108"
  },
  {
    id: "karma-coin",
    symbol: "karma",
    name: "KARMA Coin",
    image:
      "https://assets.coingecko.com/coins/images/5516/large/karma.png?1547041278"
  },
  {
    id: "b2b",
    symbol: "b2b",
    name: "B2BX",
    image:
      "https://assets.coingecko.com/coins/images/1211/large/b2bx.png?1547035239"
  },
  {
    id: "ankr",
    symbol: "ankr",
    name: "Ankr",
    image:
      "https://assets.coingecko.com/coins/images/4324/large/ankr-coin-2.png?1563551350"
  },
  {
    id: "force-protocol",
    symbol: "for",
    name: "Force Protocol",
    image:
      "https://assets.coingecko.com/coins/images/8242/large/The-Force-Protocol.png?1556861913"
  },
  {
    id: "iot-chain",
    symbol: "itc",
    name: "IoT Chain",
    image:
      "https://assets.coingecko.com/coins/images/1304/large/iot-chain-logo.png?1547035331"
  },
  {
    id: "top-network",
    symbol: "top",
    name: "TOP Network",
    image:
      "https://assets.coingecko.com/coins/images/7058/large/topnetwork-logo.png?1547043514"
  },
  {
    id: "gifto",
    symbol: "gto",
    name: "Gifto",
    image:
      "https://assets.coingecko.com/coins/images/1359/large/gifto.png?1547742697"
  },
  {
    id: "pillar",
    symbol: "plr",
    name: "Pillar",
    image:
      "https://assets.coingecko.com/coins/images/809/large/plr.png?1547034592"
  },
  {
    id: "key",
    symbol: "key",
    name: "Key",
    image:
      "https://assets.coingecko.com/coins/images/4182/large/bihu-key-token.png?1547039459"
  },
  {
    id: "blox",
    symbol: "cdt",
    name: "Blox",
    image:
      "https://assets.coingecko.com/coins/images/1231/large/blox.png?1547351562"
  },
  {
    id: "mainframe",
    symbol: "mft",
    name: "Mainframe",
    image:
      "https://assets.coingecko.com/coins/images/3293/large/mainframe.png?1547978270"
  }
];
