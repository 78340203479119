import React, { useEffect } from "react";
import Layout from "../components/UI/Layout";
import UIHero from "../components/UI/Hero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { analytics } from "../components/Firebase/context";

const ScreensDonate = () => {
  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Donate" });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Donate | Trade-r</title>
      </Helmet>
      <UIHero title="Donate" subtitle="And help support Trade-r" />
      <section className="section">
        <div className="container">
          <div className="columns content is-centered">
            <div className="column is-two-thirds">
              <div className="box content">
                <h2 className="title">Thank you for your support!</h2>
                <p>
                  Just by clicking this link means you're an awesome person!
                  This app is free to use, and donations help keep it alive and
                  growing to meet user needs. If you dig the app, and it's
                  helping either make you money, a better trader, or both,
                  please consider donating a modest amount.
                </p>
              </div>
              <div className="box">
                <h3 className="title">
                  <FontAwesomeIcon
                    icon={["fab", "bitcoin"]}
                    style={{ marginRight: "0.75rem" }}
                  />
                  BTC Donation Address
                </h3>
                <p className="subtitle is-family-code">
                  345Ws98jBVxzzE1R9uD4u91zhNroczksfr
                </p>
              </div>
              <div className="box">
                <h3 className="title">Brave Rewards</h3>
                <p>
                  Brave browser tips are greatly appreciated. Get used to the
                  web browser system of the future, which respects your privacy
                  and personal information.
                </p>
                <a
                  className="button is-link"
                  href="https://support.brave.com/hc/en-us/articles/360021123971-How-do-I-tip-websites-and-Content-Creators-in-Brave-Rewards-"
                >
                  Learn how to tip in Brave
                </a>
              </div>
            </div>

            <div className="column one-third">
              <h3 className="subtitle">Suggested donation</h3>
              <ul>
                <li>
                  How about every time you close a trade, and you think{" "}
                  <strong>Trade-r</strong> helped either make you money or saved
                  you money, consider donating <strong>1USD</strong> worth of
                  Bitcoin?
                </li>
                <li>
                  Or if you use the super awesome{" "}
                  <a
                    href="https://brave.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Brave browser
                  </a>
                  , consider suppporting this website at a modest monthly
                  donation of{" "}
                  <a
                    href="https://basicattentiontoken.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Basic Attention Token
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ScreensDonate;
