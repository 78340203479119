import React, { useEffect, useState, useContext } from "react";
//import app from "../base";
import { FirebaseContext } from "../components/Firebase/context";
import { getUser } from "./User/api";
import { getUserTrades } from "../components/Trade/api";
import { UserContext } from "./User/UserContext";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState("LOADING");
  const { updateTrades, updateUserPreferences } = useContext(UserContext);
  const { app } = useContext(FirebaseContext);

  useEffect(() => {
    // Set context for Firebase authenticated user change
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);

  useEffect(() => {
    if (currentUser === "LOADING" || !currentUser || !currentUser.uid) return;

    const fn = async () => {
      let { dateAdded, preferredCurrency } = await getUser(currentUser.uid);

      // Update UserContext user prefs
      updateUserPreferences({ dateAdded, preferredCurrency });

      // Get closed trades and send to UserContext
      const trades = await getUserTrades(currentUser.uid);
      updateTrades(trades);
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
