import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatPreferredCurrency,
  formatPrice,
  formatPair,
  percentDiff,
  shouldSellProfit,
  shouldSellLoss,
  formatDate,
} from "../../services/services";
import { supportedCoins } from "../../services/supported-coins";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../User/UserContext";
import { isMobile } from "react-device-detect";

const TradeListItemCard = ({ trade, currentPrice, handleSellClick }) => {
  const location = useLocation();
  const isListPage = location.pathname === "/trade/list";
  const { active, buy, coinBase, coinTarget, sell, tradeType } = trade;
  const coinImg = supportedCoins.find((coin) => coin.id === coinBase).image;
  const sellProfit = shouldSellProfit(trade, currentPrice);
  const sellLoss = shouldSellLoss(trade, currentPrice);
  let todayTimestamp = Math.floor(Date.now() / 1000);
  const { userPreferences } = useContext(UserContext);
  const percentChangeCurrentPrice = percentDiff(trade, currentPrice);
  const percentChangeSold = percentDiff(trade) || null;

  const getCardImageBgClass = () => {
    let styleClass = "";

    if (!active) return "has-background-dark";

    if (sellProfit) {
      styleClass = "has-background-success";
    }
    if (sellLoss) {
      styleClass = "has-background-danger";
    }
    return styleClass;
  };

  return (
    <div className="card has-ribbon">
      {(sellProfit || sellLoss) && active && (
        <div className={`ribbon is-warning`}>SELL NOW</div>
      )}
      <Link
        to={`/trade/${trade.id}`}
        className={`card-image ${getCardImageBgClass()}`}
      >
        <figure
          className={`image ${isMobile ? "is-64x64" : "is-128x128"} container`}
        >
          <img src={coinImg} alt="logo of trade coin" />
        </figure>
      </Link>

      <div className="card-content">
        <div className="content">
          <p className="is-size-7 has-text-color-grey is-italic">
            Bought {formatDate(buy.date)}
          </p>

          <div className="level no-bottom-padding is-mobile">
            <div className="level-left is-size-3 is-size-4-mobile">
              <span className="level-item">{trade.coinBase.toUpperCase()}</span>
              <span className="level-item is-size-4 is-size-5-mobile">
                {" "}
                | {formatPair(trade)}
              </span>
            </div>
          </div>

          {/* SOLD trade data */}
          {!trade.active && (
            <div>
              <div
                className={`is-size-1 is-size-2-mobile ${
                  percentChangeSold > 0 ? "has-text-success" : "has-text-danger"
                }`}
              >
                {percentChangeSold}%
              </div>

              <div className="notification">
                <p>
                  Trade closed <strong>{formatDate(sell.date)}</strong> for a
                  profit/loss of {formatPrice(trade.profit)}
                  {coinTarget}{" "}
                  <span className="is-italic">
                    (
                    {formatPreferredCurrency(
                      trade.profitPreferredCurrency,
                      userPreferences.preferredCurrency
                    )}
                    )
                  </span>
                </p>
              </div>
            </div>
          )}

          {/* ACTIVE trade data */}
          {active && (
            <>
              <div className="level is-mobile title no-bottom-padding">
                <div className="level-left">
                  <div className="level-item">
                    <span
                      className="is-size-3 is-size-4-mobile is-size-3-desktop is-size-2-widescreen "
                      title="Current price"
                    >
                      {currentPrice}
                    </span>
                  </div>
                  <div className="level-item is-size-5 is-size-6-mobile is-size-5-desktop">
                    <span className="" title="Price change since purchase">
                      {formatPrice(currentPrice - buy.price)} {coinTarget}
                    </span>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    {tradeType !== "LONG" && (
                      <p>
                        <span className="tag is-info">SHORT</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`is-size-1 is-size-2-mobile ${
                  percentChangeCurrentPrice > 0
                    ? "has-text-success"
                    : "has-text-danger"
                }`}
              >
                {percentChangeCurrentPrice}%
              </div>
            </>
          )}

          {!isListPage && (
            <p className="is-size-7 has-text-right">
              {formatDate(todayTimestamp)}
            </p>
          )}
        </div>
      </div>

      <footer className="card-footer">
        {isListPage && (
          <Link to={`/trade/${trade.id}`} className="card-footer-item ">
            View Trade
          </Link>
        )}
        {active && (sellProfit || sellLoss) && (
          // eslint-disable-next-line
          <Link to={`/trade/${trade.id}`} className="card-footer-item ">
            <span className="icon">
              <FontAwesomeIcon icon="exclamation-triangle" />
            </span>{" "}
            <span>
              <strong>Sell</strong>
            </span>
          </Link>
        )}
      </footer>
    </div>
  );
};

TradeListItemCard.propTypes = {
  trade: PropTypes.object,
  currentPrice: PropTypes.number,
  handleSellClick: PropTypes.func,
};

export default TradeListItemCard;
