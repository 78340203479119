import { app } from "../Firebase/context";
import firebase from "firebase/app";

const userCollectionRef = app.firestore().collection("users");

/**
 * Add user to our system
 * @param {object} authResult Firebase auth result object returned
 * https://firebase.google.com/docs/auth/web/password-auth#create_a_password-based_account
 * @returns
 */
export async function addUser(authResult) {
  const { user, additionalUserInfo } = authResult;
  const data = {
    authProvider: {
      displayName: user.displayName,
      email: user.email,
      providerId: additionalUserInfo.providerId,
    },
    dateAdded: firebase.firestore.Timestamp.fromDate(new Date()),
    preferredCurrency: "usd",
  };

  try {
    const transaction = await userCollectionRef.doc(user.uid).set(data);
    return transaction;
  } catch (error) {
    console.log("Error adding user", error);
    Promise.resolve(null);
  }
}

export async function getUser(userId) {
  const docRef = userCollectionRef.doc(userId);
  const snapshot = await docRef.get();

  if (snapshot.exists) {
    return { id: userId, ...snapshot.data() };
  }
  return Promise.resolve(null);
}

export async function updateUser(userId, data) {
  try {
    const docRef = await userCollectionRef.doc(userId);
    const response = await docRef.update(data);
    return response;
  } catch (error) {
    console.log("Error updating user", error);
    return Promise.resolve({
      error,
      message: `There was an error updating the user: ${error}`,
    });
  }
}
