import React, { useEffect, useState, useContext } from "react";
import Layout from "../../components/UI/Layout";
import DashboardSnapShotsAlt from "../../components/Dashboard/SnapShotsAlt";
import DashboardCoinsTraded from "../../components/Dashboard/CoinsTraded";
import DashboardWinLoss from "../../components/Dashboard/WinLoss";
import { AuthContext } from "../../components/Auth";
import { UserContext } from "../../components/User/UserContext";
import { getCoinBaseProfitMap } from "../../services/services";
import ChartAllSoldTrades from "../../components/Chart/AllSoldTrades";
import ChartExitPercentages from "../../components/Chart/ExitPercentages";
import ChartProfitByCoin from "../../components/Chart/ProfitByCoin";
import { preferredCurrencies } from "../../services/preferred-currencies";
import DashboardSalesTable from "../../components/Dashboard/SalesTable";
import ChartProfitTimeline from "../../components/Chart/ProfitTimeline";
import { Link } from "react-router-dom";
import UIPageLoader from "../../components/UI/PageLoader";
import LogoInPage from "../../components/UI/LogoInPage";
import { Helmet } from "react-helmet";
import { analytics } from "../../components/Firebase/context";

const ScreensDashboard = () => {
  const [doTradesExist, setDoTradesExist] = useState();
  const [soldTrades, setSoldTrades] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { trades, userPreferences } = useContext(UserContext);

  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Dashboard" });
  }, []);

  useEffect(() => {
    async function fn() {
      if (!trades || trades.length === 0) return;

      setDoTradesExist(true);

      const closedTrades = trades.filter((trade) => !trade.active);
      setSoldTrades(closedTrades);
    }
    fn();
  }, [trades]);

  // Context vars not available in yet
  if (!currentUser.uid || !trades) return <UIPageLoader />;

  const profitMap = getCoinBaseProfitMap(soldTrades);
  const unit = userPreferences
    ? preferredCurrencies.find(
        (c) => c.value === userPreferences.preferredCurrency
      ).unit
    : "";

  return (
    <Layout>
      <Helmet>
        <title>Dashboard | Trade-r</title>
      </Helmet>
      <section className="hero has-img-background is-primary">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-inline-block">Dashboard</h1>
            <h2 className="subtitle">Monitor Your Performance</h2>
          </div>
        </div>
      </section>

      {soldTrades.length === 0 && (
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <div className="box content has-text-centered">
                  <p className="notification is-warning has-text-center">
                    {doTradesExist ? "...No sold trades" : "... No trades"}
                  </p>
                  {!doTradesExist ? (
                    <>
                      <p>
                        Hmmm, looks like its time to start adding some trades,
                        eh?
                      </p>
                      <p>
                        <Link
                          to="/trade/form"
                          className="button is-medium is-primary"
                        >
                          Add Your First Trade
                        </Link>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="has-text-left">
                        Looks like you don't have any tracked sold trades yet.
                        No worries, but <LogoInPage isDark /> has nothing
                        interesting to report for you yet.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {soldTrades.length > 0 && (
        <>
          <section className="section">
            <DashboardSnapShotsAlt
              trades={trades}
              userPreferences={userPreferences}
              coinBaseProfitMap={profitMap}
              unit={unit}
            />
          </section>

          <section className="section">
            <div className="container">
              <div className="columns">
                <div className="column is-half">
                  <DashboardWinLoss trades={trades} />
                  <div className="box is-hidden-touch">
                    <h2 className="subtitle">Trade Exit Percentages</h2>
                    <ChartExitPercentages trades={soldTrades} />
                  </div>
                </div>
                <div className="column is-half">
                  <div className="box">
                    <h2 className="subtitle">Gains Over Time</h2>
                    <ChartProfitTimeline
                      trades={soldTrades}
                      userPreferences={userPreferences}
                      unit={unit}
                    />
                  </div>
                  <DashboardCoinsTraded trades={trades} />
                </div>
              </div>
              <div className="box">
                <h2 className="subtitle">Profit Grouped By Coin</h2>
                <ChartProfitByCoin
                  userPreferences={userPreferences}
                  trades={soldTrades}
                />
              </div>
            </div>
          </section>

          <section className="hero">
            <div className="hero-body">
              <div className="container">
                <h2 className="title">Sold Trades</h2>
                <h3 className="subtitle">Performance</h3>
              </div>
            </div>
          </section>

          <div className="container">
            <div className="box">
              <h2 className="subtitle">Individual Gains/Losses</h2>
              <ChartAllSoldTrades
                trades={trades}
                userPreferences={userPreferences}
              />
            </div>
            <div className="box">
              <DashboardSalesTable
                trades={soldTrades}
                userPreferences={userPreferences}
                unit={unit}
              />
            </div>
            <p>&nbsp;</p>
          </div>
        </>
      )}
    </Layout>
  );
};

export default ScreensDashboard;
