import React from "react";
import { formatPair, percentDiff, themeColors } from "../../services/services";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const ChartTargets = ({ trade }) => {
  const colors = [
    themeColors.isLink,
    themeColors.isSuccess,
    themeColors.isDanger,
  ];

  const { buy, target } = trade;
  const pair = formatPair(trade);

  const getPercent = (val) => {
    let percent = "";

    switch (val) {
      case target.profit:
        percent = percentDiff(trade, target.profit);
        break;
      case target.stopLoss:
        percent = percentDiff(trade, target.stopLoss);
        break;
      default:
        percent = "";
    }
    return percent ? `${percent}%` : "";
  };

  const options = {
    chart: {
      events: {
        click: function (chart, w, e) {},
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${getPercent(val)}`;
      },
      offsetY: 20,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "75%",
        dataLabels: {
          position: "top",
        },
        distributed: true,
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: (seriesName) => pair,
        },
      },
    },
    xaxis: {
      categories: ["Buy", "Target Profit", "Target Stop Loss"],
      labels: {
        style: {
          colors: colors,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      decimalsInFloat: buy.price < 1 ? 4 : buy.price > 30 ? 0 : 1,
      title: {
        text: pair,
      },
    },
  };

  const series = [
    {
      data: [buy.price, target.profit, target.stopLoss],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height={300}
    />
  );
};

ChartTargets.propTypes = {
  trade: PropTypes.object,
};

export default ChartTargets;
