import React, { useEffect, useContext, useState } from "react";
import Layout from "../components/UI/Layout";
import { AuthContext } from "../components/Auth";
import { getUser } from "../components/User/api";
import SettingsForm from "../components/Settings/Form";
import LogoInPage from "../components/UI/LogoInPage";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { analytics } from "../components/Firebase/context";

const ScreensSettings = () => {
  const { currentUser } = useContext(AuthContext);
  const [user, setUser] = useState();

  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Settings" });
  }, []);

  useEffect(() => {
    const fn = async () => {
      const response = await getUser(currentUser.uid);
      setUser(response);
    };
    fn();
  }, [currentUser.uid]);

  return (
    <Layout>
      <Helmet>
        <title>Settings | Trade-r</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <div className="box">
                <div className="content">
                  <h1 className="title is-2">User Settings</h1>
                  <SettingsForm user={user} userId={currentUser.uid} />
                </div>
              </div>
            </div>
            <div className="column is-half content">
              <p>
                <strong>What's a Preferred Currency?</strong>
              </p>
              <p>
                See <Link to="/faqs">FAQs</Link> for more info. Basically it
                allows <LogoInPage isDark /> to calculate comparisons across
                trades, to give you an idea of which trades are more successful.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ScreensSettings;
