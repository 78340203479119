import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/UI/Layout";
import { AuthContext } from "../../components/Auth";
import TradeForm from "../../components/Trade/Form";
import { useParams } from "react-router-dom";
import { getTrade } from "../../components/Trade/api";
import PageLoader from "../../components/UI/PageLoader";
import { formatDate } from "../../services/services";
import { Helmet } from "react-helmet";
import { analytics } from "../../components/Firebase/context";

const ScreensTradeForm = () => {
  const { currentUser } = useContext(AuthContext);
  const { id } = useParams();
  const [trade, setTrade] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Add Trade" });
  }, []);

  useEffect(() => {
    // New trade form
    if (!currentUser.uid || !id) {
      setLoading(false);
      setTrade(null);
      return;
    }

    const fn = async () => {
      const response = await getTrade(currentUser.uid, id);
      setTrade(response);
      setLoading(false);
    };
    fn();
  }, [currentUser.uid, id]);

  return loading ? (
    <PageLoader />
  ) : (
    <Layout>
      <Helmet>
        <title>Add Trade | Trade-r</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <div className="box">
                <div className="content">
                  <h1 className="title">{id ? "Edit" : "Add"} Trade</h1>
                  {trade && (
                    <p className="has-text-grey">
                      Last updated: {formatDate(trade.updatedAt)}
                    </p>
                  )}
                </div>
                <TradeForm userId={currentUser.uid} trade={trade} />
              </div>
            </div>
            <div className="column is-half content">
              <p>
                <strong>How to {id ? "edit" : "add"} a trade:</strong>
              </p>

              <ol>
                <li>
                  Choose your trade's coin and exchange from the list on the
                  left
                </li>
                <li>
                  <strong>Base Currency</strong> will update based on Coin /
                  Exchange combination
                </li>
                <li>
                  If a Coin / Base Currency combination is not supported by the
                  selected Exchange, you'll see a warning message.
                </li>
                <li>
                  As you enter a Buy price, you'll see some helper Exit prices
                  based on common trade percentages.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ScreensTradeForm;
