import React from "react";
import { formatPrice } from "../../services/services";

const UINotificationPriceTargets = ({ price, tradeType = "LONG" }) => {
  if (!price) return null;

  return (
    <div className="notification is-link">
      <table className="table is-bordered is-fullwidth">
        <caption className="is-size-7 has-text-white is-italic">
          Common profit %s
        </caption>
        <thead>
          <tr>
            <th> 10% </th>
            <th> 20% </th>
            <th> 30% </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {formatPrice(tradeType === "LONG" ? price * 1.1 : price * 0.9)}
            </td>
            <td>
              {formatPrice(tradeType === "LONG" ? price * 1.2 : price * 0.8)}
            </td>
            <td>
              {formatPrice(tradeType === "LONG" ? price * 1.3 : price * 0.7)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UINotificationPriceTargets;
