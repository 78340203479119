import React from "react";
import { formatPrice, percentDiff } from "../../services/services";
import ChartTargetProgress from "../Chart/TargetProgress";
import PropTypes from "prop-types";

const TradeStatusCard = ({ trade, currentPrice }) => {
  const { coinTarget, target } = trade;
  const percent = percentDiff(trade, currentPrice);
  const targetProfitPercent = percentDiff(trade, target.profit);
  const targetStopLossPercent = percentDiff(trade, target.stopLoss);

  let progressPercent =
    percent > 0
      ? Number(((percent / targetProfitPercent) * 100).toFixed(2))
      : Number(((percent / targetStopLossPercent) * 100).toFixed(2));

  let chartLabel = "Progress towards target";
  if (percent > 0 && progressPercent > 100) {
    chartLabel = "Profit target reached";
  } else if (percent < 0 && progressPercent > 100) {
    chartLabel = "Stop Loss reached";
  } else if (percent < 0) {
    chartLabel = "Nearing stop loss";
  }

  return (
    <div className="box">
      <h3 className="subtitle">Status</h3>
      <div className="level is-mobile has-text-centered no-bottom-padding">
        <div className="level-item">
          <div>
            <div className="heading">Current Price ({coinTarget})</div>
            <div className="title is-4">{formatPrice(currentPrice)}</div>
          </div>
        </div>
        <div className="level-item">
          <div>
            <div className="heading">
              Target {percent >= 0 ? "Profit" : "Stop Loss"} ({coinTarget})
            </div>
            <div className="title is-4">
              {formatPrice(percent >= 0 ? target.profit : target.stopLoss)}
            </div>
          </div>
        </div>
      </div>
      <ChartTargetProgress
        label={chartLabel}
        percent={percent}
        progressPercent={progressPercent}
      />
      <p className="notification is-size-7-mobile">
        <span className="tag is-primary">Pro Tip:</span> When the progress meter
        is 100% (or close), sell. Don't think twice, its alright.
      </p>
      {progressPercent >= 100 && (
        <button className="button is-fullwidth is-primary">Sell</button>
      )}
    </div>
  );
};

TradeStatusCard.propTypes = {
  trade: PropTypes.object,
  currentPrice: PropTypes.number,
};

export default TradeStatusCard;
