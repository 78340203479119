import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UIModalDelete = ({ handleConfirmClick, handleCancelClick, isActive }) => {
  return (
    <div className={`modal ${isActive ? "is-active" : ""}`} id="modalDelete">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Confirm Delete?</p>
          <button className="delete" onClick={handleCancelClick}></button>
        </header>
        <section className="modal-card-body">
          <FontAwesomeIcon
            icon="exclamation-triangle"
            size="lg"
            style={{ paddingRight: "0.5rem" }}
          />
          This trade will be deleted permanently.
        </section>
        <footer className="modal-card-foot">
          <button className="button is-danger" onClick={handleConfirmClick}>
            Yes, confirm
          </button>
          <button className="button" onClick={handleCancelClick}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

UIModalDelete.propTypes = {
  handleConfirmClick: PropTypes.func
};

export default UIModalDelete;
