import React, { useState, useContext, useEffect } from "react";
import UIProgress from "../../components/UI/Progress";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { AuthContext } from "../../components/Auth";
import { Redirect } from "react-router";
import { addUser } from "../../components/User/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "../../components/UI/Navbar";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import UserFirebaseUIWrapper from "./FirebaseUIWrapper";
import { Helmet } from "react-helmet";
import { analytics } from "../../components/Firebase/context";

export default function ScreensUserSignIn() {
  const [signInErrors, setSignInErrors] = useState();
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Sign In" });
  }, []);

  // Sign in submit
  const onSignIn = async (data) => {
    const authResult = await firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .catch(function (error) {
        console.log("onSubmit -> error", error);
        setSignInErrors(error);
      });
  };

  const { currentUser } = useContext(AuthContext);

  if (currentUser === "LOADING") {
    return <UIProgress active={true} />;
  }
  if (currentUser) {
    return <Redirect to="/trade/list" />;
  }

  return (
    <div>
      <Helmet>
        <title>Sign In | Trade-r</title>
      </Helmet>
      <Navbar />
      <section className="hero is-light is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              <h1 className="title">
                <FontAwesomeIcon icon="user-shield" /> Sign In
              </h1>

              {signInErrors && (
                <p className="notification is-danger">{signInErrors.message}</p>
              )}

              <div className="box">
                {/* Email Password OAuth */}
                <form
                  onSubmit={handleSubmit(onSignIn)}
                  className="has-text-left"
                >
                  <p className="has-text-right">
                    <Link to="/user/start">New here? Sign Up</Link>
                  </p>
                  <div className="field">
                    <div
                      className={`control has-icons-left ${
                        errors.email ? "has-icons-right" : ""
                      }`}
                    >
                      <input
                        className={`input ${errors.email ? "is-danger" : ""}`}
                        type="email"
                        name="email"
                        ref={register({ required: true })}
                        placeholder="Your Email"
                        autoFocus=""
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon="envelope" />
                      </span>
                      {errors.email && (
                        <span className="icon is-small is-right">
                          <FontAwesomeIcon icon="exclamation-triangle" />
                        </span>
                      )}
                      {errors.email && (
                        <p className="help is-danger">Email is required</p>
                      )}
                    </div>
                  </div>

                  <p className="has-text-right ">Forgot your password?</p>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="password"
                        name="password"
                        ref={register({ required: true, minLength: 6 })}
                        placeholder="Your Password"
                      />
                      {errors.password && (
                        <p className="help is-danger">
                          {errors.password.type === "required"
                            ? "Password is required"
                            : "Password must be at least 6 characters"}
                        </p>
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="button is-block is-primary is-fullwidth"
                  >
                    Sign In
                  </button>
                </form>
                <div className="is-divider" data-content="OR"></div>

                <UserFirebaseUIWrapper />
              </div>

              <p className="notification is-white has-text-left is-size-7">
                <span className="tag is-primary">Not working?</span> If you're
                using{" "}
                <a
                  href="https://brave.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Brave
                </a>{" "}
                browser, you may need to turn <strong>Shields Off</strong> on
                this site to allow the login pop-up to function properly.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
