import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { calculateWins, themeColors } from "../../services/services";

const DashboardWinLoss = ({ trades = [] }) => {
  if (trades.length === 0) {
    return null;
  }
  const soldTrades = trades.filter((trade) => !trade.active);
  const soldTradesLength = soldTrades.length;

  // const totalWins = soldTrades.reduce((counter, trade) => {
  //   return Math.sign(trade.profit) > 0 ? counter + 1 : counter;
  // }, 0);
  // const totalLosses = soldTrades.reduce((counter, trade) => {
  //   return Math.sign(trade.profit) < 0 ? counter + 1 : counter;
  // }, 0);
  const winsObject = calculateWins(soldTrades);
  const lossesPercent = parseInt(
    ((soldTradesLength - winsObject.total) / soldTradesLength) * 100
  );
  const series = [winsObject.percent, lossesPercent];
  const options = {
    colors: [themeColors.isSuccess, themeColors.isDanger],
    labels: ["Trades sold for profit", "Trades sold for loss"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Sold Trades",
            formatter: (w) => {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return soldTradesLength;
            },
          },
        },
      },
    },
  };

  return (
    <article className="box">
      <h2 className="subtitle">Profitable Exits</h2>
      <Chart options={options} series={series} type="radialBar" width="100%" />
    </article>
  );
};

DashboardWinLoss.propTypes = {
  trades: PropTypes.array,
};

export default DashboardWinLoss;
