import React, { useEffect, useContext } from "react";
import Navbar from "../../components/UI/Navbar";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/Auth";
import Footer from "../../components/UI/Footer";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/trade-r-logo-gradient-green.png";
import HomeAddTrade from "../../components/Home/AddTrade";
import HomeMonitorProgress from "../../components/Home/MonitorProgress";
import HomeMeasurePerformance from "../../components/Home/MeasurePerformance";
import HomeSell from "../../components/Home/Sell";
import { isMobile } from "react-device-detect";
import { analytics } from "../../components/Firebase/context";

const ScreensHome = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Home" });
  }, []);

  return (
    <>
      <section className="hero is-primary is-medium has-img-background-full">
        <div className="hero-head">
          <Navbar />
        </div>

        <div className="hero-body is-relative">
          <div className="container has-text-centered">
            <div>
              <figure className="image is-inline-block home-logo">
                <img src={logo} alt="Trade-r logo" />
              </figure>
            </div>
            <h1 className="title is-inline-block">Crypto Crusher</h1>
            <h2 className="subtitle is-size-6">
              Trade Smarter. Visualize Targets. Maximize Gains.
            </h2>
            <div className="buttons is-centered">
              <HashLink
                className="button is-mobile-fullwidth is-primary is-inverted is-outlined"
                to="/#how-does-it-work"
              >
                Learn More
              </HashLink>
              {!currentUser && (
                <Link
                  className="button is-mobile-fullwidth is-primary is-inverted is-outlined"
                  to="/user/sign-in"
                >
                  Login
                </Link>
              )}
              {currentUser && (
                <Link
                  className="button is-mobile-fullwidth is-primary is-inverted is-outlined"
                  to="/trade/list"
                >
                  Go To Trades
                </Link>
              )}
            </div>
          </div>
          <p className="photo-credit is-size-7">
            Photo by{" "}
            <a
              href="https://unsplash.com/photos/9AdeEdYB2yk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ricardo Gomez Angel on Unsplash
            </a>
          </p>
        </div>
      </section>

      {/* <section className="hero" id="how-does-it-work">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h2 className="title is-size-1 has-text-warning is-size-4-mobile">
              Why?
            </h2>
          </div>
        </div>
      </section> */}

      <section className="hero" id="how-does-it-work">
        <div
          className="hero-body"
          style={isMobile ? { paddingBottom: "1rem" } : {}}
        >
          <div className="container has-text-centered">
            <h2 className="title is-size-1 has-text-warning is-size-4-mobile">
              How Does It Work?
            </h2>
          </div>
        </div>
      </section>

      <HomeAddTrade currentUser={currentUser} />
      <HomeMonitorProgress currentUser={currentUser} />
      <HomeSell currentUser={currentUser} />

      <section className="hero is-light">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h2 className="title is-size-1 is-size-4-mobile">
              SIT BACK, RELAX AND...
            </h2>
          </div>
        </div>
      </section>

      <HomeMeasurePerformance />
      <Footer />
    </>
  );
};

export default ScreensHome;
