import React from "react";

const UIProgress = ({ active }) => {
  return active ? (
    <progress className="progress is-small is-primary" max="100">
      15%
    </progress>
  ) : null;
};

export default UIProgress;
