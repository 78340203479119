export function scrubExchange(exchange) {
  const { id, name } = exchange;
  return {
    id,
    name
  };
}

// https://api.coingecko.com/api/v3/exchanges?per_page=25
export const supportedExchanges = [
  {
    id: "bitfinex",
    name: "Bitfinex",
    year_established: 2014,
    country: "British Virgin Islands",
    description: "",
    url: "https://www.bitfinex.com",
    image:
      "https://assets.coingecko.com/markets/images/4/small/bitfinex.jpg?1519355988",
    has_trading_incentive: false,
    trust_score: 10,
    trust_score_rank: 1,
    trade_volume_24h_btc: 6028.598391880662,
    trade_volume_24h_btc_normalized: 6028.598391880662
  },
  {
    id: "kraken",
    name: "Kraken",
    year_established: 2011,
    country: "United States",
    description: null,
    url: "https://www.kraken.com/en-us",
    image:
      "https://assets.coingecko.com/markets/images/29/small/kraken.jpg?1519460351",
    has_trading_incentive: false,
    trust_score: 10,
    trust_score_rank: 2,
    trade_volume_24h_btc: 5313.5919082643395,
    trade_volume_24h_btc_normalized: 5313.5919082643395
  },
  {
    id: "kucoin",
    name: "KuCoin",
    year_established: 2014,
    country: "Seychelles",
    description: "",
    url: "https://www.kucoin.com/",
    image:
      "https://assets.coingecko.com/markets/images/61/small/kucoin.jpg?1519714368",
    has_trading_incentive: false,
    trust_score: 10,
    trust_score_rank: 3,
    trade_volume_24h_btc: 3131.550431309869,
    trade_volume_24h_btc_normalized: 3131.550431309869
  },
  {
    id: "poloniex",
    name: "Poloniex",
    year_established: 2014,
    country: "United States",
    description: null,
    url: "https://poloniex.com/",
    image:
      "https://assets.coingecko.com/markets/images/37/small/poloniex.jpg?1519639007",
    has_trading_incentive: false,
    trust_score: 10,
    trust_score_rank: 4,
    trade_volume_24h_btc: 1554.7394419540055,
    trade_volume_24h_btc_normalized: 1554.7394419540055
  },
  {
    id: "bittrex",
    name: "Bittrex",
    year_established: 2014,
    country: "United States",
    description: null,
    url: "https://bittrex.com/",
    image:
      "https://assets.coingecko.com/markets/images/10/small/bittrex.png?1519545190",
    has_trading_incentive: false,
    trust_score: 10,
    trust_score_rank: 5,
    trade_volume_24h_btc: 877.8531397313691,
    trade_volume_24h_btc_normalized: 877.8531397313691
  },
  {
    id: "binance",
    name: "Binance",
    year_established: 2017,
    country: "Malta",
    description: "",
    url: "https://www.binance.com/",
    image:
      "https://assets.coingecko.com/markets/images/52/small/binance.jpg?1519353250",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 6,
    trade_volume_24h_btc: 76510.88730749411,
    trade_volume_24h_btc_normalized: 51072.254057116865
  },
  {
    id: "gdax",
    name: "Coinbase Pro",
    year_established: 2012,
    country: "United States",
    description: null,
    url: "https://pro.coinbase.com/",
    image:
      "https://assets.coingecko.com/markets/images/23/small/fe290a14-ac8f-4c90-9aed-5e72abf271f0.jpeg?1527171545",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 7,
    trade_volume_24h_btc: 8102.472395372324,
    trade_volume_24h_btc_normalized: 8102.472395372324
  },
  {
    id: "huobi",
    name: "Huobi Global",
    year_established: 2013,
    country: "China",
    description: null,
    url: "https://www.hbg.com",
    image:
      "https://assets.coingecko.com/markets/images/25/small/Huobi.png?1529314744",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 8,
    trade_volume_24h_btc: 65399.68792658496,
    trade_volume_24h_btc_normalized: 7901.746555442905
  },
  {
    id: "bitstamp",
    name: "Bitstamp",
    year_established: 2013,
    country: "United Kingdom",
    description: null,
    url: "https://www.bitstamp.net/",
    image:
      "https://assets.coingecko.com/markets/images/9/small/bitstamp.jpg?1519627979",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 9,
    trade_volume_24h_btc: 3816.962682131437,
    trade_volume_24h_btc_normalized: 3816.962682131437
  },
  {
    id: "gate",
    name: "Gate.io",
    year_established: null,
    country: "Hong Kong",
    description: "",
    url: "https://gate.io/",
    image:
      "https://assets.coingecko.com/markets/images/60/small/gateio.jpg?1519793974",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 10,
    trade_volume_24h_btc: 3894.578886903392,
    trade_volume_24h_btc_normalized: 3016.5483390460845
  },
  {
    id: "bitflyer",
    name: "bitFlyer",
    year_established: 2014,
    country: "Japan",
    description: "",
    url: "https://bitflyer.com",
    image:
      "https://assets.coingecko.com/markets/images/5/small/bitflyer.jpg?1519611556",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 11,
    trade_volume_24h_btc: 2032.9550903610639,
    trade_volume_24h_btc_normalized: 2032.9550903610639
  },
  {
    id: "bitbank",
    name: "Bitbank",
    year_established: 2016,
    country: "Japan",
    description: null,
    url: "https://bitbank.cc/",
    image:
      "https://assets.coingecko.com/markets/images/122/small/bitbank.jpg?1521186278",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 12,
    trade_volume_24h_btc: 1193.5359575179882,
    trade_volume_24h_btc_normalized: 1193.5359575179882
  },
  {
    id: "gemini",
    name: "Gemini",
    year_established: 2014,
    country: "United States",
    description: null,
    url: "https://gemini.com/",
    image:
      "https://assets.coingecko.com/markets/images/50/small/gemini.jpg?1519701179",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 13,
    trade_volume_24h_btc: 390.78295065260943,
    trade_volume_24h_btc_normalized: 390.78295065260943
  },
  {
    id: "binance_us",
    name: "Binance US",
    year_established: 2019,
    country: "United States",
    description: "",
    url: "https://www.binance.us/en",
    image:
      "https://assets.coingecko.com/markets/images/469/small/Binance.png?1568875842",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 14,
    trade_volume_24h_btc: 260.02732643344393,
    trade_volume_24h_btc_normalized: 260.02732643344393
  },
  {
    id: "huobi_us",
    name: "Huobi US (HBUS)",
    year_established: 2019,
    country: "United States",
    description: null,
    url: "https://www.hbus.com/",
    image:
      "https://assets.coingecko.com/markets/images/350/small/Huobi.jpg?1550055675",
    has_trading_incentive: false,
    trust_score: 9,
    trust_score_rank: 15,
    trade_volume_24h_btc: 10.05639016537546,
    trade_volume_24h_btc_normalized: 10.05639016537546
  },
  {
    id: "upbit",
    name: "Upbit",
    year_established: 2017,
    country: "South Korea",
    description: null,
    url: "https://upbit.com",
    image:
      "https://assets.coingecko.com/markets/images/117/small/upbit.png?1520388800",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 16,
    trade_volume_24h_btc: 9831.012997102967,
    trade_volume_24h_btc_normalized: 9831.012997102967
  },
  {
    id: "okex",
    name: "OKEx",
    year_established: 2013,
    country: "Belize",
    description: "",
    url: "https://www.okex.com/",
    image:
      "https://assets.coingecko.com/markets/images/96/small/okEX.jpg?1519349636",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 17,
    trade_volume_24h_btc: 66532.69311808966,
    trade_volume_24h_btc_normalized: 3714.3457496084993
  },
  {
    id: "hitbtc",
    name: "HitBTC",
    year_established: 2013,
    country: null,
    description:
      "HitBTC is the world’s most advanced cryptocurrency exchange. Since 2013, HitBTC has been providing markets for Bitcoin, Ethereum, Litecoin, Dogecoin, Monero, USDT, and more than 300 cryptocurrencies in total.\r\n\r\nCreated by outstanding technical minds, high-level finance professionals and experienced traders, HitBTC delivers the most reliable, fast and powerful platform solution on the market.\r\n\r\nThe platform has earned its reputation for fault-tolerance, flawless uptime and high availability. Its core matching engine is among the best technological products in its class, offering traders a wide range of features such as real-time clearing and cutting-edge order matching algorithms.\r\n\r\nKey Features:\r\nRebate system - attractive to the most powerful market makers, creating high liquidity\r\nNo limits on the deposit or withdrawal of digital assets\r\nCutting-edge matching engine technologies\r\nWide range of available instruments (more than 500)\r\nThe most advanced API on the market (REST API and FIX API)\r\nAccess to the crypto assets exchange platform technology under the registered trademark “HitBTC” is provided in accordance with the Terms of Service.",
    url: "https://hitbtc.com/",
    image:
      "https://assets.coingecko.com/markets/images/24/small/hitbtc.png?1568217824",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 18,
    trade_volume_24h_btc: 47547.911039718325,
    trade_volume_24h_btc_normalized: 3556.8661783531675
  },
  {
    id: "exmo",
    name: "EXMO",
    year_established: 2013,
    country: "United Kingdom",
    description: null,
    url: "https://exmo.com/",
    image:
      "https://assets.coingecko.com/markets/images/59/small/exmo.jpg?1519716325",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 19,
    trade_volume_24h_btc: 2004.3763754351464,
    trade_volume_24h_btc_normalized: 2004.3763754351464
  },
  {
    id: "coinone",
    name: "Coinone",
    year_established: 2016,
    country: "South Korea",
    description: null,
    url: "https://coinone.co.kr/",
    image:
      "https://assets.coingecko.com/markets/images/20/small/coinone.jpg?1519639596",
    has_trading_incentive: null,
    trust_score: 8,
    trust_score_rank: 20,
    trade_volume_24h_btc: 1739.810966980746,
    trade_volume_24h_btc_normalized: 1739.810966980746
  },
  {
    id: "coindeal",
    name: "Coindeal",
    year_established: 2018,
    country: "Malta",
    description: null,
    url: "https://coindeal.com/",
    image:
      "https://assets.coingecko.com/markets/images/313/small/2ZP5JaIL_400x400.jpg?1541109266",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 21,
    trade_volume_24h_btc: 1726.200461558176,
    trade_volume_24h_btc_normalized: 1327.1027461615627
  },
  {
    id: "bitmart",
    name: "BitMart",
    year_established: 2017,
    country: "Cayman Islands",
    description: null,
    url: "https://www.bitmart.com/",
    image:
      "https://assets.coingecko.com/markets/images/239/small/bitmart.png?1536057590",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 22,
    trade_volume_24h_btc: 102273.88999034368,
    trade_volume_24h_btc_normalized: 899.8165488327887
  },
  {
    id: "omgfin",
    name: "Omgfin",
    year_established: 2018,
    country: "Estonia",
    description: "",
    url: "https://omgfin.com/exchange/",
    image:
      "https://assets.coingecko.com/markets/images/396/small/Screenshot_11.png?1556858288",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 23,
    trade_volume_24h_btc: 837.2712240389413,
    trade_volume_24h_btc_normalized: 837.2712240389413
  },
  {
    id: "tokenize",
    name: "Tokenize",
    year_established: 2018,
    country: "Singapore",
    description: null,
    url: "https://tokenize.exchange/",
    image:
      "https://assets.coingecko.com/markets/images/383/small/logo_%282%29.png?1554275728",
    has_trading_incentive: false,
    trust_score: 8,
    trust_score_rank: 24,
    trade_volume_24h_btc: 1429.70546019747,
    trade_volume_24h_btc_normalized: 651.4290792181699
  },
  {
    id: "zaif",
    name: "Zaif",
    year_established: 2015,
    country: "Japan",
    description: null,
    url: "https://zaif.jp/",
    image:
      "https://assets.coingecko.com/markets/images/99/small/zaif.png?1519627467",
    has_trading_incentive: null,
    trust_score: 8,
    trust_score_rank: 25,
    trade_volume_24h_btc: 392.47512021486943,
    trade_volume_24h_btc_normalized: 392.47512021486943
  }
];

export const exchanges = [
  {
    id: "abcc",
    name: "ABCC"
  },
  {
    id: "acx",
    name: "ACX"
  },
  {
    id: "aex",
    name: "AEX"
  },
  {
    id: "airswap",
    name: "AirSwap"
  },
  {
    id: "allbit",
    name: "Allbit"
  },
  {
    id: "allcoin",
    name: "Allcoin"
  },
  {
    id: "alterdice",
    name: "AlterDice"
  },
  {
    id: "altilly",
    name: "Altilly"
  },
  {
    id: "altmarkets",
    name: "Altmarkets"
  },
  {
    id: "anx",
    name: "ANX"
  },
  {
    id: "aphelion",
    name: "Aphelion"
  },
  {
    id: "atomars",
    name: "Atomars"
  },
  {
    id: "axnet",
    name: "AXNET"
  },
  {
    id: "b2bx",
    name: "B2BX"
  },
  {
    id: "bamboo_relay",
    name: "Bamboo Relay"
  },
  {
    id: "bancor",
    name: "Bancor Network"
  },
  {
    id: "bankera",
    name: "Bankera"
  },
  {
    id: "bcex",
    name: "BCEX"
  },
  {
    id: "beaxy",
    name: "Beaxy"
  },
  {
    id: "bgogo",
    name: "Bgogo"
  },
  {
    id: "bhex",
    name: "BHEX"
  },
  {
    id: "bibox",
    name: "Bibox"
  },
  {
    id: "bibox_futures",
    name: "Bibox (Futures)"
  },
  {
    id: "bigmarkets",
    name: "BIG markets"
  },
  {
    id: "bigone",
    name: "BigONE"
  },
  {
    id: "bihodl",
    name: "BiHODL "
  },
  {
    id: "biki",
    name: "Biki"
  },
  {
    id: "bilaxy",
    name: "Bilaxy"
  },
  {
    id: "binance",
    name: "Binance"
  },
  {
    id: "binance_dex",
    name: "Binance DEX"
  },
  {
    id: "binance_futures",
    name: "Binance (Futures)"
  },
  {
    id: "binance_jersey",
    name: "Binance Jersey"
  },
  {
    id: "binance_us",
    name: "Binance US"
  },
  {
    id: "bione",
    name: "Bione"
  },
  {
    id: "birake",
    name: "Birake"
  },
  {
    id: "bisq",
    name: "Bisq"
  },
  {
    id: "bit2c",
    name: "Bit2c"
  },
  {
    id: "bitalong",
    name: "Bitalong"
  },
  {
    id: "bitasset",
    name: "BitAsset"
  },
  {
    id: "bitbank",
    name: "Bitbank"
  },
  {
    id: "bitbay",
    name: "BitBay"
  },
  {
    id: "bitbegin",
    name: "Bitbegin"
  },
  {
    id: "bitbox",
    name: "BITBOX"
  },
  {
    id: "bitc3",
    name: "Bitc3"
  },
  {
    id: "bitci",
    name: "Bitci"
  },
  {
    id: "bitcoin_com",
    name: "Bitcoin.com"
  },
  {
    id: "bitex",
    name: "Bitex.la"
  },
  {
    id: "bitexbook",
    name: "BITEXBOOK"
  },
  {
    id: "bitexlive",
    name: "Bitexlive"
  },
  {
    id: "bitfex",
    name: "Bitfex"
  },
  {
    id: "bitfinex",
    name: "Bitfinex"
  },
  {
    id: "bitfinex_futures",
    name: "Bitfinex (Futures)"
  },
  {
    id: "bitflyer",
    name: "bitFlyer"
  },
  {
    id: "bitflyer_futures",
    name: "Bitflyer (Futures)"
  },
  {
    id: "bitforex",
    name: "Bitforex"
  },
  {
    id: "bitforex_futures",
    name: "Bitforex (Futures)"
  },
  {
    id: "bithash",
    name: "BitHash"
  },
  {
    id: "bitholic",
    name: "Bithumb Singapore"
  },
  {
    id: "bithumb",
    name: "Bithumb"
  },
  {
    id: "bithumb_global",
    name: "Bithumb Global"
  },
  {
    id: "bitinfi",
    name: "Bitinfi"
  },
  {
    id: "bitker",
    name: "BITKER"
  },
  {
    id: "bitkonan",
    name: "BitKonan"
  },
  {
    id: "bitkub",
    name: "Bitkub"
  },
  {
    id: "bitlish",
    name: "Bitlish"
  },
  {
    id: "bitmart",
    name: "BitMart"
  },
  {
    id: "bitmax",
    name: "BitMax"
  },
  {
    id: "bitmesh",
    name: "Bitmesh"
  },
  {
    id: "bitmex",
    name: "Bitmex"
  },
  {
    id: "bitoffer",
    name: "Bitoffer"
  },
  {
    id: "bitonbay",
    name: "BitOnBay"
  },
  {
    id: "bitopro",
    name: "BitoPro"
  },
  {
    id: "bitpanda",
    name: "Bitpanda Global Exchange"
  },
  {
    id: "bitrabbit",
    name: "BitRabbit"
  },
  {
    id: "bitrue",
    name: "Bitrue"
  },
  {
    id: "bits_blockchain",
    name: "Bits Blockchain"
  },
  {
    id: "bitsdaq",
    name: "Bitsdaq"
  },
  {
    id: "bitshares_assets",
    name: "Bitshares Assets"
  },
  {
    id: "bitso",
    name: "Bitso"
  },
  {
    id: "bitsoda",
    name: "Bitsoda"
  },
  {
    id: "bitsonic",
    name: "Bitsonic"
  },
  {
    id: "bitstamp",
    name: "Bitstamp"
  },
  {
    id: "bitsten",
    name: "Bitsten"
  },
  {
    id: "bitstorage",
    name: "BitStorage"
  },
  {
    id: "bittrex",
    name: "Bittrex"
  },
  {
    id: "bit_z",
    name: "Bit-Z"
  },
  {
    id: "bitz_futures",
    name: "Bitz (Futures)"
  },
  {
    id: "bkex",
    name: "BKEX"
  },
  {
    id: "bleutrade",
    name: "bleutrade"
  },
  {
    id: "blockonix",
    name: "Blockonix"
  },
  {
    id: "boa",
    name: "BOA Exchange"
  },
  {
    id: "braziliex",
    name: "Braziliex"
  },
  {
    id: "btc_alpha",
    name: "BTC-Alpha"
  },
  {
    id: "btcbox",
    name: "BTCBOX"
  },
  {
    id: "btcc",
    name: "BTCC"
  },
  {
    id: "btcexa",
    name: "BTCEXA"
  },
  {
    id: "btc_exchange",
    name: "Btc Exchange"
  },
  {
    id: "btcmarkets",
    name: "BTCMarkets"
  },
  {
    id: "btcnext",
    name: "BTCNEXT"
  },
  {
    id: "btcsquare",
    name: "BTCSquare"
  },
  {
    id: "btc_trade_ua",
    name: "BTC Trade UA"
  },
  {
    id: "btcturk",
    name: "BTCTurk"
  },
  {
    id: "btse",
    name: "BTSE"
  },
  {
    id: "btse_futures",
    name: "BTSE (Futures)"
  },
  {
    id: "buyucoin",
    name: "BuyUcoin"
  },
  {
    id: "bw",
    name: "BW.com"
  },
  {
    id: "bx_thailand",
    name: "BX Thailand"
  },
  {
    id: "bybit",
    name: "Bybit"
  },
  {
    id: "c2cx",
    name: "C2CX"
  },
  {
    id: "cashierest",
    name: "Cashierest"
  },
  {
    id: "cashpayz",
    name: "Cashpayz"
  },
  {
    id: "catex",
    name: "Catex"
  },
  {
    id: "cbx",
    name: "CBX"
  },
  {
    id: "ccex",
    name: "C-CEX"
  },
  {
    id: "ccx",
    name: "CCXCanada"
  },
  {
    id: "cex",
    name: "CEX.IO"
  },
  {
    id: "cezex",
    name: "Cezex"
  },
  {
    id: "chainex",
    name: "ChainEX"
  },
  {
    id: "chainrift",
    name: "Chainrift"
  },
  {
    id: "chaoex",
    name: "CHAOEX"
  },
  {
    id: "citex",
    name: "CITEX"
  },
  {
    id: "cobinhood",
    name: "Cobinhood"
  },
  {
    id: "codex",
    name: "CODEX"
  },
  {
    id: "coinall",
    name: "CoinAll"
  },
  {
    id: "coinasset",
    name: "CoinAsset"
  },
  {
    id: "coinbe",
    name: "Coinbe"
  },
  {
    id: "coinbene",
    name: "CoinBene"
  },
  {
    id: "coinbig",
    name: "COINBIG"
  },
  {
    id: "coinbit",
    name: "Coinbit"
  },
  {
    id: "coinchangex",
    name: "Coinchangex"
  },
  {
    id: "coincheck",
    name: "Coincheck"
  },
  {
    id: "coindeal",
    name: "Coindeal"
  },
  {
    id: "coindirect",
    name: "CoinDirect"
  },
  {
    id: "coineal",
    name: "Coineal"
  },
  {
    id: "coin_egg",
    name: "CoinEgg"
  },
  {
    id: "coinex",
    name: "CoinEx"
  },
  {
    id: "coinfalcon",
    name: "Coinfalcon"
  },
  {
    id: "coinfield",
    name: "Coinfield"
  },
  {
    id: "coinfinit",
    name: "Coinfinit"
  },
  {
    id: "coinflex",
    name: "CoinFLEX"
  },
  {
    id: "coinflex_futures",
    name: "CoinFLEX (Futures)"
  },
  {
    id: "coinfloor",
    name: "Coinfloor"
  },
  {
    id: "coingi",
    name: "Coingi"
  },
  {
    id: "coinhe",
    name: "CoinHe"
  },
  {
    id: "coinhub",
    name: "Coinhub"
  },
  {
    id: "coinjar",
    name: "CoinJar Exchange"
  },
  {
    id: "coinlim",
    name: "Coinlim"
  },
  {
    id: "coin_metro",
    name: "Coinmetro"
  },
  {
    id: "coinmex",
    name: "CoinMex"
  },
  {
    id: "coinnest",
    name: "CoinNest"
  },
  {
    id: "coinone",
    name: "Coinone"
  },
  {
    id: "coinpark",
    name: "Coinpark"
  },
  {
    id: "coinplace",
    name: "Coinplace"
  },
  {
    id: "coinsbank",
    name: "Coinsbank"
  },
  {
    id: "coinsbit",
    name: "Coinsbit"
  },
  {
    id: "coinsuper",
    name: "Coinsuper"
  },
  {
    id: "cointiger",
    name: "CoinTiger"
  },
  {
    id: "coinxpro",
    name: "COINX.PRO"
  },
  {
    id: "coinzest",
    name: "Coinzest"
  },
  {
    id: "coinzo",
    name: "Coinzo"
  },
  {
    id: "coss",
    name: "COSS"
  },
  {
    id: "c_patex",
    name: "C-Patex"
  },
  {
    id: "cpdax",
    name: "CPDAX"
  },
  {
    id: "credoex",
    name: "CredoEx"
  },
  {
    id: "crex24",
    name: "CREX24"
  },
  {
    id: "crxzone",
    name: "CRXzone"
  },
  {
    id: "cryptaldash",
    name: "CryptalDash"
  },
  {
    id: "cryptex",
    name: "Cryptex"
  },
  {
    id: "crypto_bridge",
    name: "CryptoBridge"
  },
  {
    id: "cryptology",
    name: "Cryptology"
  },
  {
    id: "cryptonit",
    name: "Cryptonit"
  },
  {
    id: "crytrex",
    name: "CryTrEx"
  },
  {
    id: "cybex",
    name: "Cybex DEX"
  },
  {
    id: "dach_exchange",
    name: "Dach Exchange"
  },
  {
    id: "dakuce",
    name: "Dakuce"
  },
  {
    id: "darb_finance",
    name: "Darb Finance"
  },
  {
    id: "daybit",
    name: "Daybit"
  },
  {
    id: "dcoin",
    name: "Dcoin"
  },
  {
    id: "ddex",
    name: "DDEX"
  },
  {
    id: "decoin",
    name: "Decoin"
  },
  {
    id: "delta_futures",
    name: "Delta Exchange"
  },
  {
    id: "deribit",
    name: "Deribit"
  },
  {
    id: "dextop",
    name: "DEx.top"
  },
  {
    id: "dextrade",
    name: "Dex-Trade"
  },
  {
    id: "dflow",
    name: "Dflow"
  },
  {
    id: "digifinex",
    name: "Digifinex"
  },
  {
    id: "digitalprice",
    name: "DigitalPrice"
  },
  {
    id: "dobitrade",
    name: "Dobitrade"
  },
  {
    id: "dove_wallet",
    name: "Dove Wallet"
  },
  {
    id: "dragonex",
    name: "DragonEx"
  },
  {
    id: "dsx",
    name: "DSX"
  },
  {
    id: "dydx",
    name: "dYdX"
  },
  {
    id: "ecxx",
    name: "Ecxx"
  },
  {
    id: "eosex",
    name: "EOSex"
  },
  {
    id: "escodex",
    name: "Escodex"
  },
  {
    id: "eterbase",
    name: "Eterbase"
  },
  {
    id: "etherflyer",
    name: "EtherFlyer"
  },
  {
    id: "ethex",
    name: "Ethex"
  },
  {
    id: "everbloom",
    name: "Everbloom"
  },
  {
    id: "exmarkets",
    name: "ExMarkets"
  },
  {
    id: "exmo",
    name: "EXMO"
  },
  {
    id: "exnce",
    name: "EXNCE"
  },
  {
    id: "exrates",
    name: "Exrates"
  },
  {
    id: "extstock",
    name: "ExtStock"
  },
  {
    id: "exx",
    name: "EXX"
  },
  {
    id: "f1cx",
    name: "F1CX"
  },
  {
    id: "fatbtc",
    name: "FatBTC"
  },
  {
    id: "fcoin",
    name: "FCoin"
  },
  {
    id: "fex",
    name: "FEX"
  },
  {
    id: "financex",
    name: "FinanceX"
  },
  {
    id: "finexbox",
    name: "FinexBox"
  },
  {
    id: "fisco",
    name: "Fisco"
  },
  {
    id: "floatsv",
    name: "Float SV"
  },
  {
    id: "forkdelta",
    name: "ForkDelta"
  },
  {
    id: "freiexchange",
    name: "Freiexchange"
  },
  {
    id: "ftx",
    name: "FTX"
  },
  {
    id: "ftx_spot",
    name: "FTX (Spot)"
  },
  {
    id: "fubt",
    name: "FUBT"
  },
  {
    id: "gate",
    name: "Gate.io"
  },
  {
    id: "gate_futures",
    name: "Gate.io (Futures)"
  },
  {
    id: "gbx",
    name: "Gibraltar Blockchain Exchange"
  },
  {
    id: "gdac",
    name: "GDAC"
  },
  {
    id: "gdax",
    name: "Coinbase Pro"
  },
  {
    id: "gemini",
    name: "Gemini"
  },
  {
    id: "getbtc",
    name: "GetBTC"
  },
  {
    id: "gmo_japan",
    name: "GMO Japan"
  },
  {
    id: "gmo_japan_futures",
    name: "GMO Japan (Futures)"
  },
  {
    id: "gobaba",
    name: "Gobaba"
  },
  {
    id: "go_exchange",
    name: "Go Exchange"
  },
  {
    id: "gopax",
    name: "GoPax"
  },
  {
    id: "graviex",
    name: "Graviex"
  },
  {
    id: "hanbitco",
    name: "Hanbitco"
  },
  {
    id: "hb_top",
    name: "Hb.top"
  },
  {
    id: "hitbtc",
    name: "HitBTC"
  },
  {
    id: "hotbit",
    name: "Hotbit"
  },
  {
    id: "hpx",
    name: "HPX"
  },
  {
    id: "hubi",
    name: "Hubi"
  },
  {
    id: "huobi",
    name: "Huobi Global"
  },
  {
    id: "huobi_dm",
    name: "Huobi DM"
  },
  {
    id: "huobi_japan",
    name: "Huobi Japan"
  },
  {
    id: "huobi_korea",
    name: "Huobi Korea"
  },
  {
    id: "huobi_us",
    name: "Huobi US (HBUS)"
  },
  {
    id: "ice3x",
    name: "Ice3x"
  },
  {
    id: "idax",
    name: "Idax"
  },
  {
    id: "idcm",
    name: "IDCM"
  },
  {
    id: "idex",
    name: "Idex"
  },
  {
    id: "incorex",
    name: "IncoreX"
  },
  {
    id: "independent_reserve",
    name: "Independent Reserve"
  },
  {
    id: "indodax",
    name: "Indodax"
  },
  {
    id: "infinity_coin",
    name: "Infinity Coin"
  },
  {
    id: "instantbitex",
    name: "Instant Bitex"
  },
  {
    id: "iqfinex",
    name: "IQFinex"
  },
  {
    id: "ironex",
    name: "Ironex"
  },
  {
    id: "itbit",
    name: "itBit"
  },
  {
    id: "jex",
    name: "Binance JEX"
  },
  {
    id: "jex_futures",
    name: "Binance JEX (Futures)"
  },
  {
    id: "joyso",
    name: "Joyso"
  },
  {
    id: "kairex",
    name: "KAiREX"
  },
  {
    id: "kkcoin",
    name: "KKCoin"
  },
  {
    id: "k_kex",
    name: "KKEX"
  },
  {
    id: "koinok",
    name: "Koinok"
  },
  {
    id: "koinx",
    name: "Koinx"
  },
  {
    id: "korbit",
    name: "Korbit"
  },
  {
    id: "kraken",
    name: "Kraken"
  },
  {
    id: "kraken_futures",
    name: "Kraken (Futures)"
  },
  {
    id: "kryptono",
    name: "Kryptono"
  },
  {
    id: "kucoin",
    name: "KuCoin"
  },
  {
    id: "kumex",
    name: "Kumex"
  },
  {
    id: "kuna",
    name: "Kuna Exchange"
  },
  {
    id: "kyber_network",
    name: "Kyber Network"
  },
  {
    id: "lakebtc",
    name: "LakeBTC"
  },
  {
    id: "latoken",
    name: "LATOKEN"
  },
  {
    id: "lbank",
    name: "Lbank"
  },
  {
    id: "letsdocoinz",
    name: "Letsdocoinz"
  },
  {
    id: "livecoin",
    name: "Livecoin"
  },
  {
    id: "localtrade",
    name: "LocalTrade"
  },
  {
    id: "lukki",
    name: "Lukki"
  },
  {
    id: "luno",
    name: "Luno"
  },
  {
    id: "lykke",
    name: "Lykke"
  },
  {
    id: "mandala",
    name: "Mandala"
  },
  {
    id: "max_maicoin",
    name: "Max Maicoin"
  },
  {
    id: "mercado_bitcoin",
    name: "Mercado Bitcoin"
  },
  {
    id: "mercatox",
    name: "Mercatox"
  },
  {
    id: "mercuriex",
    name: "MercuriEx"
  },
  {
    id: "mxc",
    name: "MXC"
  },
  {
    id: "nanu_exchange",
    name: "Nanu Exchange"
  },
  {
    id: "nash",
    name: "Nash"
  },
  {
    id: "neblidex",
    name: "Neblidex"
  },
  {
    id: "negociecoins",
    name: "Negociecoins"
  },
  {
    id: "neraex",
    name: "Neraex"
  },
  {
    id: "newdex",
    name: "Newdex"
  },
  {
    id: "nexybit",
    name: "Nexybit"
  },
  {
    id: "ninecoin",
    name: "9coin"
  },
  {
    id: "nlexch",
    name: "NLexch"
  },
  {
    id: "novadax",
    name: "NovaDAX"
  },
  {
    id: "novadex",
    name: "Novadex"
  },
  {
    id: "oasis_trade",
    name: "OasisDEX"
  },
  {
    id: "oceanex",
    name: "Oceanex"
  },
  {
    id: "oex",
    name: "OEX"
  },
  {
    id: "okcoin",
    name: "OKCoin"
  },
  {
    id: "okex",
    name: "OKEx"
  },
  {
    id: "okex_korea",
    name: "OKEx Korea"
  },
  {
    id: "okex_swap",
    name: "OKEx (Futures)"
  },
  {
    id: "omgfin",
    name: "Omgfin"
  },
  {
    id: "omnitrade",
    name: "OmniTrade"
  },
  {
    id: "ooobtc",
    name: "OOOBTC"
  },
  {
    id: "openledger",
    name: "OpenLedger DEX"
  },
  {
    id: "orderbook",
    name: "Orderbook.io"
  },
  {
    id: "ore_bz",
    name: "Ore BZ"
  },
  {
    id: "otcbtc",
    name: "OTCBTC"
  },
  {
    id: "ovex",
    name: "Ovex"
  },
  {
    id: "p2pb2b",
    name: "P2PB2B"
  },
  {
    id: "paribu",
    name: "Paribu"
  },
  {
    id: "paroexchange",
    name: "Paro Exchange"
  },
  {
    id: "paymium",
    name: "Paymium"
  },
  {
    id: "piexgo",
    name: "Piexgo"
  },
  {
    id: "poloniex",
    name: "Poloniex"
  },
  {
    id: "prime_xbt",
    name: "Prime XBT"
  },
  {
    id: "probit",
    name: "Probit"
  },
  {
    id: "purcow",
    name: "Purcow"
  },
  {
    id: "qbtc",
    name: "QBTC"
  },
  {
    id: "qtrade",
    name: "qTrade"
  },
  {
    id: "quoine",
    name: "Liquid"
  },
  {
    id: "radar_relay",
    name: "Radar Relay"
  },
  {
    id: "raidofinance",
    name: "Raidofinance"
  },
  {
    id: "raisex",
    name: "Raisex"
  },
  {
    id: "rfinex",
    name: "Rfinex"
  },
  {
    id: "safe_trade",
    name: "SafeTrade"
  },
  {
    id: "satoexchange",
    name: "SatoExchange"
  },
  {
    id: "sato_wallet_ex",
    name: "SatowalletEx"
  },
  {
    id: "saturn_network",
    name: "Saturn Network"
  },
  {
    id: "secondbtc",
    name: "SecondBTC"
  },
  {
    id: "shortex",
    name: "Shortex"
  },
  {
    id: "simex",
    name: "Simex"
  },
  {
    id: "sistemkoin",
    name: "Sistemkoin"
  },
  {
    id: "six_x",
    name: "6x"
  },
  {
    id: "south_xchange",
    name: "SouthXchange"
  },
  {
    id: "stake_cube",
    name: "StakeCube"
  },
  {
    id: "stellar_term",
    name: "StellarTerm"
  },
  {
    id: "stocks_exchange",
    name: "STEX"
  },
  {
    id: "swiftex",
    name: "Swiftex"
  },
  {
    id: "switcheo",
    name: "Switcheo"
  },
  {
    id: "syex",
    name: "Shangya Exchange"
  },
  {
    id: "synthetix",
    name: "Synthetix Exchange"
  },
  {
    id: "tdax",
    name: "Satang Pro"
  },
  {
    id: "therocktrading",
    name: "TheRockTrading"
  },
  {
    id: "thetokenstore",
    name: "Token.Store"
  },
  {
    id: "thinkbit",
    name: "ThinkBit Pro"
  },
  {
    id: "three_xbit",
    name: "3XBIT"
  },
  {
    id: "tidebit",
    name: "Tidebit"
  },
  {
    id: "tidex",
    name: "Tidex"
  },
  {
    id: "tokenize",
    name: "Tokenize"
  },
  {
    id: "tokenjar",
    name: "TokenJar"
  },
  {
    id: "tokenomy",
    name: "Tokenomy"
  },
  {
    id: "tokens_net",
    name: "TokensNet"
  },
  {
    id: "tokok",
    name: "TOKOK"
  },
  {
    id: "tokpie",
    name: "Tokpie"
  },
  {
    id: "topbtc",
    name: "TopBTC"
  },
  {
    id: "tradeio",
    name: "Trade.io"
  },
  {
    id: "trade_ogre",
    name: "TradeOgre"
  },
  {
    id: "trade_satoshi",
    name: "Trade Satoshi"
  },
  {
    id: "troca_ninja",
    name: "Troca.Ninja"
  },
  {
    id: "tron_trade",
    name: "TronTrade"
  },
  {
    id: "trx_market",
    name: "TRXMarket"
  },
  {
    id: "tux_exchange",
    name: "Tux Exchange"
  },
  {
    id: "txbit",
    name: "Txbit"
  },
  {
    id: "uex",
    name: "UEX"
  },
  {
    id: "uniswap",
    name: "Uniswap"
  },
  {
    id: "unnamed",
    name: "Unnamed"
  },
  {
    id: "upbit",
    name: "Upbit"
  },
  {
    id: "upbit_indonesia",
    name: "Upbit Indonesia "
  },
  {
    id: "vbitex",
    name: "Vbitex"
  },
  {
    id: "vebitcoin",
    name: "Vebitcoin"
  },
  {
    id: "velic",
    name: "Velic"
  },
  {
    id: "vindax",
    name: "Vindax"
  },
  {
    id: "vinex",
    name: "Vinex"
  },
  {
    id: "waves",
    name: "Waves Platform"
  },
  {
    id: "wazirx",
    name: "WazirX"
  },
  {
    id: "whale_ex",
    name: "WhaleEx"
  },
  {
    id: "whitebit",
    name: "Whitebit"
  },
  {
    id: "worldcore",
    name: "Worldcore"
  },
  {
    id: "xfutures",
    name: "xFutures"
  },
  {
    id: "xt",
    name: "XT"
  },
  {
    id: "yobit",
    name: "YoBit"
  },
  {
    id: "yunex",
    name: "Yunex.io"
  },
  {
    id: "zaif",
    name: "Zaif"
  },
  {
    id: "zb",
    name: "ZB"
  },
  {
    id: "zbg",
    name: "ZBG"
  },
  {
    id: "zbmega",
    name: "ZB Mega"
  },
  {
    id: "zebpay",
    name: "Zebpay"
  },
  {
    id: "zg",
    name: "ZG.com"
  },
  {
    id: "zgtop",
    name: "ZG.TOP"
  }
];

export const supportedVsCurrencies = [
  "btc",
  "eth",
  "ltc",
  "bch",
  "bnb",
  "eos",
  "xrp",
  "xlm",
  "usd",
  "usdc",
  "aed",
  "ars",
  "aud",
  "bdt",
  "bhd",
  "bmd",
  "brl",
  "cad",
  "chf",
  "clp",
  "cny",
  "czk",
  "dkk",
  "eur",
  "gbp",
  "hkd",
  "huf",
  "idr",
  "ils",
  "inr",
  "jpy",
  "krw",
  "kwd",
  "lkr",
  "mmk",
  "mxn",
  "myr",
  "nok",
  "nzd",
  "php",
  "pkr",
  "pln",
  "rub",
  "sar",
  "sek",
  "sgd",
  "thb",
  "try",
  "twd",
  "uah",
  "vef",
  "vnd",
  "zar",
  "xdr",
  "xag",
  "xau"
];
