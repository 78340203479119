import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { calculateNumDecimals, themeColors } from "../../services/services";

const TradeChartTimeline = ({
  trade,
  currentPrice,
  priceHistory = [],
  height = 450,
}) => {
  const { active, coinTarget, buy, target, tradeType, sell } = trade;

  let timeData = [];

  for (let item of priceHistory) {
    timeData.push([item[0], item[1]]);
  }

  const calculateYAxisTop = () => {
    let priceHistoryMax = timeData.reduce(
      (max, p) => (p[1] > max ? p[1] : max),
      timeData[0][1]
    );

    const max =
      tradeType === "LONG"
        ? priceHistoryMax > target.profit
          ? priceHistoryMax
          : target.profit
        : priceHistoryMax > target.stopLoss
        ? priceHistoryMax
        : target.stopLoss;

    return max * 1.03;
  };

  const calculateYAxisBottom = () => {
    let priceHistoryMin = timeData.reduce(
      (min, p) => (p[1] < min ? p[1] : min),
      timeData[0][1]
    );

    const min =
      tradeType === "LONG"
        ? priceHistoryMin < target.stopLoss
          ? priceHistoryMin
          : target.stopLoss
        : priceHistoryMin < target.profit
        ? priceHistoryMin
        : target.profit;

    return min * 0.97;
  };

  const createPoints = () => {
    let points = [
      {
        x: buy.date.toDate().getTime(),
        y: buy.price,
        marker: {
          size: 8,
          fillColor: "#fff",
          strokeColor: themeColors.isLink,
          radius: 2,
        },
        label: {
          borderColor: themeColors.isLink,
          offsetY: 0,
          style: {
            color: "#fff",
            background: themeColors.isLink,
          },

          text: "Buy",
        },
      },
    ];

    if (!active) {
      points.push({
        x: sell.date.toDate().getTime(),
        y: sell.price,
        marker: {
          size: 8,
          fillColor: "#fff",
          strokeColor: themeColors.isPrimary,
          radius: 2,
        },
        label: {
          borderColor: themeColors.isPrimary,
          offsetY: 0,
          style: {
            color: "#fff",
            background: themeColors.isPrimary,
          },

          text: "Sell",
        },
      });
    }
    return points;
  };

  const series = [
    {
      data: timeData,
    },
  ];

  const options = {
    annotations: {
      points: createPoints(),
      yaxis: [
        {
          y: target.profit,
          borderColor: themeColors.isSuccess,

          label: {
            text: "Profit target",
            style: {
              color: "#fff",
              background: themeColors.isSuccess,
            },
          },
        },
        {
          y: target.stopLoss,
          borderColor: themeColors.isDanger,
          label: {
            text: "Stop loss target",
            style: {
              color: "#fff",
              background: themeColors.isDanger,
            },
          },
        },
      ],
    },
    colors: [themeColors.isInfo],
    dataLabels: {
      enabled: false,
    },
    grid: {
      row: {
        colors: [themeColors.isLight, "transparent"], // takes an array which will be repeated on columns
        opacity: 0.3,
      },
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      y: {
        title: {
          formatter: (seriesName) => coinTarget,
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      decimalsInFloat: calculateNumDecimals(currentPrice),
      max: calculateYAxisTop(),
      min: calculateYAxisBottom(),
    },
  };

  return (
    <Chart options={options} series={series} type="line" height={height} />
  );
};

TradeChartTimeline.propTypes = {
  trade: PropTypes.object,
  priceHistory: PropTypes.array,
};

export default TradeChartTimeline;
