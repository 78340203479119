import React, { useEffect } from "react";
import Layout from "../../components/UI/Layout";
import UIHero from "../../components/UI/Hero";
import { Helmet } from "react-helmet";
import { analytics } from "../../components/Firebase/context";

const ScreensResources = () => {
  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "Resources" });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Resources | Trade-r</title>
      </Helmet>
      <UIHero title="Resources" subtitle="Knowledge is power (and profit)" />
      <section className="section">
        <div className="container">
          <p className="notification is-warning is-size-4 is-size-4-mobile is-uppercase has-text-centered">
            Coming soon...
          </p>
          {/* <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-image">
                  <figure className="image is-4by3">
                    {" "}
                    <img
                      src="https://source.unsplash.com/random/800x600"
                      alt="Image"
                    />{" "}
                  </figure>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure
                        className="image"
                        style={{ height: "40px", width: "40px" }}
                      >
                        {" "}
                        <img
                          src="https://source.unsplash.com/random/96x96"
                          alt="Image"
                        />{" "}
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">John Smith</p>
                      <p className="subtitle is-6">@johnsmith</p>
                    </div>
                  </div>
                  <div className="content">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris.
                    <a>@bulmaio</a>.<a>#css</a>
                    <a>#responsive</a>
                    <br /> <small>11:09 PM - 1 Jan 2016</small>{" "}
                  </div>
                </div>
                <footer className="card-footer">
                  <a className="card-footer-item">
                    Share Twitter Facebook Email More?
                  </a>
                </footer>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title"> Component </p>
                  <a className="card-header-icon">
                    <span className="icon">
                      {" "}
                      <i className="fa fa-angle-down"></i>{" "}
                    </span>
                  </a>
                </header>
                <div className="card-content">
                  <div className="content">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris.
                    <a>@bulmaio</a>.<a>#css</a>
                    <a>#responsive</a>
                    <br /> <small>11:09 PM - 1 Jan 2016</small>{" "}
                  </div>
                </div>
                <footer className="card-footer">
                  <a className="card-footer-item">Save</a>
                  <a className="card-footer-item">Edit</a>
                  <a className="card-footer-item">Delete</a>
                </footer>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  );
};

export default ScreensResources;
