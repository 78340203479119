import React from "react";
import PropTypes from "prop-types";
import {
  getCoinImage,
  formatPair,
  formatPrice,
  formatDate,
} from "../../services/services";
import { preferredCurrencies } from "../../services/preferred-currencies";
import { Link } from "react-router-dom";

const DashboardSalesTables = ({ trades = [], unit, userPreferences }) => {
  if (trades.length === 0 || !userPreferences) {
    return null;
  }

  const preferredCurrencyUnit = preferredCurrencies.find(
    (c) => c.value === userPreferences.preferredCurrency
  ).unit;

  const decimalPlaces = userPreferences.preferredCurrency !== "btc" ? 2 : null;

  const renderPrefCur = (value) => (
    <span className="has-text-grey">
      {preferredCurrencyUnit}
      {formatPrice(value, decimalPlaces)}
    </span>
  );

  return (
    <div>
      <div className="table-container">
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>Coin Pair</th>
              <th className="has-text-right is-hidden-mobile">Buy Price</th>
              <th className="has-text-right is-hidden-mobile">Sell Price</th>
              <th className="has-text-right is-hidden-mobile">Quantity</th>
              <th className="has-text-right">Profit</th>
            </tr>
          </thead>
          <tbody>
            {trades.map((trade) => {
              const {
                id,
                coinBase,
                coinTarget,
                buy,
                sell,
                profit,
                profitPreferredCurrency,
                tradeType,
              } = trade;
              return (
                <tr key={id}>
                  <td>
                    <Link to={`/trade/${id}`}>
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <figure className="image is-24x24">
                              <img
                                src={getCoinImage(coinBase)}
                                alt="coin logo"
                              />
                            </figure>
                          </div>
                          <div className="level-item is-size-5-desktop">
                            {formatPair(trade)}{" "}
                          </div>

                          {tradeType !== "LONG" && (
                            <div className="level-item">
                              <span className="tag is-info">SHORT</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td className="has-text-right is-hidden-mobile">
                    {`${formatPrice(buy.price)} ${coinTarget}`}
                    <br />
                    {renderPrefCur(buy.pricePreferredCurrency)}
                    <br />
                    <span className="has-text-grey is-italic">
                      {formatDate(buy.date)}
                    </span>
                  </td>
                  <td className="has-text-right is-hidden-mobile">
                    {`${formatPrice(sell.price)} ${coinTarget}`}
                    <br />
                    {renderPrefCur(sell.pricePreferredCurrency)}
                    <br />
                    <span className="has-text-grey is-italic">
                      {formatDate(sell.date)}
                    </span>
                  </td>
                  <td className="has-text-right is-hidden-mobile">
                    {formatPrice(sell.quantity)}
                  </td>
                  <td className={`has-text-right`}>
                    <span
                      className={
                        profit > 0 ? "has-text-success" : "has-text-danger"
                      }
                    >{`${formatPrice(profit)} ${coinTarget}`}</span>
                    <br />
                    {renderPrefCur(profitPreferredCurrency)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

DashboardSalesTables.propTypes = {
  trades: PropTypes.array,
  userPreferences: PropTypes.object,
};

export default DashboardSalesTables;
