import React, { useEffect } from "react";
import Layout from "../components/UI/Layout";
import UIHero from "../components/UI/Hero";
import { preferredCurrencies } from "../services/preferred-currencies";
import LogoInPage from "../components/UI/LogoInPage";
import { Helmet } from "react-helmet";
import { analytics } from "../components/Firebase/context";

const ScreensFaqs = () => {
  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "FAQs" });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Frequently Asked Questions | Trade-r</title>
      </Helmet>
      <UIHero title="FAQs" subtitle="Frequently Asked Questions" />
      <section className="section">
        <div className="container">
          <div className="box">
            <div className="column is-8 content">
              <h2 className="subtitle is-size-5">
                What's a preferred currency?
              </h2>
              <p>A preferred currency is (currently): </p>
              <ul>
                {preferredCurrencies.map((currency) => (
                  <li
                    key={currency.value}
                  >{`${currency.unit} ${currency.label}`}</li>
                ))}
              </ul>
              <p>
                <LogoInPage isDark /> uses a{" "}
                <span className="is-italic">Preferred Currency</span> as a
                comparison operator to estimate trade performance across{" "}
                <span className="is-italic">Coin Targets</span>.
              </p>
              <p className="notification is-warning">
                <span className="tag is-primary is-uppercase">For example</span>{" "}
                Sam has an ADA/BTC trade, and also a few BTC/USD trades. Her
                preferred currency is USD. Say Sam sells the ADA/BTC trade for
                .05 BTC profit, <LogoInPage isDark /> saves a converted
                preferred currency (USD) profit value (maybe ~ $300) in addition
                to the base currency profit value.
              </p>
              <p>
                <LogoInPage isDark /> can then do a few performance
                calculations. For example:
              </p>
              <ul>
                <li>Track overall profit value amongst all trades; or</li>
                <li>
                  Compare trades having different{" "}
                  <strong>Base Currencies</strong>. To see if, say an ADA/
                  <strong>BTC</strong> trade was more or less profitable then a
                  BTC/<strong>USD</strong> trade.
                </li>
              </ul>

              <hr />
              <h2 className="subtitle is-size-5">
                My trade got corrupted and doesn't look right. What can I do?
              </h2>
              <p>
                Aye... sorry. Still working out the kinks. For now, please
                delete the trade, and add the trade again. If the problem
                persists, please file a bug report below.
              </p>
              <p>
                In the initial release, Trade-r only supports adding and
                deleting trades. A future version will feature trade edits.
              </p>
              <hr />
              <h2 className="subtitle is-size-5">How do I report a bug?</h2>
              <p>
                If you'd like to report a bug, please email{" "}
                <a href="mailto:info@trade-r.com">info@trade-r.com</a> with as
                much info as possible.
              </p>
              <ul>
                <li>What page it occurred?</li>
                <li>
                  Your email address associated with your account so we can
                  track it down
                </li>
                <li>Which trade the bug popped up in</li>
              </ul>
              <hr />
              <h2 className="subtitle is-size-5">
                This app is super awesome, and now I'm a disciplined trader
                swimming in money. How can I contribute to the project?
              </h2>
              <p>
                Well, congratulations! To support Trade-r, consider donating
                some{" "}
                <a
                  href="https://basicattentiontoken.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Basic Attention Token
                </a>{" "}
                by visiting this site in the{" "}
                <a
                  href="https://brave.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Brave browser
                </a>
                , or send some Bitcoin to the following address: " "
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ScreensFaqs;
