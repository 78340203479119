import React, { useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/trade-r-logo-gradient-green.png";
import { AuthContext } from "../Auth";
import { FirebaseContext } from "../Firebase/context";
import { UserContext } from "../User/UserContext";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const [mobileNavOpen, setMobileNavOpen] = useState();
  const { currentUser } = useContext(AuthContext);
  const { updateTrades, updateUserPreferences } = useContext(UserContext);
  const { app } = useContext(FirebaseContext);

  const handleLogout = (e) => {
    e.preventDefault();
    app.auth().signOut();

    // Clear out User context values
    updateTrades([]);
    updateUserPreferences({ dateAdded: "", preferredCurrency: "" });

    history.push("/");
  };

  return (
    <nav
      className={`navbar ${location.pathname === "/" ? "is-transparent" : ""}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src={logo} alt="Trade-R logo" className="logo" />
        </Link>
        <div
          className={`navbar-burger burger ${mobileNavOpen ? "is-active" : ""}`}
          data-target="navMenu"
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div
        id="navMenu"
        className={`navbar-menu ${mobileNavOpen ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          {currentUser && (
            <Link to="/dashboard" className="navbar-item">
              Dashboard
            </Link>
          )}

          {currentUser && (
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link" to="/trade/list">
                Trades
              </Link>
              <div className="navbar-dropdown">
                <Link to="/trade/form" className="navbar-item">
                  Add a Trade
                </Link>
                <Link to="/trade/list" className="navbar-item">
                  View All Trades
                </Link>
              </div>
            </div>
          )}
          <Link to="/resources" className="navbar-item">
            Resources
          </Link>
        </div>
        <div className="navbar-end">
          <Link to="/faqs" className="navbar-item">
            F.A.Q.s
          </Link>

          {currentUser && (
            <>
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">
                  <span style={{ marginRight: "1rem" }}>
                    {currentUser.email}
                  </span>
                  <span className="icon">
                    <FontAwesomeIcon icon="user-circle" size="2x" />
                  </span>
                </div>

                <div id="userDropdown" className="navbar-dropdown ">
                  <Link to="/settings" className="navbar-item">
                    Settings
                  </Link>
                  <hr className="navbar-divider"></hr>
                  <a
                    className="navbar-item "
                    href="#nothing"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </a>
                </div>
              </div>
              {/* <div className="navbar-item">
                <Link to="/donate" className="button is-warning is-outlined">
                  <span className="icon">
                    <FontAwesomeIcon icon={["fab", "bitcoin"]} />
                  </span>
                  <span>Donate</span>
                </Link>
              </div> */}
            </>
          )}

          {!currentUser && (
            <>
              <div className="navbar-item">
                <Link
                  className="button is-primary is-inverted is-outlined"
                  to="/user/sign-in"
                >
                  Sign In
                </Link>
              </div>
              <span className="navbar-item">
                <HashLink className="button is-inverted" to="/user/start">
                  Get Started
                </HashLink>
              </span>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
