import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import TradeNotificationDetailed from "./NotificationDetailed";
import { getPreferredCurrencyPrices, getUserTrades, updateTrade } from "./api";
import {
  formatPair,
  formatPrice,
  shouldSellLoss,
  shouldSellProfit,
  formatDate,
} from "../../services/services";
import firebase from "firebase/app";
import "firebase/firestore";
import toastWrapper from "../../services/toast-wrapper";
import { useForm } from "react-hook-form";
import { AuthContext } from "../Auth";
import { UserContext } from "../User/UserContext";
import DateTime from "react-datetime";

const UIModalSellForm = ({
  isActive = true,
  trade,
  handleClose,
  currentPrice,
}) => {
  const { currentUser } = useContext(AuthContext);
  const { updateTrades, userPreferences } = useContext(UserContext);
  const { register, errors, handleSubmit, setValue } = useForm();
  const ssProfit = shouldSellProfit(trade, currentPrice);
  const ssLoss = shouldSellLoss(trade, currentPrice);
  const formattedPair = formatPair(trade);

  const { id, buy, coinBase, coinTarget } = trade;

  useEffect(() => {
    setValue("sellQuantity", buy.quantity);
  }, [setValue, buy]);

  const onSubmit = async (data) => {
    const { buy, tradeType } = trade;
    const quantity = parseFloat(data.sellQuantity);
    const price = formatPrice(parseFloat(data.sellPrice));
    const now = firebase.firestore.Timestamp.fromDate(new Date());

    const profit =
      tradeType === "LONG"
        ? formatPrice(price * quantity - buy.price * buy.quantity)
        : formatPrice(buy.price * buy.quantity - price * quantity);

    const sellObj = {
      date: firebase.firestore.Timestamp.fromDate(new Date(data.sellDate)),
      quantity,
      price,
    };

    const preferredCurrencyObject = await getPreferredCurrencyPrices(
      { ...trade, active: false, sell: { ...sellObj } },
      userPreferences.preferredCurrency
    );

    const updateObj = {
      active: false,
      buy: {
        ...buy,
        pricePreferredCurrency: parseFloat(
          preferredCurrencyObject.preferredPriceBuy
        ),
      },
      sell: {
        ...sellObj,
        pricePreferredCurrency: parseFloat(
          preferredCurrencyObject.preferredPriceSell
        ),
      },
      profit,
      profitPreferredCurrency: preferredCurrencyObject.profit,
      updatedAt: now,
    };

    const response = await updateTrade(currentUser.uid, id, updateObj);

    // Handle error
    if (response && response.error) {
      return toastWrapper({
        type: "is-danger",
        message: `Error selling trade: ${response.message}`,
      });
    }

    toastWrapper({ type: "is-success", message: "Trade sold successfully" });

    // Update trades in UserContext
    const freshTrades = await getUserTrades(currentUser.uid);
    updateTrades(freshTrades);

    handleClose();
  };

  const renderNotification = () => {
    const { buy, target } = trade;

    return !(ssProfit || ssLoss) ? (
      <TradeNotificationDetailed className="is-warning" title="Warning">
        <div className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Current Price</p>
              <p className="is-size-3">
                <strong>{formatPrice(currentPrice)}</strong>
              </p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Target Profit</p>
              <p className="is-size-3">{formatPrice(target.profit)}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Buy Price</p>
              <p className="is-size-3">{formatPrice(buy.price)}</p>
            </div>
          </div>
        </div>
        <p>Selling now does not meet your profit or stop loss exit strategy.</p>
      </TradeNotificationDetailed>
    ) : null;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`modal ${isActive ? "is-active" : ""}`} id="modalSell">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Sell Trade: {formattedPair}</p>
            <button className="delete" onClick={handleClose}></button>
          </header>

          <section className="modal-card-body">
            <div className="content">
              <p className="has-text-grey">
                <strong>
                  {coinBase.toUpperCase()} - {formattedPair}
                </strong>
                <br />
                Purchased on: {formatDate(buy.date)} for{" "}
                {formatPrice(buy.price)} {coinTarget}
                <br />
                Current price: {currentPrice} {coinTarget}
              </p>
              <hr />

              {renderNotification()}

              <div className="field">
                <label className="label">Sell Date</label>
                <div className="control">
                  <DateTime
                    defaultValue={new Date()}
                    inputProps={{
                      className: `input  ${errors.sellDate ? "is-danger" : ""}`,
                      ref: register({ required: true }),
                      id: "sellDate",
                      name: "sellDate",
                    }}
                  />
                </div>

                {errors.sellDate && (
                  <p className="help is-danger">Sell Date is required</p>
                )}
              </div>

              <div className="field">
                <label className="label">Price</label>
                <p className={`control`}>
                  <input
                    className={`input  ${errors.sellPrice ? "is-danger" : ""}`}
                    type="number"
                    id="sellPrice"
                    name="sellPrice"
                    placeholder="Sell price"
                    ref={register({ required: true })}
                    step="any"
                  />
                </p>
                {errors.sellPrice && (
                  <p className="help is-danger">Sell Price is required</p>
                )}
              </div>

              <div className="field">
                <label className="label">Quantity</label>
                <p className="control">
                  <input
                    className={`input  ${
                      errors.sellQuantity ? "is-danger" : ""
                    }`}
                    type="number"
                    id="sellQuantity"
                    name="sellQuantity"
                    placeholder="Quantity"
                    ref={register({ required: true })}
                    step="any"
                    disabled
                  />
                </p>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button type="submit" className="button is-primary ">
              Submit
            </button>
            <button className="button" onClick={handleClose}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </form>
  );
};

UIModalSellForm.propTypes = {
  isActive: PropTypes.bool,
  trade: PropTypes.object,
  handleClose: PropTypes.func,
  currentPrice: PropTypes.number,
};

export default UIModalSellForm;
