import React from "react";
import HomeStepsWrapper from "./StepsWrapper";
import LogoInPage from "../UI/LogoInPage";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import UIStat from "../UI/Stat";
import phonesAdd from "../../images/phones-add-trade2.png";

const HomeAddTrade = ({ currentUser }) => {
  return (
    <HomeStepsWrapper bg="is-primary" headline="1. Add a recent trade">
      <div className="column is-half">
        {/* <figure className={`image`}>
          <img src={phonesAdd} alt="Add trade screenshots on mobile phone" />
        </figure> */}
        <img src={phonesAdd} alt="Add trade screenshots on mobile phone" />
      </div>
      <div className="column is-half">
        <p className="is-size-4 is-size-5-mobile">
          Buy your crypto on any <Link to="/resources">Exchange</Link>, then add
          trade details into <LogoInPage />
        </p>

        <div className="notification is-warning is-size-5 is-size-6-mobile">
          <h4 className="subtitle has-text-primary is-size-6-mobile">
            Define <span className="is-italic">trade targets</span>
          </h4>
          <p>
            Say you bought 100 USD worth of Bitcoin on February 1st, and
            Bitcoin's price was 9300 USD. Here's a common target spread (2:1):
          </p>
          <div className="columns">
            <div className="column is-half">
              <div className="level is-mobile">
                <div className="level-item">
                  <UIStat heading="Target Profit %" content="20%" />
                </div>
                <div className="level-item has-text-success">
                  <UIStat heading="Target Profit" content="11,160" />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="level is-mobile">
                <div className="level-item">
                  <UIStat heading="Target Stop Loss %" content="10%" />
                </div>
                <div className="level-item has-text-danger">
                  <UIStat heading="Target Stop Loss" content="8370" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          The #1 rule successful trades follow is{" "}
          <span className="is-italic has-text-warning">
            know when to get out before entering a trade.
          </span>
        </p>
        <p>
          <Link
            to={`${currentUser ? "/trade/form" : "/user/sign-in"}`}
            className={`button is-outlined is-primary is-inverted ${
              isMobile ? "is-fullwidth" : ""
            }`}
          >
            Add a Trade
          </Link>
        </p>
      </div>
    </HomeStepsWrapper>
  );
};

export default HomeAddTrade;
