import React from "react";
import PropTypes from "prop-types";

const UIDetailItem = ({ title, subtitle }) => (
  <div className="trade-item-detail has-text-centered">
    <p className="heading">{subtitle}</p>
    <p className="title">{title}</p>
  </div>
);

UIDetailItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UIDetailItem;
