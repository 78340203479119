import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TradeNotificationDetailed = ({
  title,
  className = "is-info",
  children
}) => {
  return (
    <article className={`message ${className}`}>
      <div className="message-header">
        <p>
          <span className="icon">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </span>{" "}
          {title}
        </p>
      </div>
      <div className="message-body">{children}</div>
    </article>
  );
};

TradeNotificationDetailed.propTypes = {
  trade: PropTypes.object
};

export default TradeNotificationDetailed;
