import React from "react";

const HomeStepsWrapper = ({ children, bg, headline }) => {
  return (
    <section className={`hero ${bg}`}>
      <div className="hero-body">
        <div className="container content">
          <h3 className="title is-size-3">{headline}</h3>
          <div className="columns">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default HomeStepsWrapper;
