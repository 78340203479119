import { toast } from "bulma-toast";

export default function toastWrapper(obj) {
  const toasted = {
    dismissible: true,
    closeOnClick: true,
    duration: 5000,
    position: "top-center",
    ...obj
  };

  return toast(toasted);
}
