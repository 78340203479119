import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { getCurrentPrice } from "./api";
import UIModalDelete from "../UI/ModalDelete";
import TradeChartTimeline from "./ChartTimeline";
import { useHistory } from "react-router-dom";
import TradeModalSellForm from "./ModalSellForm";
import TradeTargetExitsCard from "./TargetExitsCard";
import TradeBuyCard from "./BuyCard";
import TradeSellCard from "./SellCard";
import TradeExchangeCard from "./ExchangeCard";
import TradeHeader from "./Header";
import { UserContext } from "../User/UserContext";
import TradeSoldStatusCard from "./SoldStatusCard";
import TradeStatusCard from "./StatusCard";
import TradeSoldSnapshots from "./SoldSnapshots";
import { isMobile } from "react-device-detect";

const Trade = ({ trade, handleDeleteClick, priceHistory }) => {
  const {
    userPreferences: { preferredCurrency },
  } = useContext(UserContext);
  const [currentPrice, setCurrentPrice] = useState();
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [sellModalActive, setSellModalActive] = useState(false);
  const history = useHistory();
  const { coinBase, coinTarget } = trade;

  useEffect(() => {
    const getPriceNow = async () => {
      const price = await getCurrentPrice(coinBase, coinTarget);
      setCurrentPrice(price);
    };

    getPriceNow();
  }, [coinBase, coinTarget]);

  const handleCloseSellModal = () => {
    setSellModalActive(false);
    // TODO: Need to refresh the page data here
    history.push(`/trade/${trade.id}`);
  };

  const handleDeleteCancelClick = () => {
    setDeleteModalActive(false);
  };

  const handleDeleteConfirmClick = () => {
    handleDeleteClick();
    setDeleteModalActive(false);
  };

  const handleSellClick = () => {
    setSellModalActive(true);
  };

  return (
    <>
      <TradeHeader
        trade={trade}
        currentPrice={currentPrice}
        preferredCurrency={preferredCurrency}
      />
      {!trade.active && (
        <section className="section">
          <div className="container">
            <TradeSoldSnapshots
              trade={trade}
              preferredCurrency={preferredCurrency}
            />
          </div>
        </section>
      )}
      <div className="box">
        <TradeChartTimeline
          trade={trade}
          currentPrice={currentPrice}
          priceHistory={priceHistory}
        />
      </div>

      <section className="section">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-half">
              {trade.active && (
                <TradeStatusCard trade={trade} currentPrice={currentPrice} />
              )}
              {!trade.active && <TradeSoldStatusCard trade={trade} />}

              <TradeTargetExitsCard trade={trade} currentPrice={currentPrice} />
            </div>
            <div className="column is-half">
              <TradeBuyCard
                trade={trade}
                preferredCurrency={preferredCurrency}
              />
              {!trade.active && (
                <TradeSellCard
                  trade={trade}
                  preferredCurrency={preferredCurrency}
                />
              )}
              <TradeExchangeCard trade={trade} />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="buttons">
            {trade.active && (
              <button
                className={`button is-primary ${
                  isMobile ? "is-fullwidth" : "is-medium"
                }`}
                onClick={handleSellClick}
              >
                Mark as Sold
              </button>
            )}
            <button
              className={`button is-danger is-outlined ${
                isMobile ? "is-fullwidth" : "is-medium"
              }`}
              onClick={() => setDeleteModalActive(true)}
            >
              Delete Trade
            </button>

            {/* <Link
              className="button "
              to={`/trade/form/${trade.id}`}
            >
              Edit
            </Link> */}
          </div>
        </div>
      </section>

      <UIModalDelete
        isActive={deleteModalActive}
        handleCancelClick={handleDeleteCancelClick}
        handleConfirmClick={handleDeleteConfirmClick}
      />
      {sellModalActive && (
        <TradeModalSellForm
          isActive={sellModalActive}
          trade={trade}
          handleClose={handleCloseSellModal}
          currentPrice={currentPrice}
        />
      )}
    </>
  );
};

Trade.propTypes = {
  trade: PropTypes.object,
  handleDeleteClick: PropTypes.func,
  priceHistory: PropTypes.array,
};

export default Trade;
