import React from "react";
import HomeStepsWrapper from "./StepsWrapper";
import LogoInPage from "../UI/LogoInPage";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import screenshot from "../../images/phones-dashboard.png";

const HomeMeasurePerformance = () => {
  return (
    <HomeStepsWrapper bg="is-link" headline="Measure Performance">
      <div className="column is-three-fifths">
        <img src={screenshot} alt="Trade-r dashboard screen shots" />
      </div>
      <div className="column is-two-fifths is-size-4 is-size-5-mobile">
        <p>
          <LogoInPage /> gives insight into exited positions, and can help track
          your individual performance across coins and base currencies.
        </p>
        <p>
          <Link
            to="/dashboard"
            className={`button is-outlined is-primary is-inverted ${
              isMobile ? "is-fullwidth" : ""
            }`}
          >
            Go To Dashboard
          </Link>
        </p>
      </div>
    </HomeStepsWrapper>
  );
};

export default HomeMeasurePerformance;
