import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/trade-r-logo-gradient-green.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer has-img-background-footer has-text-white">
      <div className="container">
        <div className="content has-text-centered">
          <p>
            <a
              className="icon"
              href="//github.com/adamjarling"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "github"]} />
            </a>
            &nbsp;
            <a
              className="icon"
              href="//twitter.com/adamjarling"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "twitter-square"]} />
            </a>
          </p>
          <nav className="level">
            <div className="level-item">
              <Link to="/dashboard">Dashboard</Link>
            </div>
            <div className="level-item">
              <Link to="/trade/form">Add Trade</Link>
            </div>
            <div className="level-item">
              <Link to="/trade/list">Trades</Link>
            </div>
            <div className="level-item">
              <Link to="/resources">Resources</Link>
            </div>
            <div className="level-item">
              <Link to="/faqs">Frequently Asked Questions</Link>
            </div>
            <div className="level-item">
              <Link to="/donate" className="button is-warning is-outlined">
                <span className="icon">
                  <FontAwesomeIcon icon={["fab", "bitcoin"]} />
                </span>
                <span>Donate</span>
              </Link>
            </div>
          </nav>
          <figure className="image no-bottom-padding">
            <img src={logo} className="logo" alt="Trade-r logo" />
          </figure>
          <p>v1.0.1 &copy; Copyright 2020</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
