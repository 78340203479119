import React from "react";

const UIStat = ({ heading, content }) => {
  return (
    <div className="has-text-centered">
      <div className="heading">{heading}</div>
      <div className="is-size-3">{content}</div>
    </div>
  );
};

export default UIStat;
