import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/firestore";
import {
  addTrade,
  getCoinTargets,
  getCurrentPrice,
  getPricesOnDate,
  getUserTrades,
  updateTrade,
} from "./api";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { supportedCoins } from "../../services/supported-coins";
import { supportedExchanges } from "../../services/exchanges";
import UINotificationPriceTargets from "../UI/NotificationPriceTargets";
import UINotificationStopLosses from "../UI/NotificationStopLosses";
import { formatPrice } from "../../services/services";
import toastWrapper from "../../services/toast-wrapper";
import { useForm } from "react-hook-form";
import DateTime from "react-datetime";
import { UserContext } from "../User/UserContext";

const TradeForm = ({ trade, userId }) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm();
  const history = useHistory();
  const [coinTargets, setCoinTargets] = useState([]);
  const [currentPrice, setCurrentPrice] = useState();
  const [isUSDTarget, setIsUSDTarget] = useState(false);
  const [showPriceTargetHelpers, setShowPriceTargetHelpers] = useState(false);
  const [unsupportedExchangeMsg, setUnsupportedExchangeMsg] = useState();
  const { updateTrades, userPreferences } = useContext(UserContext);

  useEffect(() => {
    const fn = async () => {
      if (trade) {
        // Edit form, populate existing values into form
        const {
          buy,
          coinBase,
          coinTarget,
          exchange,
          sell,
          target,
          tradeType,
        } = trade;

        setValue("coinBase", coinBase);
        setValue("exchange", exchange);

        await handleGetCoinTargets();

        setValue("coinTarget", coinTarget);
        setValue("tradeType", tradeType);
        setValue("buyPrice", buy.price);
        setValue("buyQuantity", buy.quantity);
        setValue("buyDate", buy.date.toDate());
        setValue("sellPrice", sell.price);
        setValue("sellQuantity", sell.quantity);
        setValue("targetPriceProfit", target.profit);
        setValue("targetPriceStopLoss", target.stopLoss);
      } else {
        // Adding form, ensure form values are clear
        reset();
      }
    };

    fn();
  }, []);

  const handleCoinTargetChange = async () => {
    const { coinBase, coinTarget } = getValues();

    if (!(coinBase && coinTarget)) return Promise.resolve();

    // Get current price
    let curPrice = await getCurrentPrice(coinBase, coinTarget.toLowerCase());
    setCurrentPrice(curPrice);

    // Give an initial, helper value to Buy Price
    setValue("buyPrice", curPrice);

    // Show price target helpers
    if (curPrice) {
      setShowPriceTargetHelpers(true);
    }
    setIsUSDTarget(coinTarget.toLowerCase() === "usd");
  };

  const handleGetCoinTargets = async () => {
    const { coinBase, exchange } = getValues();

    if (exchange && coinBase) {
      setUnsupportedExchangeMsg(``);
      const coinTargets = await getCoinTargets({
        exchange,
        coinBase,
      });

      try {
        setCoinTargets(coinTargets);
        setValue("coinTarget", coinTargets[0].id);
        await handleCoinTargetChange();
      } catch (error) {
        setUnsupportedExchangeMsg(
          `${coinBase.toUpperCase()} is not currently supported on ${exchange.toUpperCase()}`
        );
        console.log("Error trying to set coin target values in Form: ", error);
      }
    }
  };

  const displayTargetIcon = () => {
    return (
      isUSDTarget && (
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon="dollar-sign" />
        </span>
      )
    );
  };

  const handlePriceChange = () => {
    const { buyPrice } = getValues();
    setShowPriceTargetHelpers(!!buyPrice);
  };

  const onSubmit = async (data) => {
    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const {
      coinBase,
      exchange,
      coinTarget,
      buyDate,
      buyPrice,
      buyQuantity,
      targetPriceProfit,
      targetPriceStopLoss,
      tradeType,
      sellDate,
      sellPrice,
      sellQuantity,
    } = data;

    // Get preferred currency buy price
    const pricesObj = await getPricesOnDate(coinBase, buyDate);
    const pricePreferredCurrency = formatPrice(
      pricesObj[userPreferences.preferredCurrency]
    );

    let tradeObj = {
      active: trade ? trade.active : true,
      dateAdded: now,
      coinBase,
      coinTarget,
      exchange,

      buy: {
        date: firebase.firestore.Timestamp.fromDate(new Date(buyDate)),
        price: parseFloat(buyPrice),
        pricePreferredCurrency: parseFloat(pricePreferredCurrency),
        quantity: parseFloat(buyQuantity),
      },
      sell: trade
        ? {
            date: parseFloat(sellDate),
            price: parseFloat(sellPrice),
            quantity: parseFloat(buyQuantity),
          }
        : { date: "", price: null, quantity: null },
      target: {
        profit: parseFloat(targetPriceProfit),
        stopLoss: parseFloat(targetPriceStopLoss),
      },
      tradeType,
      updatedAt: now,
    };

    // New Trade
    if (!trade) {
      // TODO: Handle error in addTrade
      const response = await addTrade(userId, tradeObj);
      if (response) {
        toastWrapper({
          message: "Trade successfully added",
          type: "is-success",
        });
        // Update trades in UserContext
        const freshTrades = await getUserTrades(userId);
        updateTrades(freshTrades);

        history.push("/trade/list");
      }
    }
    // Update a trade
    else {
      const response = await updateTrade(userId, trade.id, tradeObj);

      // Handle errors
      if (response && response.error) {
        return toastWrapper({
          message: response.message,
          type: "is-danger",
          duration: 300000,
        });
      }

      // Success, call the route to re-load trade data
      toastWrapper({
        message: "Trade successfully updated",
        type: "is-success",
      });
      history.push(`/trade/${trade.id}`);
    }
  };

  // TODO: Figure out how to clear these values when going
  // from editing a trade, to adding a trade
  const getBuyDateValue = () => {
    const values = getValues();
    return trade.buy.date.toDate();
  };
  const getSellDateValue = () => {
    const values = getValues();
    try {
      return trade.sell.date.toDate();
    } catch (err) {
      return "";
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label className="label">Coin</label>
        <p className="control">
          <span className={`select  ${errors.coinBase ? "is-danger" : ""}`}>
            <select
              id="coinBase"
              name="coinBase"
              onChange={handleGetCoinTargets}
              ref={register({ required: true })}
            >
              <option value="">Select coin</option>
              {supportedCoins.map((coin) => (
                <option
                  key={coin.id}
                  value={coin.id}
                >{`${coin.symbol.toUpperCase()} - ${coin.name}`}</option>
              ))}
            </select>
          </span>
        </p>
        {errors.coinBase && <p className="help is-danger">Coin is required</p>}
      </div>

      <div className="field">
        <label className="label">Exchange</label>
        <p className="control">
          <span className={`select  ${errors.exchange ? "is-danger" : ""}`}>
            <select
              id="exchange"
              name="exchange"
              onChange={handleGetCoinTargets}
              ref={register({ required: true })}
            >
              <option value="">Select exchange</option>
              {supportedExchanges.map((exchange) => (
                <option
                  key={exchange.id}
                  value={exchange.id}
                >{`${exchange.name}`}</option>
              ))}
            </select>
          </span>
        </p>
        {errors.exchange && (
          <p className="help is-danger">Exchange is required</p>
        )}
      </div>

      {unsupportedExchangeMsg && (
        <p className="notification is-warning">{unsupportedExchangeMsg}</p>
      )}

      <div className="field">
        <label className="label">Base Currency</label>
        <p className="control">
          <span className={`select  ${errors.coinTarget ? "is-danger" : ""}`}>
            <select
              id="coinTarget"
              name="coinTarget"
              onChange={handleCoinTargetChange}
              ref={register({ required: true })}
            >
              <option value="">Select base currency</option>
              {coinTargets.map((target) => (
                <option key={target.id} value={target.id}>
                  {target.id}
                </option>
              ))}
            </select>
          </span>
        </p>
        {errors.coinTarget && (
          <p className="help is-danger">Base Currency is required</p>
        )}
      </div>

      <div className="field">
        <label className="label">Trade Type</label>
        <p className="control">
          <input
            type="radio"
            className="is-checkradio is-primary"
            name="tradeType"
            id="tradeType"
            value="LONG"
            ref={register}
            defaultChecked
          />{" "}
          <label htmlFor="tradeType">Long</label>
          <input
            type="radio"
            className="is-checkradio is-primary"
            name="tradeType"
            id="tradeType2"
            value="SHORT"
            ref={register}
          />{" "}
          <label htmlFor="tradeType2">Short</label>
        </p>
        <p className="help">
          <strong>Long</strong> bets the price will go up.{" "}
          <strong>Short</strong> bets the price will go down.
        </p>
      </div>

      <hr />
      <h2 className="subtitle">Buy</h2>
      <div className="field">
        <label className="label">Date</label>
        <div className="control">
          <DateTime
            defaultValue={!trade ? new Date() : getBuyDateValue()}
            inputProps={{
              className: `input  ${errors.buyDate ? "is-danger" : ""}`,
              ref: register({ required: true }),
              id: "buyDate",
              name: "buyDate",
            }}
          />
        </div>
        {errors.buyDate && <p className="help is-danger">Date is required</p>}
      </div>

      <div className="field">
        <label className="label">Price</label>
        <p className={`control ${isUSDTarget ? "has-icons-left" : ""}`}>
          <input
            className={`input  ${errors.buyPrice ? "is-danger" : ""}`}
            type="number"
            id="buyPrice"
            name="buyPrice"
            placeholder="Buy price"
            step="any"
            onChange={handlePriceChange}
            ref={register({ required: true })}
          />
          {displayTargetIcon()}
        </p>
        {errors.buyPrice && <p className="help is-danger">Price is required</p>}
      </div>

      <div className="field">
        <label className="label">Quantity</label>
        <p className="control">
          <input
            className={`input  ${errors.buyQuantity ? "is-danger" : ""}`}
            type="number"
            id="buyQuantity"
            name="buyQuantity"
            placeholder="Quantity"
            ref={register({ required: true })}
            step="any"
          />
        </p>
        {errors.buyQuantity && (
          <p className="help is-danger">Quantity is required</p>
        )}
      </div>

      <hr />
      <h2 className="subtitle">Exits</h2>
      <div className="field">
        <label className="label">Target Profit Price</label>
        <p className={`control ${isUSDTarget ? "has-icons-left" : ""}`}>
          <input
            className={`input  ${errors.targetPriceProfit ? "is-danger" : ""}`}
            type="number"
            id="targetPriceProfit"
            name="targetPriceProfit"
            placeholder="Set profit target"
            ref={register({ required: true })}
            step="any"
          />
          {displayTargetIcon()}
        </p>
        {errors.targetPriceProfit && (
          <p className="help is-danger">Target Profit Price is required</p>
        )}
        <p className="help">The price you want to sell at for a profit</p>
      </div>

      {showPriceTargetHelpers && (
        <UINotificationPriceTargets
          price={watch("buyPrice")}
          tradeType={watch("tradeType")}
        />
      )}

      <div className="field">
        <label className="label">Stop Loss Price</label>
        <p className={`control ${isUSDTarget ? "has-icons-left" : ""}`}>
          <input
            className={`input  ${
              errors.targetPriceStopLoss ? "is-danger" : ""
            }`}
            type="number"
            id="targetPriceStopLoss"
            name="targetPriceStopLoss"
            placeholder="Set stop loss"
            ref={register({ required: true })}
            step="any"
          />
          {displayTargetIcon()}
        </p>
        {errors.targetPriceStopLoss && (
          <p className="help is-danger">Target Profit Price is required</p>
        )}
        <p className="help">Safety price to sell to avoid further losses.</p>
      </div>

      {showPriceTargetHelpers && (
        <UINotificationStopLosses
          price={watch("buyPrice")}
          tradeType={watch("tradeType")}
        />
      )}

      {trade && !trade.active && (
        <>
          <hr />
          <h2 className="subtitle">Sell</h2>
          <div className="field">
            <label className="label">Date</label>
            <div className="control">
              <DateTime
                defaultValue={!trade ? new Date() : getSellDateValue()}
                inputProps={{
                  className: `input  ${errors.sellDate ? "is-danger" : ""}`,
                  ref: register({ required: true }),
                  id: "sellDate",
                  name: "sellDate",
                }}
              />
            </div>
            {errors.sellDate && (
              <p className="help is-danger">Date is required</p>
            )}
          </div>

          <div className="field">
            <label className="label">Price</label>
            <p className={`control ${isUSDTarget ? "has-icons-left" : ""}`}>
              <input
                className={`input  ${errors.sellPrice ? "is-danger" : ""}`}
                type="number"
                id="sellPrice"
                name="sellPrice"
                placeholder="Sell price"
                step="any"
                onChange={handlePriceChange}
                ref={register({ required: true })}
              />
              {displayTargetIcon()}
            </p>
            {errors.sellPrice && (
              <p className="help is-danger">Price is required</p>
            )}
          </div>

          <div className="field">
            <label className="label">Quantity</label>
            <p className="control">
              <input
                disabled
                className={`input  ${errors.sellQuantity ? "is-danger" : ""}`}
                type="number"
                id="sellQuantity"
                name="sellQuantity"
                ref={register({ required: true })}
                step="any"
              />
            </p>
            {errors.sellQuantity && (
              <p className="help is-danger">Quantity is required</p>
            )}
          </div>
        </>
      )}

      <div className="field">
        <div className="buttons">
          <button type="submit" className="button is-primary ">
            {trade ? "Update" : "Submit"} Trade
          </button>
          <Link to="/trade/list" className="button is-link">
            Cancel
          </Link>
        </div>
      </div>
    </form>
  );
};

TradeForm.propTypes = {
  trade: PropTypes.object,
  userId: PropTypes.string,
};

export default TradeForm;
