import React from "react";
import PropTypes from "prop-types";
import {
  countTradeDays,
  formatPreferredCurrency,
  formatPrice,
  percentDiff,
} from "../../services/services";

const TradeSoldSnapshots = ({ trade, preferredCurrency }) => {
  const { coinTarget, profit } = trade;
  const percentChangeSold = percentDiff(trade) || null;

  return (
    <>
      <div className="level has-text-centered">
        <div className="level-item">
          <div>
            <p className="heading">Sold %</p>
            <div
              className={`title ${
                percentChangeSold > 0 ? "has-text-success" : "has-text-danger"
              }`}
              style={{ paddingBottom: "0.5rem" }}
            >
              {percentChangeSold}%
            </div>
          </div>
        </div>
        <div className="level-item">
          <div>
            <p className="heading">Profit/Loss</p>
            <p className={`title`}>
              {formatPrice(profit)}{" "}
              <span className="is-size-4">{coinTarget}</span>
            </p>
          </div>
        </div>
        <div className="level-item">
          <div>
            <p className="heading">
              Estimated Profit/Loss
              <br />
              (Preferred Currency)
            </p>
            <p className={`title`}>
              {formatPreferredCurrency(
                trade.profitPreferredCurrency,
                preferredCurrency
              )}
            </p>
          </div>
        </div>
        <div className="level-item">
          <div>
            <p className="heading">Days Open</p>
            <p className="title">{countTradeDays(trade)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

TradeSoldSnapshots.propTypes = {
  trade: PropTypes.object,
  preferredCurrency: PropTypes.string,
};

export default TradeSoldSnapshots;
