export const preferredCurrencies = [
  {
    label: "USD",
    value: "usd",
    unit: "$"
  },
  {
    label: "EUR",
    value: "eur",
    unit: "€"
  },
  {
    label: "British Pound",
    value: "gbp",
    unit: "£"
  },
  {
    label: "Japanese Yen",
    value: "jpy",
    unit: "¥"
  },
  {
    label: "Canadian Dollar",
    value: "cad",
    unit: "CAD"
  },
  {
    label: "Bitcoin",
    value: "btc",
    unit: "₿"
  }
];
