import React from "react";
import { supportedExchanges } from "../../services/exchanges";

const TradeExchangeCard = ({ trade }) => {
  const { exchange } = trade;
  const exchangeObj = supportedExchanges.find((x) => x.id === exchange);

  return (
    <div className="box">
      <h3 className="subtitle">Exchange Info</h3>
      <div className="content">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <figure
                className="image is-24x24"
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                <img src={exchangeObj.image} alt={exchangeObj.name} />
              </figure>
            </div>
            <div className="level-item">
              <h4 className="title is-size-6">{exchangeObj.name}</h4>
            </div>
            <div className="level-item">
              <a
                href={exchangeObj.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {exchangeObj.url}
              </a>
            </div>
          </div>
        </div>
        <dl>
          <dt>Year established</dt>
          <dd>{exchangeObj.year_established}</dd>
          <dt>Country</dt>
          <dd>{exchangeObj.country}</dd>
          <dt>Trust score rank</dt>
          <dd># {exchangeObj.trust_score_rank}</dd>
        </dl>
      </div>
    </div>
  );
};

export default TradeExchangeCard;
