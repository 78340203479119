import React from "react";
import {
  formatDate,
  formatPair,
  formatPreferredCurrency,
  formatPrice,
} from "../../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const TradeBuyCard = ({ trade, preferredCurrency }) => {
  const { tradeType, buy, coinTarget } = trade;

  return (
    <div className="box has-ribbon">
      <div className="ribbon is-info">{tradeType}</div>
      <h3 className="subtitle">Buy</h3>

      <div className="content">
        <dl>
          <dt>Date</dt>
          <dd>{formatDate(buy.date)}</dd>
          <dt>Price</dt>
          <dd>
            {buy.price} {coinTarget}
          </dd>
          <dt>
            Preferred currency price
            <Link to="/faqs" className="icon">
              <FontAwesomeIcon icon="info" />
            </Link>
          </dt>
          <dd>
            {formatPreferredCurrency(
              buy.pricePreferredCurrency,
              preferredCurrency
            )}
          </dd>
          <dt>Quantity</dt>
          <dd>{buy.quantity}</dd>
          <dt>Trade Type</dt>
          <dd>{tradeType}</dd>
          <dt>Trading Pair</dt>
          <dd>{formatPair(trade)}</dd>
        </dl>

        <dl className="notification is-light">
          <dt>Buy Amount</dt>
          <dd>
            {formatPrice(buy.price * buy.quantity)} {coinTarget}
          </dd>
          <dt>
            Buy Amount (in preferred currency){" "}
            <Link to="/faqs" className="icon">
              <FontAwesomeIcon icon="info" />
            </Link>
          </dt>
          <dd>
            ~{" "}
            {formatPreferredCurrency(
              buy.pricePreferredCurrency * buy.quantity,
              preferredCurrency
            )}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default TradeBuyCard;
