import React from "react";
import { formatPair, formatPrice, themeColors } from "../../services/services";
import Chart from "react-apexcharts";
import { preferredCurrencies } from "../../services/preferred-currencies";
import { isMobile } from "react-device-detect";

const ChartAllSoldTrades = ({ trades = [], userPreferences }) => {
  if (!userPreferences) {
    return null;
  }

  const { preferredCurrency = "" } = userPreferences;

  const unit = preferredCurrency
    ? preferredCurrencies.find((c) => c.value === preferredCurrency).unit
    : "";

  const sortedTrades = trades
    .filter((trade) => !trade.active)
    .sort((a, b) => b.profitPreferredCurrency - a.profitPreferredCurrency);

  const title = isMobile
    ? {}
    : {
        text: `Ordered by profit in preferred currency (${unit}
      ${preferredCurrency.toUpperCase()})`,
        floating: true,
        align: "center",
        style: {
          color: themeColors.isDark,
        },
      };

  const options = {
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -10000,
              to: -1,
              color: themeColors.isDanger,
            },
            {
              from: 0,
              to: 10000,
              color: themeColors.isSuccess,
            },
          ],
        },
        columnWidth: "80%",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    title,
    xaxis: {
      categories: sortedTrades.map((trade) => formatPair(trade)),
      title: {
        text: `Preferred currency`,
      },
    },
  };

  const series = [
    {
      data: sortedTrades.map((trade) =>
        formatPrice(trade.profitPreferredCurrency)
      ),
    },
  ];

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={500}
      />
    </div>
  );
};

export default ChartAllSoldTrades;
