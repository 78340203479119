import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import {
  calculateExitPercent,
  calculateTargetExitPercentages,
  formatPair,
  themeColors,
} from "../../services/services";

const ChartExitPercentages = ({ trades }) => {
  if (trades.length === 0) {
    return null;
  }

  const tradePairs = trades.map((trade) => formatPair(trade));
  const exitPercentages = trades.map((trade) => calculateExitPercent(trade));
  const targetExitPercentageObjects = trades.map((trade) =>
    calculateTargetExitPercentages(trade)
  );
  const exitTargetProfits = targetExitPercentageObjects.map(
    (target) => target.profit
  );
  const exitTargetStopLosses = targetExitPercentageObjects.map(
    (target) => target.stopLoss
  );

  const series = [
    {
      name: "Sold",
      data: exitPercentages,
    },
    {
      name: "Target Profit",
      data: exitTargetProfits,
    },
    {
      name: "Target Stop Loss",
      data: exitTargetStopLosses,
    },
  ];

  const options = {
    colors: [themeColors.isPrimary, themeColors.isLight, themeColors.isLight],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: tradePairs,
      labels: {
        rotate: -45,
      },
      tickPlacement: "on",
    },

    yaxis: {
      title: {
        text: "% (percent)",
      },
      labels: {
        formatter: function (val, index) {
          return `${val}%`;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
  };

  return <Chart options={options} series={series} type="bar" height={450} />;
};

ChartExitPercentages.propTypes = {
  trades: PropTypes.array,
};

export default React.memo(ChartExitPercentages);
